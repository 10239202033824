import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LottieSpinner from "../../../common/lottieSpinner/LottieSpinner";
import { API_HOTEL_LIST } from "../../../../utils/config";
//import PropertyCard from "./PropertyCard"; // Import your PropertyCard component

const PropertyCard = ({ hotel, onRoomCardClick }) => {
  const navigate = useNavigate();
  const { hotel_id, imgSrc, price, roomType, description, hotelType } = hotel;

  const handleDetailsClick = () => {
    onRoomCardClick(hotel_id);
    // navigate("/propertiesdetails", { state: { hotel_id: hotel_id } });
    console.log("hotel_id:", hotel_id); 
    navigate(`/propertiesdetails?hotel_id=${hotel_id}`);
  };
  const cardStyle = {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    margin: "0",
    padding: "0",
    
  };
  const textStyles = {
    fontWeight: "bold", 
    color: "white", 
  };

  return (
    <div className="property-card">
      <div className="overlay-content">
        <h4 style={textStyles}>{hotelType}</h4>
        <p style={textStyles}>£{price} / Night</p>
        <button style={textStyles} onClick={handleDetailsClick}>
          View Details
        </button>
      </div>
      
      <figure style={cardStyle}>
        <img
          src={imgSrc}
          alt=""
          className="img-fluid"
          style={{
            maxHeight: "100%", 
            maxWidth: "100%", 
          }}
        />
      </figure>
    </div>
  );
};

const Property = () => {
  const [hotelData, setHotelsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRoomId, setSelectedRoomId] = useState(null);

  useEffect(() => {
    const apiUrl = API_HOTEL_LIST;

    axios
      .get(apiUrl)
      .then((response) => {
        const data = response.data;
        if (data.status === "true") {
          const mappedRooms = data.data.map((hotel) => ({
            hotel_id: hotel.id,
            imgSrc: hotel.primary_images,
            price: hotel.rent,
            hotelType: hotel.name,
            description: hotel.description,
            facilities: hotel.amenities,
            isLeftAligned: true,
          }));
          setHotelsData(mappedRooms);
        } else {
          setError("Error fetching data from the API.");
        }
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching data from the API.");
        setLoading(false);
      });
  }, []);

  const handleRoomCardClick = (hotel_id) => {
    console.log("Function Call" + hotel_id);
    setSelectedRoomId(hotel_id);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (selectedRoomId) {
        console.log("Selected Hotel ID:", selectedRoomId);
      } else {
        // Handle the case where no room is selected
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (loading) {
    return (
      <p>
        Loading...
        <LottieSpinner />
      </p>
    );
  }

  if (error) {
    return <LottieSpinner />;
  }

  return (
    <section className="rooms1 section-padding bg-cream" data-scroll-index="1">
      <div className="container">
        <div>
          <div>
            <div className="section-subtitle">The Lluex Luxury Hotel</div>
            <div className="section-title">Properties</div>
          </div>
        </div>

        <div className="row">
          {hotelData.map((hotel) => (
            <div className="col-12" key={hotel.hotel_id}>
              <PropertyCard hotel={hotel} onRoomCardClick={() => handleRoomCardClick(hotel.hotel_id)} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Property;





