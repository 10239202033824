import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../components/pages/home/HomePage/Home";
import About from "../components/pages/about/AboutPage/About";
import RoomsSuites from "../components/pages/rooms&suites/RSpage/RoomsSuites";
import RegistrationPage from "../components/pages/registration/RegistrationPage";
import LoginPage from "../components/pages/login/LoginPage";
import ContactPage from "../components/pages/contact/ContactPage";
import RoomSuitesRouter from "./RoomSuitesRouter";
import VerifyMainPage from "../components/pages/verify/VerifyMainPage";
import ScrollToTop from "./ScrollToTop";
import SearchMainPage from "../components/pages/searchResult/SearchMainPage";
import RoomMainPage from "../components/pages/roomdetails/RoomMainPage/RoomMainPage";
import BookingMainPage from "../components/pages/booking/bookingmainpage/BookingMainPage";
import PaymentProcess from "../components/pages/booking/paymentprocesspage/PaymentProcess";
import FinalPaymentPage from "../components/pages/booking/paymentprocesspage/FinalPaymentPage";

import ThanksMainPage from "../components/pages/thanks/ThanksMainPage";
import BookingHistoryMainPage from "../components/pages/bookinghistory&review/BookingHistoryMainPage";
import ForgetPassword from "../components/pages/login/forgetpassword/ForgetPassword";
import Checkout from "../components/pages/booking/paymentprocesspage/Checkout";
import PropertyContentDetailsPage from "../components/pages/properties/propertycontentdetail/PropertyContentDetailsPage";
// import ReviewPage from "../components/pages/review/ReviewPage";

const Approuter = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/rooms&suites" element={<RoomsSuites />} />

          <Route path="/rooms&suites/*" element={<RoomSuitesRouter />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/verify" element={<VerifyMainPage />} />
          <Route path="/searchroom" element={<SearchMainPage />} />
          <Route path="/roomsdetails" element={<RoomMainPage />} />
          <Route path="/searchroomdetails" element={<SearchMainPage />} />
          {/* <Route path="/roomdetails/:roomId" element={<RoomMainPage />} /> */}
          <Route path="/payment" element={<BookingMainPage />} />
          <Route path="/finalpayment" element={<FinalPaymentPage />} />
         
          <Route path="/thankyou" element={<ThanksMainPage />} />
          <Route path="/review&history" element={<BookingHistoryMainPage />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route exact path="checkout/" index element={<Checkout />} />
          <Route
            exact
            path="/propertiesdetails"
            index
            element={<PropertyContentDetailsPage />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default Approuter;

// import React from "react";
// import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
// import Home from "../components/pages/home/HomePage/Home";
// import About from "../components/pages/about/AboutPage/About";
// import RoomsSuites from "../components/pages/rooms&suites/RSpage/RoomsSuites";
// import RegistrationPage from "../components/pages/registration/RegistrationPage";
// import LoginPage from "../components/pages/login/LoginPage";
// import ContactPage from "../components/pages/contact/ContactPage";
// import VerifyMainPage from "../components/pages/verify/VerifyMainPage";
// import ScrollToTop from "./ScrollToTop";
// import SearchMainPage from "../components/pages/searchResult/SearchMainPage";
// import RoomMainPage from "../components/pages/roomdetails/RoomMainPage/RoomMainPage";
// import BookingMainPage from "../components/pages/booking/bookingmainpage/BookingMainPage";
// import PaymentProcess from "../components/pages/booking/paymentprocesspage/PaymentProcess";
// import FinalPaymentPage from "../components/pages/booking/paymentprocesspage/FinalPaymentPage";
// import PrivateRoute from "./PrivateRoute" ; // Import the PrivateRoute component

// const Approuter = () => {
//   // Check the user's login status
//   const isUserLoggedIn = localStorage.getItem("loginStatus") === "true";

//   return (
//     <Router>
//       <ScrollToTop />
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/about" element={<About />} />
//         <Route path="/contact" element={<ContactPage />} />
//         <Route path="/rooms&suites" element={<RoomsSuites />} />

//         {/* Use the PrivateRoute component */}
//         <Route
//           path="/roomdetails/:roomId"
//           element={
//             isUserLoggedIn ? <RoomMainPage /> : <Navigate to="/login" />
//           }
//         />

//           <Route path="/registration" element={<RegistrationPage />} />
//           <Route path="/login" element={<LoginPage />} />
//           <Route path="/verify" element={<VerifyMainPage />} />
//           <Route path="/searchroom" element={<SearchMainPage />} />
//           <Route path="/roomsdetails" element={<RoomMainPage />} />
//           <Route path="/searchroomdetails" element={<SearchMainPage />} />
//           {/* <Route path="/roomdetails/:roomId" element={<RoomMainPage />} /> */}
//           <Route path='/payment' element={<BookingMainPage/>}     />
//           <Route path='/finalpayment' element={<FinalPaymentPage/>}     />
//       </Routes>
//     </Router>
//   );
// };

// export default Approuter;
