// import React from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import IMG2 from "../../../../assets/img/slider/2.jpg"
// import IMG3 from "../../../../assets/img/slider/3.jpg"
// import IMG5 from "../../../../assets/img/slider/1.jpg"

// const RoomPageSlider = () => {
//   const carouselSettings = {
//     dots: false,
//     arrows: false,
//     infinite: true,
//     speed: 500,
//     autoplay: true,
//     autoplaySpeed: 5000,
//     fade: true,
//     cssEase: 'linear',
//   };
//   return (
//     <header className="header slider">
//       <Slider {...carouselSettings}>
//         <div className="text-center item bg-img" data-overlay-dark="3" style={{ backgroundImage: `url(${IMG3})` }}></div>
//         <div className="text-center item bg-img" data-overlay-dark="3" style={{ backgroundImage: `url(${IMG2})` }}></div>
//         <div className="text-center item bg-img" data-overlay-dark="3" style={{ backgroundImage: `url(${IMG5})` }}></div>
//       </Slider>
//       <div className="arrow bounce text-center">
//         <a href="#" data-scroll-nav="1" className="">
//           <i className="ti-arrow-down"></i>
//         </a>
//       </div>
//     </header>
//   );
// };

// export default RoomPageSlider;

import React from 'react';
import Slider from 'react-slick';
import ImgSlider1 from '../../../../assets/img/slider/1.jpg';
import ImgSlider2 from '../../../../assets/img/slider/2.jpg';
import ImgSlider3 from '../../../../assets/img/slider/3.jpg';
import { NavLink } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const RoomPageSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 5000, // Set the duration for each slide in milliseconds
    autoplaySpeed: 2000, // Set the interval between slides in milliseconds (change this value)
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: 'linear',
  };

  return (
    <Slider {...settings}>
      <div className="text-center item bg-img fhtd" data-overlay-dark="2">
        <img src={ImgSlider1} alt="Slider 1" />
        {/* <div className="v-middle caption">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <span>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                </span>
                <h4>Luxury Hotel & Best Resort</h4>
                <h1>Enjoy a Luxury Experience</h1>
              
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="text-center item bg-img" data-overlay-dark="2">
        <img src={ImgSlider2} alt="Slider 2" />
        {/* <div className="v-middle caption">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <span>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                </span>
                <h4>Unique Place to Relax & Enjoy</h4>
                <h1>The Perfect Base For You</h1>
              
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="text-center item bg-img" data-overlay-dark="3">
        <img src={ImgSlider3} alt="Slider 3" />
        {/* <div className="v-middle caption">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <span>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                </span>
                <h4>The Ultimate Luxury Experience</h4>
                <h1>Enjoy The Best Moments of Life</h1>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </Slider>
  );
};

export default RoomPageSlider;
