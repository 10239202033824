import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_BOOKING } from "../../../../utils/config";

const BookingFormPage = () => {
  const location = useLocation();
  const { roomId, user_id } = location.state;
  const [validationErrors, setValidationErrors] = useState({});
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    check_in: "",
    check_out: "",
    adults: "",
    children: "",
    total_guests: "",
    guest_name: "",
    first_name: "",
    last_name: "",
    user_id: localStorage.getItem("user_id"),
    email: "",
    phone: "",
    country: "",
    coupon_code: "",
    payement_type: "",
    room_id: roomId,
  });

  console.log("userid in bookingformpage", localStorage.getItem("user_id"));

  const handleDateChange = (fieldName, date) => {
    if (
      fieldName === "check_out" &&
      formData.check_in &&
      date >= formData.check_in
    ) {
      setFormData((prevFormData) => ({ ...prevFormData, [fieldName]: date }));
    } else if (fieldName === "check_in" && date >= new Date()) {
      setFormData((prevFormData) => ({ ...prevFormData, [fieldName]: date }));
      setFormData((prevFormData) => ({ ...prevFormData, check_out: "" }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (
      (name === "total_guests" || name === "children" || name === "adults") &&
      parseInt(value) < 0
    ) {
      return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        API_BOOKING,
        formData
      );
      console.log("ROOMID IN BOOKINGPAGE" + roomId);
      console.log("booking api response", response.data);

      if (response.data.status === "true") {
        const { amount, booking_id, original_price, discount } =
          response.data.data;
        navigate("/finalpayment", {
          state: { amount, booking_id, original_price, discount },
        });
      } else {
        if (response.data.status === "false") {
          const errorData = response.data;
          setValidationErrors(errorData.errors || {});
          console.log("errordata " + errorData.message);
          alert(errorData.message);
        } else {
          setValidationErrors({});
        }
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  };

  const handleCouponCodeChange = (event) => {
    const { value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      coupon_code: value,
    }));
  };

  const isHalfPaymentDisabled = () => {
    const currentDate = new Date();
    const checkInDate = formData.check_in;

    if (!checkInDate) {
      return true;
    }

    const timeDifference = checkInDate.getTime() - currentDate.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    return daysDifference < 29;
  };

  return (
    <div className="mb-5 shadow-soft bg-white rounded-sm mt-5">
      <div className="pt-4 pb-5 px-5 pay-details">
        <h3
          id="scroll-description"
          className="font-size-21 font-weight-bold text-dark mb-4"
        >
          Let us know who you are
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-6 mb-4">
              <div className="js-form-message">
                <label className="form-label">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="first_name"
                  placeholder=""
                  aria-label=""
                  value={formData.first_name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <input type="hidden" name="room_id" value={roomId} />
            <input type="hidden" name="user_id" value={user_id} />

            <div className="col-sm-6 mb-4">
              <div className="js-form-message">
                <label className="form-label">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="last_name"
                  placeholder=""
                  aria-label=""
                  value={formData.last_name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <div className="js-form-message">
                <label className="form-label">E-mail address</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  placeholder=""
                  aria-label=""
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <div className="js-form-message">
                <label className="form-label">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  placeholder=""
                  aria-label=""
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-sm-6 mb-4">
              <div className="js-form-message">
                <label className="form-label">Country</label>
                <input
                  type="text"
                  className="form-control"
                  name="country"
                  placeholder=""
                  aria-label=""
                  value={formData.country}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="col-6 mb-4">
              <div className="js-form-message">
                <label className="form-label">Total Guests</label>
                <input
                  type="number"
                  className="form-control"
                  name="total_guests"
                  placeholder=""
                  aria-label=""
                  value={formData.total_guests}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-6 mb-4">
              <div className="js-form-message">
                <label className="form-label">Guest Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="guest_name"
                  placeholder=""
                  aria-label=""
                  value={formData.guest_name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-6 mb-4">
              <div className="js-form-message">
                <label className="form-label">Children</label>
                <input
                  type="number"
                  className="form-control"
                  name="children"
                  placeholder=""
                  aria-label=""
                  value={formData.children}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-6 mb-4">
              <div className="js-form-message">
                <label className="form-label">Adults</label>
                <input
                  type="number"
                  className="form-control"
                  name="adults"
                  placeholder=""
                  aria-label=""
                  value={formData.adults}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-6 mb-4 datepicker-container">
              <div className="js-form-message">
                <label htmlFor="checkInDate" className="form-label">
                  Check-in Date
                </label>
                <DatePicker
                  id="checkInDate"
                  selected={formData.check_in}
                  onChange={(date) => handleDateChange("check_in", date)}
                  className={`form-control ${
                    formData.check_out ? "" : "react-datepicker__day--faded"
                  }`}
                  name="checkInDate"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select check-in date"
                  required
                  aria-label="Select check-in date"
                  minDate={new Date()}
                />
              </div>
            </div>
            <div className="col-6 mb-4">
              <div className="js-form-message">
                <label htmlFor="checkOutDate" className="form-label">
                  Check-out Date
                </label>
                <DatePicker
                  id="checkOutDate"
                  selected={formData.check_out}
                  onChange={(date) => handleDateChange("check_out", date)}
                  className={`form-control ${
                    formData.check_in ? "" : "react-datepicker__day--faded"
                  }`}
                  name="checkOutDate"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select check-out date"
                  required
                  aria-label="Select check-out date"
                  minDate={formData.check_in || new Date()}
                />
              </div>
            </div>

            <div className="col-6 mb-4">
              <div className="js-form-message">
                <label className="form-label">Coupon Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="coupon_code"
                  placeholder=""
                  aria-label=""
                  value={formData.coupon_code}
                  onChange={handleCouponCodeChange}
                />
              </div>
            </div>

            <div className="col-12 mb-4">
              <div className="js-form-message">
                <label htmlFor="paymentType" className="form-label">
                  Payment Type
                </label>
                <select
                  id="paymentType"
                  className="form-select"
                  name="payment_type"
                  value={formData.payment_type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Payment Type</option>
                  <option value="0" disabled={isHalfPaymentDisabled()}>
                    Half Payment
                  </option>
                  <option value="1">Full Payment</option>
                </select>
              </div>
            </div>

            <div className="col-12">
              <div className="text-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-wide rounded-sm transition-3d-hover font-size-16 font-weight-bold py-3"
                >
                  BOOK NOW
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingFormPage;