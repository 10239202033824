import React, { useEffect } from "react";
import SmoothScroll from "smooth-scroll";
import { NavLink } from "react-router-dom";

const Footer = ({ scrollToTop }) => {
  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };
  useEffect(() => {
    // Initialize SmoothScroll
    const smoothScroll = new SmoothScroll("a[data-scroll]", {
      speed: 500,
      easing: "easeInOutCubic",
    });

    return () => {
      // Clean up when the component unmounts
      smoothScroll.destroy();
    };
  }, []);
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-column footer-about">
                <h3 className="footer-title">About Us</h3>
                <p className="footer-about-text">
                  Welcome to LLuxe Homes, where comfort is everything. Beautiful
                  room presentations, straightforward booking & reservation
                  options, & a whole lot more awaits here.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-3 offset-md-1">
              <div className="footer-column footer-explore clearfix">
                <h3 className="footer-title">Explore</h3>
                <ul className="footer-explore-list list-unstyled">
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/about">About</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">Contact</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-column footer-contact">
                <h3 className="footer-title">Contact</h3>
                <p className="footer-contact-text">
                  Opening Hours: Monday To Sunday 8am to 11pm
                </p>
                <div className="footer-contact-info">
                  <p className="footer-contact-phone">
                    <span className="fa fa-phone"></span> +44 7877 766886
                  </p>
                  <p className="footer-contact-mail">info@lluxe.com</p>
                </div>

                <div className="footer-about-social-list">
                  <a href="#">
                    <i className="fa fa-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-youtube"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-pinterest"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-bottom-inner">
                <p className="footer-bottom-copy-right">
                  © Copyright 2021 LLuxe Properties
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <button
        className="scroll-top"
        style={{ backgroundColor: "black" }}
        aria-label="Scroll to top"
        onClick={scrollToTop}
      >
        <i className="fa fa-angle-up" aria-hidden="true"></i>
      </button> */}
      {/* <button
      className="scroll-top"
      style={{ backgroundColor: "black" }}
      aria-label="Scroll to top"
      onClick={scrollToTop}
    >
      <i className="fa fa-angle-up" aria-hidden="true"></i>
    </button> */}
    </footer>
  );
};

export default Footer;
