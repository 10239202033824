// // OTPInput.js
// import React from 'react';

// const OTPInput = ({ length, values, setValues }) => {
//   const handleChange = (index, event) => {
//     const inputValue = event.target.value;
//     if (!isNaN(inputValue)) {
//       const newValues = [...values];
//       newValues[index] = inputValue;
//       setValues(newValues);
//     }
//   };

//   return (
//     <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
//       {Array.from({ length }).map((_, index) => (
//         <input
//           key={index}
//           className="m-2 text-center form-control rounded"
//           type="text"
//           maxLength="1"
//           value={values[index]}
//           onChange={(e) => handleChange(index, e)}
//         />
//       ))}
//     </div>
//   );
// };

// export default OTPInput;


// OTPInput.js
import React from 'react';

const OTPInput = ({ length, values, setValues }) => {
  const handleChange = (index, event) => {
    const inputValue = event.target.value;
    if (!isNaN(inputValue)) {
      const newValues = [...values];
      newValues[index] = inputValue;
      setValues(newValues);
    }
  };

  return (
    <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
      {Array.from({ length }).map((_, index) => (
        <input
          key={index}
          className="m-2 text-center form-control rounded"
          type="text"
          maxLength="1"
          value={values[index]}
          onChange={(e) => handleChange(index, e)}
        />
      ))}
    </div>
  );
};

export default OTPInput;
