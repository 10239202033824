// import React, { useState, useEffect } from "react";
// import RoomContentPage from "../../roomdetails/RoomContent/RoomContentPage";
// import SearchRoomCard from "./SearchRoomCard";
// import axios from "axios";

// const SearchRoomSection = ({ bookingData }) => {
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [selectedRoomId, setSelectedRoomId] = useState(null);

//   useEffect(() => {
//     setLoading(false);
    
//   }, []);

//   const handleRoomCardClick = (roomId) => {
//     console.log("Function Call" + roomId);
//     setSelectedRoomId(roomId);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     try {
//       if (selectedRoomId) {
//         console.log("Selected Room ID:", selectedRoomId);
//         // Do something with the selected room ID
//       } else {
//         // Handle case when no room is selected
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   if (loading) {
//     return <p>Loading...</p>;
//   }

//   if (error) {
//     return <p>{error}</p>;
//   }
  
// console.log("hello")
//   return (
//     <section className="section-padding">
//       <div className="container">
//         <form onSubmit={handleSubmit}>
//           {bookingData &&
//             bookingData.map((room, index) => (
//               <div key={index}>
//                 <SearchRoomCard room={room} onRoomCardClick={handleRoomCardClick} />
//               </div>
//             ))}

//           {console.log(selectedRoomId)}

//           {selectedRoomId && <RoomContentPage roomId={selectedRoomId} />}
//         </form>
//       </div>
//     </section>
//   );
// };

// export default SearchRoomSection;


import React, { useState, useEffect } from "react";
import RoomContentPage from "../../roomdetails/RoomContent/RoomContentPage";
import SearchRoomCard from "./SearchRoomCard";
import axios from "axios";

const SearchRoomSection = ({ bookingData }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedRoomId, setSelectedRoomId] = useState(null);

  const handleRoomCardClick = (roomId) => {
    console.log("Function Call" + roomId);
    setSelectedRoomId(roomId);
  };

    console.log(bookingData)

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (selectedRoomId) {
        console.log("Selected Room ID:", selectedRoomId);
        // Do something with the selected room ID
      } else {
        console.log('Something Error')
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        setLoading(false);
      } catch (error) {
        setError("An error occurred while fetching data from the server.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    // <section className="section-padding">
    //   <div className="container">
    //     <form onSubmit={handleSubmit}>
    //       {bookingData &&
    //         bookingData.map((room, index) => (
    //           <div key={index}>
    //             <SearchRoomCard room={room} onRoomCardClick={handleRoomCardClick} />
    //             {console.log(index)}
    //           </div>
    //         ))}

    //       {console.log(selectedRoomId)}

    //       {selectedRoomId && <RoomContentPage roomId={selectedRoomId} />}
    //     </form>
    //   </div>
    // </section>
    <section className="section-padding">
    <div className="container">
      <form onSubmit={handleSubmit}>
        {/* Check if bookingData is an array before mapping */}
        {Array.isArray(bookingData) && bookingData.length > 0 ? (
          bookingData.map((room, index) => (
            <div key={index}>
              <SearchRoomCard room={room} onRoomCardClick={handleRoomCardClick} />
              {console.log(index)}
            </div>
          ))
        ) : (
          <p>No room data available.</p>
        )}

        {console.log(selectedRoomId)}

        {selectedRoomId && <RoomContentPage roomId={selectedRoomId} />}
      </form>
    </div>
  </section>

  );
};

export default SearchRoomSection;






























