import React from 'react'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../../../common/header/Header'
import Clients from '../../home/HomeClient/Clients'
import Footer from '../../../common/footer/Footer'
// import Reservations from '../../home/HomeReservationsBookingPage/Reservations'
// import PricingCard from '../../home/HomePricingPage/PricingCard'
import SimilarRooms from '../SimilarRoom/SimilarRooms'
import ServicesSection from '../../home/HomeServicesPage.js/ServicesSection'
// import PricingSection from '../../home/HomePricingPage/PricingSection'
import RoomContentPage from '../RoomContent/RoomContentPage'
import RoomPageSlider from '../RoomPageSlider/RoomPageSlider'
import HeaderSlider from '../../home/HomeSliderPage/HeaderSlider';
import ViewReview from '../../review/ViewReview';
// import Rooms from '../../home/HomeRoomPage/Rooms';

const RoomMainPage = () => {
  return (
    <>
         <Header/>
         {/* <HeaderSlider/> */}
         <RoomPageSlider/>
         <RoomContentPage/>
         {/* <SimilarRooms/> */}
         {/* <Rooms/> */}
         {/* <PricingSection/> */}
         {/* <Reservations/> */}
         {/* <Clients/> */}
         <ViewReview/>
         <Footer/>
    </>
  )
}

export default RoomMainPage