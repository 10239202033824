import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ForgotPasswordPage = () => {

  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordReset = (event) => {
    event.preventDefault();
    axios
    .post("https://lluxe.sbwares.com/api/forget_password", { email })
    .then((response) => {
      console.log("API Response:", response.data);
      if (response.data.status === 'true') {
        alert("Password reset instructions sent to your email.");
        navigate("/login");
      } else {
        alert("Incorrect credentials. Please check your email and try again.");
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      alert("Error requesting password reset. Please try again later.");
    });


};

  return (
    <section className="contact section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
          <div className="reg">
            <h3 className="text-center mb-30">Forgot Password</h3>
            <div>
              <h3></h3>
              <form onSubmit={handlePasswordReset}>
                <div>
                  <label>Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                </div>
                <div className="col-md-12">
                <div className="text-center">
                  <button type="submit" className="butn-dark2">
                    <span>Submit</span>
                  </button>
                </div>
              </div>
              </form>
            </div>  </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPasswordPage;
