// // VerifyAccount.js
// import React, { useState } from 'react';
// import OTPInput from './OTPInput';

// const VerifyAccountPage = () => {
//   const [otpValues, setOTPValues] = useState(['', '', '', '', '', '']);

//   return (
//     <section className="contact section-padding">
//       <div className="container d-flex justify-content-center align-items-center">
//         <div className="position-relative col-md-6">
//           <div className="p-2 text-center">
//             <h3>Please enter the one time password<br />to verify your account</h3>
//             <div>
//               <span>A code has been sent to</span>
//               <small>*******98@gmail.com</small>
//             </div>

//             <OTPInput length={6} values={otpValues} setValues={setOTPValues} />

//             <div className="mt-4">
//               <button type="submit" className="butn-dark2"><span>Validate</span></button>
//             </div>
//           </div>
//           <div className="card-2">
//             <div className="content d-flex justify-content-center align-items-center">
//               <span>Didn't get the code</span>
//               <a href="#" className="text-decoration-none ms-3">Resend(1/3)</a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default VerifyAccountPage;


import React, { useState } from 'react';
import OTPInput from './OTPInput';
import { API_OTP } from '../../../utils/config';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const VerifyAccountPage = () => {
  const [otpValues, setOTPValues] = useState(['', '', '', '']);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');

  const [verificationSuccess, setVerificationSuccess] = useState(false);

  const handleOTPSubmit = () => {
    const otp = otpValues.join('');
    const apiUrl = API_OTP;

    const formData = {
      otp,
      user_id: userId,
    };

    axios.post(apiUrl, formData)
      .then((response) => {
        console.log(response.data);

        setVerificationSuccess(true);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };


  const navigate = useNavigate();
  const handleLoginRedirect = () => {
    navigate('/login'); 
  };

  return (
    <section className="contact section-padding">
      <div className="container d-flex justify-content-center align-items-center">
        <div className="position-relative col-md-6">
          <div className="p-2 text-center">
            <h3>Please enter the one time password<br />to verify your account</h3>
            <div>
              <span>A code has been sent to</span>
              <small>**</small>
            </div>

            <OTPInput length={4} values={otpValues} setValues={setOTPValues} />

            <div className="mt-4">
              <button type="button" onClick={handleOTPSubmit} className="butn-dark2">
                <span>Validate</span>
              </button>
            </div>

            {verificationSuccess && (
              <div>
                <p>Registration is successful!</p>
                <button type="button" onClick={handleLoginRedirect} className="butn-dark2">
                  <span>Go to Login</span>
                </button>
              </div>
            )}
          </div>
          <div className="card-2">
            {/* <div className="content d-flex justify-content-center align-items-center">
              <span>Didn't get the code</span>
              <a href="#" className="text-decoration-none ms-3">Resend(1/3)</a>
            </div> */}
          </div>
        </div>
      </div>

      <input type="hidden" name="user_id" value={userId} />
    </section>
  );
};

export default VerifyAccountPage;
