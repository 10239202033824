import React from "react";
import BookingFormPage from "./bookingformpage/BookingFormPage";
import PaymentProcess from "./paymentprocesspage/PaymentProcess";

const LeftPart = () => {
  return (
    <div className="col-lg-8 col-xl-9">
      <BookingFormPage />
      {/* <PaymentProcess /> */}
    </div>
  );
};

export default LeftPart;
