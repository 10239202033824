// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import TeamImg1 from '../../../../assets/img/team/1.jpg';
// import TeamImg4 from '../../../../assets/img/team/4.jpg';
// import TeamImg5 from '../../../../assets/img/team/5.jpg';


// const TeamSection = () => {
//   const teamMembers = [
//     {
//       name: 'Valentina Karla',
//       position: 'General Manager',
//       email: 'valentina@hotel.com',
//       image: TeamImg4,
//     },
//     {
//       name: 'Micheal White',
//       position: 'Guest Service Department',
//       email: 'micheal@hotel.com',
//       image: TeamImg1,
//     },
    
//     {
//       name: 'Mariana Dana',
//       position: 'F&B Manager',
//       email: 'mariana@hotel.com',
//       image: TeamImg5,
//     },
    
//   ];

//   const settings = {
//     dots: false,
//     infinite: true,
//     autoplay: true,
//     speed: 5000, // Set the duration for each slide in milliseconds
//     autoplaySpeed: 2000, // Set the interval between slides in milliseconds (change this value)
//     slidesToShow: 3,
//     slidesToScroll: 1,
//   };
//   const slideStyle = {
//     margin: 'flex', // Add margin between slides to create a gap
//   };

//   const imgStyle = {
//     marginRight: '15px',
//     textAlign: 'center', // Center align the team member images
//   };

//   return (
//     <section className="team section-padding bg-cream">
//       <div className="container">
//         <div className="row">
//           <div className="col-md-12">
//             <div className="section-subtitle">Professionals</div>
//             <div className="section-title">Meet The Team</div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-md-12">
//             <Slider {...settings}>
//               {teamMembers.map((member, index) => (
//                 <div key={index} className="item" style={slideStyle}>
//                   <div className="img" style={imgStyle}>
//                     <img src={member.image} alt="" />
//                   </div>
//                   <div className="info">
//                     <h6>{member.name}</h6>
//                     <p>{member.position}</p>
//                     <div className="social valign">
//                       <div className="full-width">
//                         <NavLink><i className="ti-instagram"></i></NavLink>
//                         <NavLink><i className="ti-twitter"></i></NavLink>
//                         <NavLink><i className="ti-facebook"></i></NavLink>
//                         <NavLink><i className="ti-pinterest"></i></NavLink>
//                         <p>{member.email}</p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default TeamSection;


import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { API_ABOUT } from '../../../../utils/config';
import  LottieSpinner from "../../../common/lottieSpinner/LottieSpinner"
 



const TeamSection = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const apiUrl = API_ABOUT;

    axios
      .get(apiUrl)
      .then((response) => {
        const data = response.data;
        if (data && data.team && Array.isArray(data.team)) {
          setTeamMembers(data.team);
        } else {
          setError('No team member data found in the API response.');
        }
        setLoading(false);
      })
      .catch((error) => {
        setError('Error fetching team member data from the API.');
        setLoading(false);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };
  const slideStyle = {
    margin: 'flex',
  };

  const imgStyle = {
    marginRight: '15px',
    textAlign: 'center',
  };

  if (loading) {
    // return <p>Loading...<LottieSpinner/></p>;
    return <p>Loading...<LottieSpinner/> </p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className="team section-padding bg-cream">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-subtitle">Professionals</div>
            <div className="section-title">Meet The Team</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Slider {...settings}>
              {teamMembers.map((member, index) => (
                <div key={index} className="item" style={slideStyle}>
                  <div className="img" style={imgStyle}>
                    <img src={member.image} alt={member.name} />
                  </div>
                  <div className="info">
                    <h6>{member.name}</h6>
                    <p>{member.designation}</p>
                    <div className="social">
                      <div className="full-width">
                        <NavLink><i className="fa fa-instagram"></i></NavLink>
                        <NavLink><i className="fa fa-twitter"></i></NavLink>
                        <NavLink><i className="fa fa-facebook"></i></NavLink>
                        <NavLink><i className="fa fa-pinterest"></i></NavLink>
                        <a href={`mailto:${member.email}`}>
                          <i className="ti-email"></i>
                        </a>
                      </div>
                      <p>{member.email}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
