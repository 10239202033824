// import React from "react";

// const ModalComponent = ({ togglePopup }) => {
//   return (
//     <div>
//       <div
//         className="modal fade modal-contact-popup-one"
//         id="contactModal"
//         tabIndex="-1"
//         role="dialog"
//         aria-labelledby="contactModalTitle"
//         aria-hidden="true"
//       >
//         <div className="modal-dialog modal-dialog-centered" role="document">
//           <main className="main-body modal-content clearfix">
//           <button type="button" className="close" onClick={togglePopup} data-dismiss="modal">
// </button>

//             <div className="left-side">
//               <div className="d-flex flex-column justify-content-between h-100">
//                 <div className="row">
//                   <div className="col-xl-10 col-lg-8 m-auto">
//                     <blockquote>
//                       “We never dreamed about success. We worked for it.”
//                     </blockquote>
//                     <span className="bio">Hastech</span>
//                   </div>
//                 </div>

//               </div>
//             </div>
//             <div className="right-side">
//               <h2 className="form-title">Contact us</h2>
//               <form
//                 id="conatct"
//                 method="post"
//                 action="send-mail.php"
//                 data-toggle="validator"
//               >
//                 <div className="messages"></div>
//                 <div className="row controls">
//                   <div className="col-12">
//                     <div className="input-group-meta form-group mb-15">
//                       <label>Name*</label>
//                       <input
//                         type="text"
//                         placeholder="Your Name"
//                         name="Fname"
//                         required="required"
//                         data-error="Name is required."
//                         className="form-control"
//                       />

//                       <div className="help-block with-errors"></div>
//                     </div>
//                   </div>
//                   <div className="col-12">
//                     <div className="input-group-meta form-group mb-35">
//                       <label>Email*</label>
//                       <input
//                         type="email"
//                         placeholder="Email Address"
//                         name="email"
//                         required="required"
//                         data-error="Valid email is required."
//                         className="form-control"
//                       />
//                       <div className="help-block with-errors"></div>
//                     </div>
//                   </div>
//                   <div className="col-12">
//                     <div className="input-group-meta form-group mb-25">
//                       <label>Message*</label>

//                       <textarea
//                         placeholder="Your message"
//                         name="message"
//                         required="required"
//                         data-error="Please,leave us a message."
//                         className="form-control"
//                       ></textarea>

//                       <div className="help-block with-errors"></div>
//                     </div>
//                   </div>
//                   <div className="col-12">
//                     <button
//                       className="theme-btn-seven hover-reverse-gr-bg-one w-100"
//                       name="submit"
//                       id="contact-submit"
//                       data-submit="...Sending"
//                     >
//                       Send Message
//                     </button>
//                   </div>
//                 </div>
//               </form>
//             </div>
//           </main>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ModalComponent;

// import React, { useState } from "react";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import Modal from "react-bootstrap/Modal";
// import Rating from "react-rating-stars-component";

// const ModalComponent = () => {
//   const [show, setShow] = useState(false);
//   const [name, setName] = useState("");
//   const [comment, setComment] = useState("");
//   const [rating, setRating] = useState(0);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   return (
//     <>
//       <Button onClick={handleShow}>Add reviw</Button>

//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>RATE US</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//               <Form.Label>NAME</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Name"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 required
//                 autoFocus
//               />
//             </Form.Group>
//             <Form.Group
//               className="mb-3"
//               controlId="exampleForm.ControlTextarea1"
//             >
//               <Form.Label>FEEDBACK</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Comment"
//                 value={comment}
//                 onChange={(e) => setComment(e.target.value)}
//                 required
//                 autoFocus
//               />
//             </Form.Group>
//             {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//             <Form.Label>Rating</Form.Label>
//             <Form.Control
//                  type="number"
//                   placeholder="Rating"
//                  value={rating}
//                    onChange={(e) => setRating(parseInt(e.target.value))}
//                   required
//                  autoFocus
//             />
//           </Form.Group> */}
//             <Form.Group className="mb-3" controlId="rating">
//               <Form.Label>Rating</Form.Label>
//               <Rating
//                 count={5}
//                 value={rating}
//                 size={24}
//                 onChange={(newRating) => setRating(newRating)}
//               />
//             </Form.Group>
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleClose}>
//             Save Changes
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default ModalComponent;

// import React, { useState } from "react";
// import axios from "axios"
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import Modal from "react-bootstrap/Modal";
// import Rating from "react-rating-stars-component";

// const ModalComponent = () => {
//   const [show, setShow] = useState(false);
//   const [name, setName] = useState("");
//   const [comment, setComment] = useState("");
//   const [rating, setRating] = useState(0);

//   const handleClose = async () => {
//     try {
//       // Send a POST request to the backend API
//       const response = await axios.post("http://lluxe.sbwares.com/api/rating", {
//         name,
//         comment,
//         rating,
//         booking_id: "your_booking_id_here", // Replace with actual booking ID
//       });

//       // Handle success response
//       console.log("Review submitted successfully:", response.data);

//       // Close the modal
//       setShow(false);
//     } catch (error) {
//       // Handle error
//       console.error("Error submitting review:", error);
//     }
//   };

//   const handleShow = () => setShow(true);

//   return (
//     <>
//       <Button onClick={handleShow}>Add reviw</Button>

//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>RATE US</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//               <Form.Label>NAME</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Name"
//                 value={name}
//                 onChange={(e) => setName(e.target.value)}
//                 required
//                 autoFocus
//               />
//             </Form.Group>
//             <Form.Group
//               className="mb-3"
//               controlId="exampleForm.ControlTextarea1"
//             >
//               <Form.Label>FEEDBACK</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Comment"
//                 value={comment}
//                 onChange={(e) => setComment(e.target.value)}
//                 required
//                 autoFocus
//               />
//             </Form.Group>
//             <Form.Group className="mb-3" controlId="rating">
//               <Form.Label>Rating</Form.Label>
//               <Rating
//                 count={5}
//                 value={rating}
//                 size={24}
//                 onChange={(newRating) => setRating(newRating)}
//               />
//             </Form.Group>
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleClose}>
//             Save Changes
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default ModalComponent;

import React, { useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Rating from "react-rating-stars-component";

const ModalComponent = () => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);

  const handleSubmitReview  = async () => {
    try {
      // Send a POST request to the backend API
      const response = await axios.post("http://lluxe.sbwares.com/api/rating", {
        name,
        comment,
        rating,
        booking_id: "your_booking_id_here", // Replace with actual booking ID
      });

      // Handle success response
      console.log("Review submitted successfully:", response.data);

      // Close the modal
      setShow(false);
    } catch (error) {
      // Handle error
      console.error("Error submitting review:", error);
    }
  };
  console.log('Name' + name )

  const handleShow = () => setShow(true);

  return (
    <>
      <Button onClick={handleShow}>Add reviw</Button>

      <Modal show={show} onHide={handleSubmitReview}>
        <Modal.Header closeButton>
          <Modal.Title>RATE US</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>NAME</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>FEEDBACK</Form.Label>
              <Form.Control
                type="text"
                placeholder="Comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                required
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="rating">
              <Form.Label>Rating</Form.Label>
              <Rating
                count={5}
                value={rating}
                size={24}
                onChange={(newRating) => setRating(newRating)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          <Button variant="primary" onClick={handleSubmitReview}>
           
            Submit Review {/* Update button text */}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalComponent;
