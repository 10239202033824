import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStripe, useElements, ElementsConsumer, CardElement } from "@stripe/react-stripe-js";

import CardSection from "./CardSection";

function CheckoutForm({ booking_id, amount, onPaymentSuccess }) {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  



  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      console.log(result.error.message);
    } else {
      console.log(result.token);

      const data = {
        booking_id: booking_id,
        amount: amount,
        token: result.token.id,
        payment_method: 0,
      };

      try {
        const response = await axios.post(
          "https://backend.lluxe.com/api/stripe_method",
          data
        );

        console.log(response.data);
  console.log('data ' + data.token)


        // if (response.data.success ) {
          if (response.data.status === "true"){
          onPaymentSuccess("Payment successful!");
          navigate("/thankyou");
          console.log("API Response:fdf", response.data);
        } else {
          console.error("Payment failed:", response.data.error);

        }
      } catch (error) {
        console.error("Payment error:", error);
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <CardSection />
        <button disabled={!stripe} className="btn-pay">
          Buy Now
        </button>
      </form>
    </div>
  );
}

export default function InjectedCheckoutForm({booking_id, amount}) {
  return (
    <>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <CheckoutForm
            stripe={stripe}
            elements={elements}
            booking_id={booking_id}
            amount={amount}
            onPaymentSuccess={(message) => {
            }}
          />
        )}
      </ElementsConsumer>
      <ToastContainer />
    </>
  );
}
