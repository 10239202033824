
export const API_URL = 'https://backend.lluxe.com/api/home_api';
export const API_CONTACT = 'https://backend.lluxe.com/api/contact_queries';
export const API_ROOM = 'https://backend.lluxe.com/api/rooms_list';
export const API_LOGIN = 'https://backend.lluxe.com/api/login'
export const API_TOKEN = 'https://backend.lluxe.com/api/login'
export const API_OTP = 'https://backend.lluxe.com/api/verify_otp'
export const API_ABOUT = 'https://backend.lluxe.com/api/about_us'
export const API_BOOKING = 'https://backend.lluxe.com/api/bookings'
export const API_PAYMENT = 'https://backend.lluxe.com/api/stripe_payment'
export const API_HOME = "https://backend.lluxe.com/api/home_api"
export const API_CHECKNOW = "https://backend.lluxe.com/api/check_now"
export const API_BOOKNOW = "https://backend.lluxe.com/api/book_now"
export const API_CSRF = "https://backend.lluxe.com/api/csrf-token"
export const API_HOTELROOM = "https://backend.lluxe.com/api/hotel_rooms"
export const API_ROOMDETAILS =  "https://backend.lluxe.com/api/room_details"
export const API_POST_REVIEW = "https://backend.lluxe.com/api/rating"
export const API_GET_REVIEW = "https://backend.lluxe.com/api/rating"
export const API_STRIPE = "https://backend.lluxe.com/api/stripe_payment"
export const API_HOTEL_LIST = "https://backend.lluxe.com/api/hotel_list"
export const API_REGISTER = "https://backend.lluxe.com/api/register"
export const API_GET_PROPERTY = "https://backend.lluxe.com/api/get_property"
export const API_BOOK_PROPERTY = "https://backend.lluxe.com/api/book_property"

export const CLIENT_ID = process.env.CLIENT_ID || "AX0zPiyIutF3Q2ZsCsizYCPQVhxJxXCM1NW60Y3CKIE4BGDm0SWsI7w3o8d39YpRrRrgE8KKvk_-_wlo"
export const APP_SECRET = process.env.APP_SECRET || "EF5U5AMnjbifmR2juW6JEMN2YwtWd0uKgSDO1SCQ3zQzHrVrMOLTTrzKoA2v334Fa72bBRWNDVPib8Fv"