import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import IMG1 from "../../../../assets/img/team/1.jpg"
import IMG4 from "../../../../assets/img/team/4.jpg"
import IMG5 from "../../../../assets/img/team/5.jpg"
import IMG2 from "../../../../assets/img/slider/2.jpg"

const testimonialData = [
    {
        author: 'Emily Brown',
        review: 'Hotel dapibus asue metus the nec feusiate eraten miss hendreri net ve ante the lemon...',
        rating: 5,
        imgSrc: IMG4,
      },
      {
        author: 'Nolan White',
        review: 'Hotel dapibus asue metus the nec feusiate eraten miss hendreri net ve ante the lemon...',
        rating: 4,
        imgSrc: IMG1,
      },
      {
        author: 'Olivia Martin',
        review: 'Hotel dapibus asue metus the nec feusiate eraten miss hendreri net ve ante the lemon...',
        rating: 5,
        imgSrc: IMG5,
      },
];

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};
const backgroundStyle = {
  backgroundImage: `url(${IMG2})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const TestimonialSlider = () => {
  return (
    <section className="testimonials">
      <div className="background bg-img bg-fixed section-padding pb-0" style={backgroundStyle} data-overlay-dark="3">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="testimonials-box">
                <div className="head-box">
                  <h6>Testimonials</h6>
                  <h4>What Client's Say?</h4>
                  <div className="line"></div>
                </div>
                <Slider {...sliderSettings}>
                  {testimonialData.map((testimonial, index) => (
                    <div key={index} className="item">
                      <span className="quote"><img src="img/quot.png" alt="" /></span>
                      <p>{testimonial.review}</p>
                      <div className="info">
                        <div className="author-img"><img src={testimonial.imgSrc} alt="" /></div>
                        <div className="cont">
                          <span>
                            {[...Array(testimonial.rating)].map((_, i) => (
                              <i key={i} className="star-rating"></i>
                            ))}
                          </span>
                          <h6>{testimonial.author}</h6>
                          <span>Guest review</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSlider;
