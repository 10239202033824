
// import React from 'react'
// import { NavLink } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

// const SearchRoomCard = ({ room, onRoomCardClick}) => {
//   const navigate = useNavigate();
//   const { id, imgSrc, price, roomType, description, facilities, isLeftAligned } = room;

//   const handleDetailsClick = () => {
//     onRoomCardClick(id);
//     // navigate(`/roomdetails/${id}`);
//     navigate('/searchroomdetails',  { state: { roomId: id } })

//   };

//   return (
//     <div
//       style={{ marginTop: "234px" }}
//       className={`rooms2 mb-90 animate-box${isLeftAligned ? " left" : ""}`}
//       data-animate-effect="fadeInUp"
//     >
//       <div className="row">
//         {isLeftAligned && (
//           <div className="col-md-6">
//             <figure>
//               <img src={imgSrc} alt="" className="img-fluid" />
//             </figure>
//           </div>
//         )}
//         <div className="col-md-6">
//           <div className="caption">
//             {/* <h3>
//               {price} <span>/ Night</span>
//             </h3> */}
//             {/* <h4>
//               <NavLink to={`roomdetails/${id}`}>{roomType}</NavLink>
//             </h4> */}
//             <h4>
//               {roomType}
//             </h4>
//             <p>{description}</p>
//             <div className="row room-facilities">
//               {facilities.map((facility, index) => (
//                 <div key={index} className="col-md-6">
//                   <ul>
//                     <li>
//                       <img
//                         style={{ width: "30px" }}
//                         src={facility.image}
//                         alt={facility.name}
//                       />
//                       {facility.name}
//                     </li>
//                   </ul>
//                 </div>
//               ))}
//             </div>
//             <hr className="border-2" />
//             <div className="info-wrapper">
//               {/* <div className="more">
//                 <button className="link-btn" onClick={handleDetailsClick}>
//                   Details <i className="ti-arrow-right"></i>
//                 </button>
//               </div> */}
//               {/* <div className="butn-dark">
//                 <NavLink >
//                   <span>Book Now</span>
//                 </NavLink>
//               </div> */}
//               <h3>
//               {price} <span>/ Night</span>
//             </h3>
//               <div className="butn-dark">
//                 <button className="link-btn" onClick={handleDetailsClick}>
//                   Details <i className="ti-arrow-right"></i>
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//         {!isLeftAligned && (
//           <div className="col-md-6">
//             <figure>
//               <img src={imgSrc} alt="" className="img-fluid" />
//             </figure>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default SearchRoomCard



import React from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const SearchRoomCard = ({ room, onRoomCardClick }) => {
  const navigate = useNavigate();
  const { id, imgSrc, price, roomType, description, facilities, isLeftAligned } = room;

  const handleDetailsClick = () => {
    onRoomCardClick(id);
    navigate('/searchroomdetails', { state: { roomId: id } });
  };

  return (
    <div
      style={{ marginTop: '234px' }}
      className={`rooms2 mb-90 animate-box${isLeftAligned ? ' left' : ''}`}
      data-animate-effect="fadeInUp"
    >
      <div className="row">
        {isLeftAligned && (
          <div className="col-md-6">
            <figure>
              <img src={imgSrc} alt="" className="img-fluid" />
            </figure>
          </div>
        )}
        <div className="col-md-6">
          <div className="caption">
            <h4>{roomType}</h4>
            <p>{description}</p>
            <div className="row room-facilities">
              {facilities.map((facility, index) => (
                <div key={index} className="col-md-6">
                  <ul>
                    <li>
                      <img
                        style={{ width: '30px' }}
                        src={facility.image}
                        alt={facility.name}
                      />
                      {facility.name}
                    </li>
                  </ul>
                </div>
              ))}
            </div>
            <hr className="border-2" />
            <div className="info-wrapper">
              <h3>
                {price} <span>/ Night</span>
              </h3>
              <div className="butn-dark">
                <button className="link-btn" onClick={handleDetailsClick}>
                  Details <i className="ti-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        {!isLeftAligned && (
          <div className="col-md-6">
            <figure>
              <img src={imgSrc} alt="" className="img-fluid" />
            </figure>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchRoomCard;

