import React from 'react';

const PhoneCallButton = () => {
  const phoneNumber = '+447877766886';

  return (
    <div className="phone-call cbh-phone cbh-green cbh-show cbh-static" id="clbh_phone_div">
      <a
        id="WhatsApp-button"
        href={`https://wa.me/${phoneNumber}`}
        target="_blank"
        rel="noopener noreferrer"
        className="phoneJs"
        title="WhatsApp 360imagem"
      >
        <div className="cbh-ph-circle"></div>
        <div className="cbh-ph-circle-fill"></div>
        <div className="cbh-ph-img-circle1"></div>
      </a>
    </div>
  );
};

export default PhoneCallButton;
