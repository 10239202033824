import React from "react";
import BookingFormPage from "../bookingformpage/BookingFormPage";
import Footer from "../../../common/footer/Footer";
import Header from "../../../common/header/Header";
import BannerHeader from "../bannerHeader/BannerHeader.js";
import PaymentProcess from "../paymentprocesspage/PaymentProcess";
import HotelCard from "../sidebookdetail/HotelCard";
import LeftPart from "../LeftPart";
import RightPart from "../RightPart";

const BookingMainPage = () => {
  return (
    <div>
      <Header />
      <BannerHeader />

      <div className="container">
        <div className="row">
          {/* <BookingFormPage/> */}
          {/* <PaymentProcess/> */}
          {/* <HotelCard/> */}
          <LeftPart />
          <RightPart />
          
        </div>
      </div>
      <Footer />



    </div>
  );
};

export default BookingMainPage;
