// import React from "react";

// const ViewReview = () => {
//   return (
//     <>
//       <div className="review-container">
//         {/* <h2 className="review-heading">4.95 out of 5 stars from 61 reviews</h2> */}
//         <h2 className="review-heading">4.95 out of 5 stars</h2>
//         <div className="review-content">
//           <div className="review-item">
//             <h3 className="review-title">Christine</h3>
//             <p className="review-text">
//               Alvaro, Stefanio and their cats and dog all welcomed us very
//               warmly to their great apartment. The room is cozy and has a
//               comfortable bed, the shower is great too! Kitchen at disposal has
//               everything you might need, and the location makes it really easy
//               to get to anywhere you want to go! So if Copacabana is where you
//               like to be based while in Rio, this is your place! We're already
//               looking forward to being back next week!
//             </p>
//           </div>
//         </div>
//         <button className="show-all-reviews-button">Show all reviews</button>
//       </div>
//     </>
//   );
// };

// export default ViewReview;


// import React, { useState, useEffect } from "react";

// const ViewReview = () => {
//   const [reviews, setReviews] = useState([]);

//   useEffect(() => {
//     // Fetch data from the API
//     fetch("http://lluxe.sbwares.com/api/get_rating")
//       .then((response) => response.json())
//       .then((data) => {
//         // Assuming the API response contains an array of reviews
//         setReviews(data.reviews);
//       })
//       .catch((error) => {
//         console.error("Error fetching reviews:", error);
//       });
//   }, []);

//   return (
//     <>
//       <div className="review-container">
//         <h2 className="review-heading">4.95 out of 5 stars</h2>
//         <div className="review-content">
//           {reviews.map((review, index) => (
//             <div className="review-item" key={index}>
//               <h3 className="review-title">{review.name}</h3>
//               <p className="review-text">{review.comment}</p>
//             </div>
//           ))}
//         </div>
//         <button className="show-all-reviews-button">Show all reviews</button>
//       </div>
//     </>
//   );
// };

// export default ViewReview;

// import React, { useState, useEffect } from "react";

// const ViewReview = () => {
//   const [reviews, setReviews] = useState([]);

//   useEffect(() => {
//     // Fetch data from the API
//     fetch("http://lluxe.sbwares.com/api/get_rating")
//       .then((response) => response.json())
//       .then((data) => {
//         setReviews(data.data); // Set the 'reviews' state to 'data.data'
//       })
//       .catch((error) => {
//         console.error("Error fetching reviews:", error);
//       });
//   }, []);

//   return (
//     <div className="review-container">
//       <h2 className="review-heading">4.95 out of 5 stars</h2>
//       <div className="review-content">
//         {reviews.map((review, index) => (
//           <div className="review-item" key={index}>
//           <h2 className="review-title">Rating : {review.rating}</h2>
//             <h3 className="review-title">Name :{review.name}</h3>
//             <p className="review-text">Comment : {review.comment}</p>
//           </div>
//         ))}
//       </div>
//       <button className="show-all-reviews-button">Show all reviews</button>
//     </div>
//   );
// };

// export default ViewReview;


// import React, { useState, useEffect } from "react";
// import Rating from "react-rating-stars-component";

// const ViewReview = () => {
//   const [reviews, setReviews] = useState([]);

//   useEffect(() => {
//     // Fetch data from the API
//     fetch("http://lluxe.sbwares.com/api/get_rating")
//       .then((response) => response.json())
//       .then((data) => {
//         setReviews(data.data); // Set the 'reviews' state to 'data.data'
//       })
//       .catch((error) => {
//         console.error("Error fetching reviews:", error);
//       });
//   }, []);

//   return (
//     <div className="review-container">
//       <h2 className="review-heading">4.95 out of 5 stars</h2>
//       <div className="review-content">
//         {reviews.map((review, index) => (
//           <div className="review-item" key={index}>
//           <h3 className="review-title">Name: {review.name}</h3>
//             <h2 className="review-title">
//               <Rating
//                 value={parseInt(review.rating)}
//                 size={24}
//                 edit={false} // Disable user interaction
//               />
//             </h2>
            
//             <p className="review-text">Comment: {review.comment}</p>
//           </div>
//         ))}
//       </div>
//       <button className="show-all-reviews-button">Show all reviews</button>
//     </div>
//   );
// };

// export default ViewReview;

// import React, { useState, useEffect } from "react";

// const ViewReview = () => {
//   const [reviews, setReviews] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const reviewsPerPage = 4; // Number of reviews per page

//   useEffect(() => {
//     // Fetch data from the API based on the current page
//     fetch(`http://lluxe.sbwares.com/api/get_rating?page=${currentPage}`)
//       .then((response) => response.json())
//       .then((data) => {
//         setReviews(data.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching reviews:", error);
//       });
//   }, [currentPage]);

//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//   };

//   return (
//     <div className="review-container">
//       {/* Display reviews */}
//       <div className="review-content">
//       {reviews.map((review, index) => (
//           <div className="review-item" key={index}>
//          <h3 className="review-title">Name: {review.name}</h3>
//              <h2 className="review-title">
//                <Rating
//                 value={parseInt(review.rating)}
//                  size={24}
//                  edit={false} // Disable user interaction
//                />
//              </h2>
            
//              <p className="review-text">Comment: {review.comment}</p>
//            </div>
//          ))}
//       </div>

//       {/* Pagination */}
//       <div className="pagination">
//         {Array.from({ length: Math.ceil(totalReviews / reviewsPerPage) }).map(
//           (_, index) => (
//             <button
//               key={index}
//               onClick={() => handlePageChange(index + 1)}
//               className={currentPage === index + 1 ? "active" : ""}
//             >
//               {index + 1}
//             </button>
//           )
//         )}
//       </div>
//     </div>
//   );
// };

// export default ViewReview;


// import React, { useState, useEffect } from "react";
// import Rating from "react-rating-stars-component";

// const ViewReview = () => {
//   const [reviews, setReviews] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const reviewsPerPage = 4; // Number of reviews per page

//   useEffect(() => {
//     // Fetch data from the API based on the current page
//     fetch(`http://lluxe.sbwares.com/api/get_rating?page=${currentPage}`)
//       .then((response) => response.json())
//       .then((data) => {
//         setReviews(data.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching reviews:", error);
//       });
//   }, [currentPage]);

//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//   };

//   const totalPages = Math.ceil(reviews.length / reviewsPerPage);

//   const startIndex = (currentPage - 1) * reviewsPerPage;
//   const endIndex = startIndex + reviewsPerPage;

//   const currentReviews = reviews.slice(startIndex, endIndex);

//   return (
//     <div className="review-container">
//       {/* Display reviews */}
//       <div className="review-content">
//       {currentReviews.map((review, index) => (
//           <div className="review-item" key={index}>
//             <h3 className="review-title">Name: {review.name}</h3>
//             <h2 className="review-title">
//               <Rating
//                 value={parseInt(review.rating)}
//                 size={24}
//                 edit={false}
//               />
//             </h2>
//             <p className="review-text">Comment: {review.comment}</p>
//           </div>
//         ))}
//       </div>

//       {/* Pagination */}
//       <div className="pagination">
//         {Array.from({ length: totalPages }).map((_, index) => (
//           <button
//             key={index}
//             onClick={() => handlePageChange(index + 1)}
//             className={currentPage === index + 1 ? "active" : ""}
//           >
//             {index + 1}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ViewReview;


// ViewReview.js
import React, { useState, useEffect } from "react";
import Rating from "react-rating-stars-component";

const ViewReview = () => {
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 4; // Number of reviews per page

  useEffect(() => {
    // Fetch data from the API based on the current page
    fetch(`https://backend.lluxe.com/api/get_rating?page=${currentPage}`)
      .then((response) => response.json())
      .then((data) => {
        setReviews(data.data);
      })
      .catch((error) => {
        console.error("Error fetching reviews:", error);
      });
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(reviews.length / reviewsPerPage);

  const startIndex = (currentPage - 1) * reviewsPerPage;
  const endIndex = startIndex + reviewsPerPage;

  const currentReviews = reviews.slice(startIndex, endIndex);
  const leftReviews = currentReviews.slice(0, 2);
  const rightReviews = currentReviews.slice(2, 4);

  return (
    <div className="review-container">
    <div>Your Valuable Review's</div>
      {/* Display reviews */}
      <div className="review-content">
        <div className="review-column">
          {leftReviews.map((review, index) => (
            <div className="review-item" key={index}>
              <h3 className="review-title">Name: {review.name}</h3>
              <h2 className="review-title">
                <Rating value={parseInt(review.rating)} size={24} edit={false} />
              </h2>
              <p className="review-text">Comment: {review.comment}</p>
            </div>
          ))}
        </div>
        <div className="review-column">
          {rightReviews.map((review, index) => (
            <div className="review-item" key={index}>
              <h3 className="review-title"> {review.name}</h3>
              <h2 className="review-title">
                <Rating value={parseInt(review.rating)} size={24} edit={false} />
              </h2>
              <p className="review-text"> {review.comment}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Pagination */}
      <div className="pagination">
        {Array.from({ length: totalPages }).map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ViewReview;
