import React from 'react'
import Header from '../../../common/header/Header'
import RoomBannerHeader from '../roomheader/RoomBannerHeader'
import RoomSection from '../roomscardcomponent/RoomSection'
import RoomSearchSection from '../roomsearch/RoomSearchSection'
import PricingSection from '../../home/HomePricingPage/PricingSection'
import Clients from '../../home/HomeClient/Clients'
import Footer from '../../../common/footer/Footer'
import Reservations from '../../home/HomeReservationsBookingPage/Reservations'

const RoomsSuites = () => {
  return (
    <>
      <Header/>
      <RoomBannerHeader/>
      <RoomSection/>
      {/* <RoomSearchSection/> */}
      <PricingSection/>
      {/* <Reservations/> */}
      {/* <Clients/> */}
      <Footer/>
    </>
  )
}

export default RoomsSuites