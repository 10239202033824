import React from 'react';
import Lottie from 'lottie-react';
import animationData from './animation_lldoo55a.json';

const LottieAnimation = () => {
    return (
        <div style={{display:"flex", justifyContent:"center"}}>
            <Lottie
                animationData={animationData}
                loop={true}
                autoplay={true}
                style={{width:"80px" }}
            />
        </div>
    );
};

export default LottieAnimation;
