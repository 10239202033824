// import React from 'react';

// const PricingCard = ({ imgSrc, name, amount, features }) => {
//   return (
//     <div className="pricing-card">
//       <img src={imgSrc} alt="" />
//       <div className="desc">
//         <div className="name">{name}</div>
//         <div className="amount">{amount}</div>
//         <ul className="list-unstyled list">
//           {features.map((feature, index) => (
//             <li key={index}>
//               {feature.available ? <i className="ti-check"></i> : <i className="ti-close unavailable"></i>}
//               {feature.text}
//             </li>
//           ))}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default PricingCard;
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

const PricingCard = ({ imgSrc, name, amount, features }) => {
  return (
    <div className="pricing-card">
      <img src={imgSrc} alt={name} />
      <h3 className="pricing-name">{name}</h3>
      <p className="amount">{amount}</p>
      <div className="pricing-features">
        {features.map((feature, index) => (
          <div key={index} className="feature">
            <FontAwesomeIcon icon={feature.available ? faCheck : faTimes} className={feature.available ? 'available' : 'unavailable'} />
            <span className={feature.available ? 'available-text' : 'unavailable-text'}>{feature.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingCard;
