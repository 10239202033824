import React from 'react'
import Header from '../../common/header/Header'
import ContactBannerHeader from './ContactBannerHeader/ContactBannerHeader'
import ContactForm from './ContactForm '
import Footer from '../../common/footer/Footer'

const ContactPage = () => {
  return (
    <>
        <Header/>
        <ContactBannerHeader/>
        <ContactForm/>
        <Footer/>
    </>
  )
}

export default ContactPage