import React from 'react'
import Header from "../../common/header/Header"
import Footer from "../../common/footer/Footer"
import BannerHeader from "./BannerHeader/BannerHeader"
import SearchRoomSection from "../searchResult/searchroomcomponent/SearchRoomSection"
import Clients from '../home/HomeClient/Clients'
import RoomContentPage from '../roomdetails/RoomContent/RoomContentPage'
import ParentComponent from './searchroomcomponent/ParentComponent'



const SearchMainPage = () => {
  return (
    <>
        <Header/>
        <BannerHeader/>
       {/* <SearchRoomSection/> */}
       <ParentComponent/>
        <Clients/>
        <Footer/>
    </>
  )
}

export default SearchMainPage