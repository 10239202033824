import React from 'react'
import Header from '../../common/header/Header'
import RegistrationForm from './RegistrationForm'
import Footer from '../../common/footer/Footer'
import RegistrationBannerHeader from './RegistrationBannerHeader/RegistrationBannerHeader'

const RegistrationPage = () => {
  return (
    <>
        <Header/>
        <RegistrationBannerHeader/>
        <RegistrationForm/>
        <Footer/>
    </>
  )
}

export default RegistrationPage