// import React from 'react';

// const HotelFacilitiesSection = () => {
//   const facilitiesData = [
//     {
//       iconClass: 'flaticon-world',
//       title: 'Pick Up & Drop',
//       description: "We'll pick up from the airport while you comfy on your ride, mus nellentesque habitant.",
//     },
//     {
//       iconClass: 'flaticon-car',
//       title: 'Parking Space',
//       description: 'Fusce tincidunt nis ace park norttito sit amet space, mus nellentesque habitant.',
//     },
//     {
//       iconClass: 'flaticon-bed',
//       title: 'Room Service',
//       description: 'Room tincidunt nis ace park norttito sit amet space, mus nellentesque habitant.',
//     },
//     {
//       iconClass: 'flaticon-swimming',
//       title: 'Swimming Pool',
//       description: 'Swimming pool tincidunt nise ace park norttito sit space, mus nellentesque habitant.',
//     },
//     {
//       iconClass: 'flaticon-wifi',
//       title: 'Fibre Internet',
//       description: 'Wifi tincidunt nis ace park norttito sit amet space, mus nellentesque habitant.',
//     },
//     {
//       iconClass: 'flaticon-breakfast',
//       title: 'Breakfast',
//       description: 'Eat tincidunt nisa ace park norttito sit amet space, mus nellentesque habitant.',
//     },
//   ];

//   return (
//     <section className="facilities section-padding">
//       <div className="container">
//         <div className="row">
//           <div className="col-md-12">
//             <div className="section-subtitle">Our Services</div>
//             <div className="section-title">Hotel Facilities</div>
//           </div>
//         </div>
//         <div className="row">
//           {facilitiesData.map((facility, index) => (
//             <div key={index} className="col-md-4">
//               <div className="single-facility animate-box" data-animate-effect="fadeInUp">
//                 <span className={facility.iconClass}></span>
//                 <h5>{facility.title}</h5>
//                 <p>{facility.description}</p>
//                 <div className="facility-shape">
//                   <span className={facility.iconClass}></span>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };


// export default HotelFacilitiesSection;



import React from 'react';
import useFacilitiesData from './useFacilitiesData';
import LottieSpinner from "../../../common/lottieSpinner/LottieSpinner"
const HotelFacilitiesSection = () => {
  const { facilitiesData, isLoading } = useFacilitiesData();

  if (isLoading) {
    // You can show a loading spinner or skeleton here while data is being fetched
    return <div>Loading...<LottieSpinner/></div>;
  }

  return (
    <section className="facilties section-padding">
      <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="section-subtitle">Our Services</div>
        <div className="section-title">Hotel Facilities</div>
      </div>
      </div>
      <div className="row">
        {facilitiesData.map((facility, index) => (
          <div key={index} className="col-md-4">
            <div className="single-facility animate-box" data-animate-effect="fadeInUp">
              <img src={facility.image} alt={facility.title} />
              <h5>{facility.title}</h5>
              <p>{facility.description}</p>
              {/* <div className="facility-shape">
                <img src={facility.image} alt={facility.title} />
              </div> */}
            </div>
          </div>
        ))}
      </div>
     </div>
    </section>
  );
};

export default HotelFacilitiesSection;


