import React from "react";
import PaymentProcess from "./PaymentProcess";
import Header from "../../../common/header/Header";
import BannerHeader from "../bannerHeader/BannerHeader";
import Footer from "../../../common/footer/Footer";

const FinalPaymentPage = () => {
  return (
    <div>

      <Header />
      <BannerHeader />
      <div className="container">
        <div className="row">
      <PaymentProcess />
      </div>
      </div>
      <Footer/>
    </div>
  );
};

export default FinalPaymentPage;
