// import React from 'react';
// import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';

// const MapComponent = withScriptjs(withGoogleMap(({ latitude, longitude }) => (
//   <GoogleMap
//     defaultZoom={14}
//     defaultCenter={{ lat: latitude, lng: longitude }}
//   >
//     <Marker position={{ lat: latitude, lng: longitude }} />
//   </GoogleMap>
// )));

// const Map = ({ latitude, longitude }) => {
//   return (
//     <MapComponent
//       latitude={latitude}
//       longitude={longitude}
//       googleMapURL={`https://maps.googleapis.com/maps/api/js?key=YOUR_GOOGLE_MAPS_API_KEY`}
//       loadingElement={<div style={{ height: '100%' }} />}
//       containerElement={<div style={{ height: '400px' }} />}
//       mapElement={<div style={{ height: '100%' }} />}
//     />
//   );
// };

// export default Map;


// import React from 'react';

// function Map() {
//   return (
//     <div className="row">
 
//       <div className="col-md-12 map animate-box" >
//       <iframe
//         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9849.715286569706!2d-1.17390335!3d51.889638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876dd9da90e0cbb%3A0x9171cd4c6f0c89a4!2sBicester%20OX26%201AJ%2C%20UK!5e0!3m2!1sen!2sin!4v1694075555090!5m2!1sen!2sin"
//         width="600"
//         height="450"
//         style={{ border: '0' }}
//         allowFullScreen=""
//         loading="lazy"
//         referrerPolicy="no-referrer-when-downgrade"
//       ></iframe>
//       </div>
//     </div>
//   );
// }

// export default Map;


// import React from 'react';
//   const Map = () => {
   
//   return (
//     <div>
//       <iframe
//         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9849.715286569706!2d-1.17390335!3d51.889638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876dd9da90e0cbb%3A0x9171cd4c6f0c89a4!2sBicester%20OX26%201AJ%2C%20UK!5e0!3m2!1sen!2sin!4v1694075555090!5m2!1sen!2sin"
//         width="1100"
//         height="450"
//         style={{ border: '0' }}
//         allowFullScreen=""
//         loading="lazy"
//         referrerPolicy="no-referrer-when-downgrade"
//       ></iframe>
//     </div>
//   );
// };

// export default Map;

import React, { useRef, useEffect } from 'react';

function Map({ iframe }) {
  console.log('iframe prop:', iframe);

  const iframeRef = useRef(null);

  useEffect(() => {
    if (iframe && iframeRef.current) {
      iframeRef.current.innerHTML = ''; // Clear any previous content
      const div = document.createElement('div');
      div.innerHTML = iframe;
      const iframeElement = div.querySelector('iframe');

      if (iframeElement) {
        iframeRef.current.appendChild(iframeElement);
      }
    }
  }, [iframe]);

  return (
    <div className="row">
      <div className="col-md-12 map animate-box">
        <div ref={iframeRef}></div>
      </div>
    </div>
  );
}

export default Map;

// import React, { useRef, useEffect } from 'react';

// function Map({ iframe }) {
//   console.log('iframe prop:', iframe);

//   const iframeRef = useRef(null);

//   useEffect(() => {
//     if (iframe && iframeRef.current) {
//       iframeRef.current.innerHTML = ''; // Clear any previous content
//       const div = document.createElement('div');
//       div.innerHTML = iframe;

//       const iframeElement = div.querySelector('iframe');

//       if (iframeElement) {
//         // Modify the iframe URL to include the &output=embed query parameter
//         const originalSrc = iframeElement.getAttribute('src');
//         const modifiedSrc = originalSrc.includes('?')
//           ? `${originalSrc}&output=embed`
//           : `${originalSrc}?output=embed`;

//         iframeElement.setAttribute('src', modifiedSrc);

//         iframeRef.current.appendChild(iframeElement);
//       }
//     }
//   }, [iframe]);

//   return (
//     <div className="row">
//       <div className="col-md-12 map animate-box">
//         <div ref={iframeRef}></div>
//       </div>
//     </div>
//   );
// }

// export default Map;
