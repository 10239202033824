import React from 'react';
import PhoneCallButton from './PhoneCallButton/PhoneCallButton'; // Update the path to the actual location of PhoneCallButton component

const WebsiteLayout = ({ children }) => {
  return (
    <div>
      <div style={{ minHeight: '100vh', paddingBottom: '0px' }}>
        {/* Content goes here */}
        {children}
      </div>
      <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 999 }}>
        <PhoneCallButton />
      </div>
    </div>
  );
};

export default WebsiteLayout;
