import React from 'react';
import ImgSlider1 from "../../../../assets/img/slider/1.jpg"

const Header = () => {
  const backgroundImageUrl = ImgSlider1; // Replace this with the correct image path

  const headerStyle = {
    background: `url(${backgroundImageUrl})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div className="banner-header section-padding valign bg-img bg-fixed" data-overlay-dark="4" style={headerStyle}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 caption mt-90">
            <h5>Luxury Hotel</h5>
            <h1>About Us</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
