import React from 'react';
import IMG1 from "../../../../assets/img/slider/1.jpg"

const RoomBannerHeader = () => {
    const backgroundImageStyle = {
      backgroundImage: `url(${IMG1})`,
    }
  
    return (
      <div
        className="banner-header section-padding valign bg-img bg-fixed"
        data-overlay-dark="4"
        style={backgroundImageStyle}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 caption mt-90">
              <h5>Luxury Hotel</h5>
              <h1>Rooms & Suites</h1>
            </div>
          </div>
        </div>
      </div>
  );
};

export default RoomBannerHeader;
