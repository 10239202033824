// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Header from "../../../common/header/Header";
// import Footer from "../../../common/footer/Footer";
// import PropertyAvailability from "./PropertyAvailability";
// import PropertyAmennties from "./PropertyAmennties";
// import FeaturesScroll from "./FeaturesScroll";
// import PropertyBannerHeader from "../propertyheader/PropertyBannerHeader";
// import RoomData from "./RoomData";
// import PropertyRoomImages from "./PropertyRoomImages";
// import PropertyCalender from "./PropertyCalender";
// import { useLocation } from "react-router-dom";
// import Map from "./Map";
// import ViewReview from "../../review/ViewReview";
// import VideoPlayer from "./VideoPlayer";



// const PropertyContentDetailsPage = () => {
//   const location = useLocation();
//   const { hotel_id: property_id } = location.state || {};
//   const [propertyData, setPropertyData] = useState(null);
//   const [calander, setCalander] = useState(null);

//   console.log("propertyid from the contentpage " + property_id);
  
//   const dates = '20201010'

//   useEffect(() => {
//     if (property_id) {
//       axios
//         .post("https://backend.lluxe.com/api/get_property", {
//           property_id: property_id,
//           dates : dates
          
//         })
//         .then((response) => {
//           setPropertyData(response.data.data);
//           setCalander(response.data.calander);
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error);
//         });
//     }
//   }, [property_id , dates]);
//   const containerStyle = {
//     display: "flex",
//     justifyContent: "space-between",
//   };
 

     
//   return (
//     <>
//       <Header />
//       <PropertyBannerHeader propertyData={propertyData} />
//       <PropertyRoomImages
//         secondary_images={propertyData && propertyData.secondary_images}
//         primary_images={propertyData && propertyData.primary_images}
//       />
//       <section className="room-data">
//         <div className="container">
//           <div className="row">
//             <div className="col-sm-7">
//               <RoomData
//                 additionalInformation={
//                   propertyData && propertyData.addtional_information
//                 }
//                 bathroom={propertyData && propertyData.bathroom}
//                 guestCapacity={propertyData && propertyData.guest_capacity}
//                 name={propertyData && propertyData.name}
//                 description={propertyData && propertyData.description}
//               />

//               <FeaturesScroll
//                 room_detail={propertyData && propertyData.room_detail}
//               />

//               <PropertyAmennties
//                 amenities={propertyData && propertyData.amenities}
//               />
//               <hr />


//               <PropertyCalender
//                 calander={calander} 
//               />
//             </div>

//             <div className="col-sm-5">
//               <PropertyAvailability propertyId={property_id} />
//             </div>
//           </div>
//           <hr />
                
//           {/* <Map parseGoogleMapsUrl={parseGoogleMapsUrl} /> */}
//           {/* {propertyData && (
//             <Map parseGoogleMapsUrl={parseGoogleMapsUrl} iframeCode={propertyData.google_maps_url} />
//           )} */}

//           {/* {propertyData && (
//             <div
//               dangerouslySetInnerHTML={{ __html: propertyData.google_maps_url }}
//             />
//           )} */}

//           {/* <Map googleMapsUrl={googleMapsUrl} /> */}
//           <Map iframe={propertyData && propertyData.iframe} />
//         </div>
//       </section>
//       <Footer />
//     </>
//   );
// };

// export default PropertyContentDetailsPage;






// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Header from "../../../common/header/Header";
// import Footer from "../../../common/footer/Footer";
// import PropertyAvailability from "./PropertyAvailability";
// import PropertyAmennties from "./PropertyAmennties";
// import FeaturesScroll from "./FeaturesScroll";
// import PropertyBannerHeader from "../propertyheader/PropertyBannerHeader";
// import RoomData from "./RoomData";
// import PropertyRoomImages from "./PropertyRoomImages";
// import PropertyCalender from "./PropertyCalender";
// import { useLocation } from "react-router-dom";
// import Map from "./Map";
// import ViewReview from "../../review/ViewReview";
// import VideoPlayer from "./VideoPlayer";



// const PropertyContentDetailsPage = () => {
//   const location = useLocation();
//   const { hotel_id: property_id } = location.state || {};
//   const [propertyData, setPropertyData] = useState(null);
//   const [calander, setCalander] = useState(null);

//   console.log("propertyid from the contentpage " + property_id);
  
  

//   useEffect(() => {
//     if (property_id) {
//       axios
//         .post("https://backend.lluxe.com/api/get_property", {
//           property_id: property_id,
          
          
//         })
//         .then((response) => {
//           setPropertyData(response.data.data);
//           setCalander(response.data.calander);
//         })
//         .catch((error) => {
//           console.error("Error fetching data:", error);
//         });
//     }
//   }, [property_id ]);
  
//   const containerStyle = {
//     display: "flex",
//     justifyContent: "space-between",
//   };
 

     
//   return (
//     <>
//       <Header />
//       <PropertyBannerHeader propertyData={propertyData} />
//       <PropertyRoomImages
//         secondary_images={propertyData && propertyData.secondary_images}
//         primary_images={propertyData && propertyData.primary_images}
//       />
//       <section className="room-data">
//         <div className="container">
//           <div className="row">
//             <div className="col-sm-7">
//               <RoomData
//                 additionalInformation={
//                   propertyData && propertyData.addtional_information
//                 }
//                 bathroom={propertyData && propertyData.bathroom}
//                 guestCapacity={propertyData && propertyData.guest_capacity}
//                 name={propertyData && propertyData.name}
//                 description={propertyData && propertyData.description}
//               />

//               <FeaturesScroll
//                 room_detail={propertyData && propertyData.room_detail}
//               />

//               <PropertyAmennties
//                 amenities={propertyData && propertyData.amenities}
//               />
//               <hr />


//               <PropertyCalender
//                 calander={calander} 
//               />
//             </div>

//             <div className="col-sm-5">
//               <PropertyAvailability propertyId={property_id}   calander={calander} />
//             </div>
//           </div>
//           <hr />
                
//           {/* {propertyData && (
//             <Map parseGoogleMapsUrl={parseGoogleMapsUrl} iframeCode={propertyData.google_maps_url} />
//           )} */}

//           {/* {propertyData && (
//             <div
//               dangerouslySetInnerHTML={{ __html: propertyData.google_maps_url }}
//             />
//           )} */}

//           <Map iframe={propertyData && propertyData.iframe} />
//         </div>
//       </section>
//       <Footer />
//     </>
//   );
// };

// export default PropertyContentDetailsPage;













// updated lluex 22/09



import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../../common/header/Header";
import Footer from "../../../common/footer/Footer";
import PropertyAvailability from "./PropertyAvailability";
import PropertyAmennties from "./PropertyAmennties";
import FeaturesScroll from "./FeaturesScroll";
import PropertyBannerHeader from "../propertyheader/PropertyBannerHeader";
import RoomData from "./RoomData";
import PropertyRoomImages from "./PropertyRoomImages";
import PropertyCalender from "./PropertyCalender";
import { useLocation } from "react-router-dom";
import Map from "./Map";
import ViewReview from "../../review/ViewReview";
import VideoPlayer from "./VideoPlayer";



const PropertyContentDetailsPage = () => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
const property_id = search.get('hotel_id');
  // const { hotel_id: property_id } = location.state || {};
  const [propertyData, setPropertyData] = useState(null);
  const [calander, setCalander] = useState(null);

  console.log("propertyid from the contentpage " + property_id);
  
  // const dates = '20201010'

  useEffect(() => {
    if (property_id) {
      axios
        .post("https://backend.lluxe.com/api/get_property", {
          property_id: property_id,
          // dates : dates
          
        })
        .then((response) => {
          setPropertyData(response.data.data);
          setCalander(response.data.calander);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [property_id ]);
  const containerStyle = {
    display: "flex",
    justifyContent: "space-between",
  };
 

     
  return (
    <>
      <Header />
      <PropertyBannerHeader propertyData={propertyData} />
      <PropertyRoomImages
        secondary_images={propertyData && propertyData.secondary_images}
        primary_images={propertyData && propertyData.primary_images}
      />
      <section className="room-data">
        <div className="container">
          <div className="row">
            <div className="col-sm-7">
              <RoomData
                additionalInformation={
                  propertyData && propertyData.addtional_information
                }
                bathroom={propertyData && propertyData.bathroom}
                guestCapacity={propertyData && propertyData.guest_capacity}
                name={propertyData && propertyData.name}
                description={propertyData && propertyData.description}
              />

              <FeaturesScroll
                room_detail={propertyData && propertyData.room_detail}
              />

              <PropertyAmennties
                amenities={propertyData && propertyData.amenities}
              />
              <hr />


              <PropertyCalender
                calander={calander} 
              />
            </div>

            <div className="col-sm-5">
              <PropertyAvailability propertyId={property_id}   calander={calander} />
            </div>
          </div>
          <hr />
                
          {/* {propertyData && (
            <Map parseGoogleMapsUrl={parseGoogleMapsUrl} iframeCode={propertyData.google_maps_url} />
          )} */}

          {/* {propertyData && (
            <div
              dangerouslySetInnerHTML={{ __html: propertyData.google_maps_url }}
            />
          )} */}

          <Map iframe={propertyData && propertyData.iframe} />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PropertyContentDetailsPage;


// updated ended lluex



















// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Header from "../../../common/header/Header";
// import Footer from "../../../common/footer/Footer";
// import PropertyAvailability from "./PropertyAvailability";
// import PropertyAmennties from "./PropertyAmennties";
// import FeaturesScroll from "./FeaturesScroll";
// import PropertyBannerHeader from "../propertyheader/PropertyBannerHeader";
// import RoomData from "./RoomData";
// import PropertyRoomImages from "./PropertyRoomImages";
// import PropertyCalender from "./PropertyCalender";
// import { useLocation } from "react-router-dom";
// import Map from "./Map";
// import ViewReview from "../../review/ViewReview";
// import VideoPlayer from "./VideoPlayer";

// const propertyCalendarUrls = {
//   13: "https://www.airbnb.cn/calendar/ical/45422917.ics?s=1c10788dc01ffa70ab3475ea68c70b03",
//   5: "https://www.airbnb.cn/calendar/ical/42156770.ics?s=9d2445ea08b6927849af7e4fe367f267",
//   14: "https://www.airbnb.cn/calendar/ical/51858153.ics?s=8719bdf175986f85e5984b8f616176f9",
// };

// const parseDatesFromICalendar = (data) => {
//   const lines = data.split("\n");
//   const eventDates = [];

//   for (let i = 0; i < lines.length; i++) {
//     if (lines[i].startsWith("DTSTART")) {
//       // Extract date from DTSTART line
//       const date = lines[i].split(":")[1];
//       eventDates.push(date);
//     }
//   }

//   return eventDates;
// };

// const PropertyContentDetailsPage = () => {
//   const location = useLocation();
//   const { hotel_id: property_id } = location.state || {};
//   const [propertyData, setPropertyData] = useState(null);
//   const [calendarData, setCalendarData] = useState("");
//   const [calander, setCalander] = useState(null);
//   const calendarUrl = propertyCalendarUrls[property_id] || "";

//   useEffect(() => {
//     if (property_id) {
//       // Fetch the iCalendar data from the calendar URL
//       axios
//         .get(calendarUrl)
//         .then((response) => {
//           // Parse the iCalendar data to extract dates
//           const eventDates = parseDatesFromICalendar(response.data);

//           // Set the extracted dates in the state
//           setCalendarData(response.data);
//           setCalander(eventDates);
//           console.log("Dates extracted from the iCalendar URL:", eventDates);

//           // Make an additional request for property data using property_id
//           axios
//             .post("https://backend.lluxe.com/api/get_property", {
//               property_id: property_id,
//               dates: eventDates, // Pass eventDates to the API request if needed
//             })
//             .then((propertyResponse) => {
//               setPropertyData(propertyResponse.data.data);
//             })
//             .catch((error) => {
//               console.error("Error fetching property data:", error);
//             });
//         })
//         .catch((error) => {
//           console.error("Error fetching iCalendar data:", error);
//         });
//     }
//   }, [property_id, calendarUrl]);
//   const containerStyle = {
//     display: "flex",
//     justifyContent: "space-between",
//   };
 

     
//   return (
//     <>
//       <Header />
//       <PropertyBannerHeader propertyData={propertyData} />
//       <PropertyRoomImages
//         secondary_images={propertyData && propertyData.secondary_images}
//         primary_images={propertyData && propertyData.primary_images}
//       />
//       <section className="room-data">
//         <div className="container">
//           <div className="row">
//             <div className="col-sm-7">
//               <RoomData
//                 additionalInformation={
//                   propertyData && propertyData.addtional_information
//                 }
//                 bathroom={propertyData && propertyData.bathroom}
//                 guestCapacity={propertyData && propertyData.guest_capacity}
//                 name={propertyData && propertyData.name}
//                 description={propertyData && propertyData.description}
//               />

//               <FeaturesScroll
//                 room_detail={propertyData && propertyData.room_detail}
//               />

//               <PropertyAmennties
//                 amenities={propertyData && propertyData.amenities}
//               />
//               <hr />


//               <PropertyCalender
//                 calander={calander} 
//               />
//             </div>

//             <div className="col-sm-5">
//               <PropertyAvailability propertyId={property_id} />
//             </div>
//           </div>
//           <hr />
                
         
//           {/* {propertyData && (
//             <Map parseGoogleMapsUrl={parseGoogleMapsUrl} iframeCode={propertyData.google_maps_url} />
//           )} */}

//           {/* {propertyData && (
//             <div
//               dangerouslySetInnerHTML={{ __html: propertyData.google_maps_url }}
//             />
//           )} */}

         
//           <Map iframe={propertyData && propertyData.iframe} />
//         </div>
//       </section>
//       <Footer />
//     </>
//   );
// };

// export default PropertyContentDetailsPage;
