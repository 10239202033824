import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: true,
  });
  
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

  
  const handleSubmit = (event) => {
    event.preventDefault();
    const requestData = {
      email: formData.email,
      password: formData.password,
      remember_me: formData.rememberMe,
    };

    axios
      .post("https://backend.lluxe.com/api/login", requestData)
      .then((response) => {
        console.log("API login Response:", response.data);

        if (response.data.status === false) {
          // Handle unsuccessful login here
          alert("Login failed. Please check your credentials and try again.");
        } else {
          localStorage.setItem("loginStatus", true);

          const user_id = response.data.data.id;
          sessionStorage.setItem("user_id", user_id);
          console.log("User ID stored in sessionStorage:", user_id);

          const loginSuccessful = true;

          if (loginSuccessful) {
            navigate("/");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Login failed. Please check your credentials and try again.");
      });
  };

  const handleForgotPassword = (event) => {
    event.preventDefault();
    setShowForgotPassword(true); // Show the "Forgot Password" form
    navigate("/forgetpassword")
  };

  return (
    <section className="contact section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
          <div className="reg">
            <h3 className="text-center mb-30">Log In</h3>
            <form onSubmit={handleSubmit} className="Login__form">
              <div className="row">
                <div className="col-12">
                  <div
                    className="alert alert-success contact__msg"
                    style={{ display: "none" }}
                    role="alert"
                  >
                    Your message was sent successfully.
                  </div>
                </div>
              </div>

              <div>
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="input-field"
                  required
                />
              </div>
              <div className="mb-25">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="input-field"
                />
              </div>
              <div className="mb-30">
                <input
                  type="checkbox"
                  name="rememberMe"
                  checked={formData.rememberMe}
                  onChange={handleCheckboxChange}
                />
                <span className="checkmark"></span>
                <label className="option">Remember me</label>
              </div>
              <div className="col-md-12">
                <div className="text-center">
                  <button type="submit" className="butn-dark2">
                    <span>Sign in</span>
                  </button>
                </div>
              </div>
              <p className="text-center">
                <a href="#" onClick={handleForgotPassword}>
                  Forgot Password?
                </a>
              </p>
            </form>
          </div></div>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;