import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {API_ROOMDETAILS , API_BOOKNOW} from "../../../../utils/config"

const RoomContentPage = () => {
  const [bookingInProgress, setBookingInProgress] = useState(false);
  const [roomData, setRoomData] = useState(null);
  const [bookNowData, setBookNowData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const { roomId } = location.state;
  const navigate = useNavigate();
  const [mappedRooms, setMappedRooms] = useState([]);
  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        const response = await axios.post(
          API_ROOMDETAILS,
          { room_id: roomId }
        );
        const data = response.data;
        console.log("roomcontentpage roomid" + roomId);
        if (data.status === "true") {
          setRoomData(data.data);
        } else {
          setError("Error fetching room details from the API.");
        }
        setLoading(false);
      } catch (error) {
        setError("Error fetching room details from the API.");
        setLoading(false);
      }
    };

    fetchRoomDetails();
  }, [roomId]);

  const handleBookNowClick = async () => {
    try {
      setBookingInProgress(true);
      const isUserLoggedIn = localStorage.getItem("loginStatus") === "true";

      const response = await axios.post(
        API_BOOKNOW,
        { room_id: roomId }
      );
      navigate("/payment", {
        state: { roomId: roomId, bookNowData: response.data.data },
      });

      console.log("Booking Api response:", response.data);
      console.log("Booking Api response:", response.data.data.name);
      setBookNowData(response.data.data);
      setBookingInProgress(false);
    } catch (error) {
      console.error("Error booking the room:", error);
      setBookingInProgress(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      <section className="rooms-page section-padding" data-scroll-index="1">
        <div className="container">
          {mappedRooms.map((room) => (
            <div key={room.id}>
              <h6>{room.roomType}</h6>
              <p>{room.description}</p>
            </div>
          ))}
          <div className="row">
            <div className="col-md-12">
              <div className="star-rating"></div>
              <span></span>
              <div className="section-subtitle">Luxury Hotel</div>
              <div className="section-title">Suite</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <p className="mb-30">{roomData.description}</p>
              <p className="mb-30">{roomData.checkin}</p>
              <p className="mb-30">{roomData.checkout}</p>
              <div className="row">
                <div className="col-md-6">
                  <h6>{roomData.keyA.insAtitle}</h6>
                  <p>{roomData.keyA.insAdesc}</p>
                </div>
                <div className="col-md-6">
                  <h6>{roomData.keyB.insBtitle}</h6>
                  <p>{roomData.keyB.insBdesc}</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 offset-md-1 faclty">
              <h6>Facilites:</h6>
              <ul>
                {roomData.facilities.map((facility, index) => (
                  <li key={index}>
                    <img
                      src={facility.image}
                      alt={`Image of ${facility.name}`}
                      style={{ maxWidth: "25px", maxHeight: "25px" }}
                    />
                    {facility.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="butn-dark">
              <button
                className="link-btn butn-dark"
                onClick={handleBookNowClick}
                disabled={bookingInProgress}
              >
                {bookingInProgress ? "Booking..." : "Book Now"}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RoomContentPage;
