// // import React from "react";

// // const FeaturesScroll = () => {
// //   return (
// //     <div>
// //       <section className="feature-scroll">
// //         <div className="sleep-bed mb-5 pb-5">
// //           <h3>Where you'll sleep</h3>
// //           <div className="row">
// //             <div className="col-md-12">
// //               <div className="owl-carousel owl-theme">
// //                 <div className="item">
// //                   <i className="fa fa-home"></i>
// //                   <h5>
// //                     <a href="post.html">Bedroom 1</a>
// //                   </h5>
// //                   <p>1 double bed, 2 single beds</p>
// //                 </div>

// //                 <div className="item">
// //                   <i className="fa fa-home"></i>
// //                   <i className="fa fa-home"></i>
// //                   <h5>
// //                     <a href="post.html">Bedroom 2</a>
// //                   </h5>
// //                   <p>1 double bed, 2 single beds</p>
// //                 </div>

// //                 <div className="item">
// //                   <i className="fa fa-home"></i>
// //                   <i className="fa fa-home"></i>
// //                   <i className="fa fa-home"></i>
// //                   <h5>
// //                     <a href="post.html">Bedroom 3</a>
// //                   </h5>
// //                   <p>1 double bed, 2 single beds</p>
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </section>
// //     </div>
// //   );
// // };

// // export default FeaturesScroll;


// import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const FeaturesScroll = () => {
//   const settings = {
//     dots:false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1
//   };

//   const slideStyles = {
//     marginRight: "120px"
//   };
//   return (
//     <section className="feature-scroll">
//       <div className="sleep-bed mb-5 pb-5">
//         <h3>Where you'll sleep</h3>
//         <Slider {...settings}>
//           <div className="item" style={slideStyles}>
//             <i className="fa fa-home"></i>
//             <h5>
//               {/* <a href="post.html">Bedroom 1</a> */}
//               <span>Bedroom 1</span>
//             </h5>
//             <p>1 double bed, 2 single beds</p>
//           </div>

//           <div className="item" style={slideStyles}>
//             <i className="fa fa-home"></i>
//             <i className="fa fa-home"></i>
//             <h5>
//               {/* <a href="post.html">Bedroom 2</a>
//                */}
//                <span>Bedroom 2</span>
//             </h5>
//             <p>1 double bed, 2 single beds</p>
//           </div>

//           <div className="item" style={slideStyles}>
//             <i className="fa fa-home"></i>
//             <i className="fa fa-home"></i>
//             <i className="fa fa-home"></i>
//             <h5>
//             <span>Bedroom 3</span>
//             </h5>
//             <p>2 double bed, 2 single beds</p>
//           </div>
//           <div className="item" style={slideStyles}>
//             <i className="fa fa-home"></i>
//             <i className="fa fa-home"></i>
//             <i className="fa fa-home"></i>
//             <i className="fa fa-home"></i>
//             <h5>
//             <span>Bedroom 4</span>
//             </h5>
//             <p>2 double bed, 3 single beds</p>
//           </div>
//         </Slider>
//       </div>
//     </section>
//   );
// };

// export default FeaturesScroll;


import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FeaturesScroll = ({ room_detail }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const slideStyles = {
    marginRight: "120px",
  };

  return (
    <section className="feature-scroll">
      <div className="sleep-bed mb-5 pb-5">
        <h3>Where you'll sleep</h3>
        <Slider {...settings}>
          {room_detail &&
            room_detail.map((room, index) => (
              <div className="item" style={slideStyles} key={index}>
                <i className="fa fa-home"></i>
                <h5>
                  <span>Bedroom {index + 1}</span>
                </h5>
                <p>{room}</p>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default FeaturesScroll;
