// import React from "react";

// const RoomData = () => {
//   return (
//     <div>
//       <div className="top-head">
//         <div className="row">
//           <div className="col-9">
//             <h2 className="mb-0">Entire home hosted by Shan</h2>
//             <p>
//               <span>16+ guests6</span> . <spna>bedrooms12</spna> .{" "}
//               <spna>beds3</spna> . <spna>5 bathrooms</spna>
//             </p>
//           </div>
//           <div className="col-3 text-center">
//             <img
//               src="https://a0.muscache.com/im/pictures/user/45be6f3c-4378-427e-9da9-ca2c523031a8.jpg"
//               className="rounded-circle"
//               width="50"
//               alt="img"
//             />
//           </div>
//         </div>
//       </div>

//       <div className="facilty-2 my-5 pb-4">
//         <ul>
//           <li>
//             <i className="fa fa-send"></i>
//             <h4 className="mb-0">Self check-in</h4>
//             <p>Check yourself in with the keypad.</p>
//           </li>

//           <li>
//             <i className="fa fa-send"></i>{" "}
//             <h4 className="mb-0">Great check-in experience</h4>
//             <p>
//               90% of recent guests gave the check-in process a 5-star rating.
//             </p>
//           </li>

//           <li>
//             <i className="fa fa-send"></i>{" "}
//             <h4 className="mb-0">Park for free</h4>
//             <p>This is one of the few places in the area with free parking.</p>
//           </li>
//         </ul>
//         <p>
//           This house Ideally for weddings, business groups, and traveling
//           groups. also, we have other properties in the same location to support
//           more groups' stay. 10 mins walking distance to Bicester outlet, The
//           supermarket and pizza are opposite the house. M&S, Boots, and
//           restaurants are walking in 3mins. 20 mins driving to Oxford and
//           Blenheim Palace. 15mins walking from the train station to this
//           property.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default RoomData;


import React from "react";

const RoomData = ({ additionalInformation, bathroom, guestCapacity, description, name }) => {
  return (
    <div>
      <div className="top-head">
        <div className="row">
          <div className="col-9">
            <h2 className="mb-0">{name}</h2>
            <p>
              <span>{guestCapacity} guests</span> .{" "}
              {additionalInformation && (
                <span>{additionalInformation.length} bedrooms</span>
              )}  . <span>{bathroom} bathrooms</span>
            </p>
          </div>
          {/* <div className="col-3 text-center">
            <img
              src="https://a0.muscache.com/im/pictures/user/45be6f3c-4378-427e-9da9-ca2c523031a8.jpg"
              className="rounded-circle"
              width="50"
              alt="img"
            />
          </div> */}
        </div>
      </div>

      <div className="facilty-2 my-5 pb-4">
        <ul>
          {additionalInformation &&
            additionalInformation.map((info, index) => (
              <li key={index}>
                <i className="fa fa-building-o"></i>
                <h4 className="mb-0">{info}</h4>
                <p>{/* Description of the information */}</p>
              </li>
            ))}
        </ul>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default RoomData;

