// import React, { useState } from 'react';

// const ReviewSection = () => {
//   const [reviews, setReviews] = useState([]);

//   const handleAddReview = (index) => {

//   };

//   return (
//     <section className="chs section-padding">
//       <div className="container">
//         <div className="htlfndr-user-panel col-md-12 col-sm-12 htlfndr-booking-page ui-tabs-panel ui-widget-content ui-corner-bottom">
//           <table className="table">
//             <thead>
//               <tr>
//                 <th>Booking ID</th>
//                 <th>Property Name</th>
//                 <th>Checkin-Checkout</th>
//                 <th>Total Guest</th>
//                 <th>Amount</th>
//                 <th>Reviews</th>
//               </tr>
//             </thead>
//             <tbody>
//               {reviews.map((review, index) => (
//                 <tr key={index}>
//                   <td className="htlfndr-scope-row">{review.bookingId}</td>
//                   <td data-title="Location">{review.propertyName}</td>
//                   <td data-title="Data of your stay">{review.checkinCheckout}</td>
//                   <td data-title="Order date">{review.totalGuest}</td>
//                   <td data-title="Cost">{review.amount}</td>
//                   <td data-title="Cost">
//                     <button onClick={() => handleAddReview(index)}>Add Review</button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ReviewSection;

// import React, { useState } from "react";
// import ReviewForm from "./ReviewForm";
// import ModalComponent from "../ModalComponent";

// const ReviewSection = () => {
//   const [isPopupVisible, setIsPopupVisible] = useState(false);
//   const [reviews, setReviews] = useState([]);

//   const handleAddReview = (index) => {};
//   const togglePopup = () => {
//     setIsPopupVisible(!isPopupVisible);
//   };

//   return (
//     <section className="chs section-padding">
//       <div className="container">
//         <div className="htlfndr-user-panel col-md-12 col-sm-12 htlfndr-booking-page ui-tabs-panel ui-widget-content ui-corner-bottom">
//           <table className="table">
//             <thead>
//               <tr>
//                 <th>Booking ID</th>
//                 <th>Property Name</th>
//                 <th>Checkin-Checkout</th>
//                 <th>Total Guest</th>
//                 <th>Amount</th>
//                 <th>Reviews</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td className="htlfndr-scope-row">372357458</td>
//                 <td data-title="Location">San Fancisco</td>
//                 <td data-title="Data of your stay">01/05/2014 - 01/15/2014</td>
//                 <td data-title="Order date">01</td>
//                 <td data-title="Cost">$1280</td>
//                 <td data-title="Cost">
//                   <div>
//                     <button
//                       style={{ color: "red", fontWeight: "bold" }}
//                       type="button"
//                       onClick={togglePopup}
//                       className="demo-button"
//                       data-toggle="modal"
//                       data-target="#contactModal"
//                     >
//                       Add Review
//                     </button>
//                     {isPopupVisible && <ModalComponent togglePopup={togglePopup} />}
//                   </div>
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ReviewSection;
// import React, { useState } from "react";
// import ReviewForm from "./ReviewForm";
// import ModalComponent from "./ModalComponent";

// const ReviewSection = () => {
//   const [isPopupVisible, setIsPopupVisible] = useState(false);
//   const [reviews, setReviews] = useState([]);

//   const handleAddReview = (newReview) => {
//     setReviews([...reviews, newReview]);
//     togglePopup();
//   };

//   const togglePopup = () => {
//     setIsPopupVisible(isPopupVisible);
//   };

//   return (
//     <section className="chs section-padding">
//       <div className="container">
//         <div className="htlfndr-user-panel col-md-12 col-sm-12 htlfndr-booking-page ui-tabs-panel ui-widget-content ui-corner-bottom">
//           <table className="table">
//             <thead>
//             <tr>
//                 <th>Booking ID</th>
//                 <th>Property Name</th>
//                 <th>Checkin-Checkout</th>
//                 <th>Total Guest</th>
//                 <th>Amount</th>
//                 <th>Reviews</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//               <td className="htlfndr-scope-row">372357458</td>
//                 <td data-title="Location">San Fancisco</td>
//                 <td data-title="Data of your stay">01/05/2014 - 01/15/2014</td>
//                 <td data-title="Order date">01</td>
//                 <td data-title="Cost">$1280</td>
//                 <td data-title="Cost"></td>
//                 <td>
                 
//                     <button
//                       style={{ color: "red", fontWeight: "bold" }}
//                       type="button"
//                       onClick={togglePopup}
//                       // onClick={()=>alert('CLICKED')}
//                       className="demo-button"
//                     >
//                       Add Review
//                     </button>
                  
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </div>
//       </div>

//       {/* Render the modal at the top level */}
//       {isPopupVisible && (
//         <ModalComponent
//           togglePopup={togglePopup}
//           handleAddReview={handleAddReview}
//         />
//       )}
//     </section>
//   );
// };

// export default ReviewSection;

// import React, { useState } from "react";
// import { useLocation } from "react-router-dom";
// import ModalComponent from "./ModalComponent";

// const ReviewSection = () => {
//   const location = useLocation();
//   const userId = location.state.userId;
//   const [isPopupVisible, setIsPopupVisible] = useState(false);
//   const [reviews, setReviews] = useState([]);

//   const handleAddReview = (newReview) => {
//     setReviews([...reviews, newReview]);
//     togglePopup();
//   };

//   console.log(userId)
//   const togglePopup = () => {
//     setIsPopupVisible(!isPopupVisible);
//     const modal = document.querySelector(".modal-contact-popup-one");
//   };

//   return (
//     <section className="chs section-padding">
//       <div className="container">
//         <div className="htlfndr-user-panel col-md-12 col-sm-12 htlfndr-booking-page ui-tabs-panel ui-widget-content ui-corner-bottom">
//           <table className="table">
//             <thead>
//               <tr>
//                 <th>Booking ID</th>
//                 <th>Property Name</th>
//                 <th>Checkin-Checkout</th>
//                 <th>Total Guest</th>
//                 <th>Amount</th>
//                 <th>Reviews</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td className="htlfndr-scope-row">372357458</td>
//                 <td data-title="Location">San Fancisco</td>
//                 <td data-title="Data of your stay">01/05/2014 - 01/15/2014</td>
//                 <td data-title="Order date">01</td>
//                 <td data-title="Cost">$1280</td>
//                 <td data-title="Review">  
//                   <ModalComponent  
//                     onClick={togglePopup}

//                   />
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ReviewSection;



// import React, { useState } from 'react';
// import { useLocation } from "react-router-dom";
// import ModalComponent from "./ModalComponent";

// const ReviewSection = () => {
//   const location = useLocation();
//   // const {user_id} = location.state
//   // const userId = location.state.userId;
//   const user_id = sessionStorage.getItem('user_id')
//   const [isPopupVisible, setIsPopupVisible] = useState(false);
//   const [reviews, setReviews] = useState([]);
 
//   console.log( 'Review user id' + user_id)

//   const handleAddReview = (newReview) => {
//     setReviews([...reviews, newReview]);
//     togglePopup();
//   };
//   console.log( 'review id' + sessionStorage.getItem('user_id'))
//   const togglePopup = () => {
//     setIsPopupVisible(!isPopupVisible);
//     const modal = document.querySelector(".modal-contact-popup-one");
//   };

//   return (
//     <section className="chs section-padding">
//       <div className="container">
//         <div className="htlfndr-user-panel col-md-12 col-sm-12 htlfndr-booking-page ui-tabs-panel ui-widget-content ui-corner-bottom">
//           <table className="table">
//             <thead>
//               <tr>
//                 <th>Booking ID</th>
//                 <th>Property Name</th>
//                 <th>Checkin-Checkout</th>
//                 <th>Total Guest</th>
//                 <th>Amount</th>
//                 <th>Reviews</th>
//               </tr>
//             </thead>
//             <tbody>
//               {reviews.map((review, index) => (
//                 <tr key={index}>
//                   <td className="htlfndr-scope-row">{review.bookingId}</td>
//                   <td data-title="Location">{review.propertyName}</td>
//                   <td data-title="Data of your stay">{review.checkinCheckout}</td>
//                   <td data-title="Order date">{review.totalGuest}</td>
//                   <td data-title="Cost">{review.amount}</td>
//                   <td data-title="Review">  
//                   <ModalComponent  
//                     onClick={togglePopup}

//                   />
//                 </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ReviewSection;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios'; // Make sure to install axios using npm or yarn
// import ModalComponent from "./ModalComponent";

// const ReviewSection = () => {
//   const user_id = sessionStorage.getItem('user_id');
//   const [isPopupVisible, setIsPopupVisible] = useState(false);
//   const [bookings, setBookings] = useState([]);


// console.log("userid in reviewsectionpage" + sessionStorage.getItem('user_id'))

//   useEffect(() => {
//     // Fetch data from the API using axios (POST request)
//     axios.post('http://lluxe.sbwares.com/api/get_bookings', { user_id })
//       .then(response => {
//         if (response.data && response.data.status === 'true') {
//           setBookings(response.data.data);
//         }
//       })
//       .catch(error => {
//         console.error('Error fetching data:', error);
//       });
//   }, [user_id]);

//   const togglePopup = () => {
//     setIsPopupVisible(!isPopupVisible);
//   };

//   return (
//     <section className="chs section-padding">
//       <div className="container">
//         <div className="htlfndr-user-panel col-md-12 col-sm-12 htlfndr-booking-page ui-tabs-panel ui-widget-content ui-corner-bottom">
//           <table className="table">
//             <thead>
//               <tr>
//                 <th>Booking ID</th>
//                 <th>Property Name</th>
//                 <th>Checkin-Checkout</th>
//                 <th>Total Guest</th>
//                 <th>Amount</th>
//                 <th>Reviews</th>
//               </tr>
//             </thead>
//             <tbody>
//               {bookings.map((booking, index) => (
//                 <tr key={index}>
//                   <td className="htlfndr-scope-row">{booking.booking_id}</td>
//                   <td data-title="Location">{booking.hotel_id}</td>
//                   <td data-title="Data of your stay">{`${booking.check_in} - ${booking.check_out}`}</td>
//                   <td data-title="Order date">{booking.total_guests}</td>
//                   <td data-title="Cost">{booking.amount}</td>
//                   <td data-title="Review">  
//                     <ModalComponent
//                       onClick={togglePopup}
//                     />
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ReviewSection;

import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Make sure to install axios using npm or yarn
import ModalComponent from "./ModalComponent";

const ReviewSection = () => {
  const user_id = sessionStorage.getItem('user_id');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [bookings, setBookings] = useState([]);


console.log("userid in reviewsectionpage" + sessionStorage.getItem('user_id'))

  useEffect(() => {
    // Fetch data from the API using axios (POST request)
    axios.post('http://lluxe.sbwares.com/api/get_bookings', { user_id })
      .then(response => {
        if (response.data && response.data.status === 'true') {
          setBookings(response.data.data);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [user_id]);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <section className="chs section-padding">
      <div className="container">
        <div className="htlfndr-user-panel col-md-12 col-sm-12 htlfndr-booking-page ui-tabs-panel ui-widget-content ui-corner-bottom">
          <table className="table">
            <thead>
              <tr>
                <th>Booking ID</th>
                <th>Property Name</th>
                <th>Checkin-Checkout</th>
                <th>Total Guest</th>
                <th>Amount</th>
                <th>Reviews</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking, index) => (
                <tr key={index}>
                  <td className="htlfndr-scope-row">{booking.booking_id}</td>
                  <td data-title="Location">{booking.room_id}</td>
                  <td data-title="Data of your stay">{`${booking.check_in} - ${booking.check_out}`}</td>
                  <td data-title="Order date">{booking.total_guests}</td>
                  <td data-title="Cost">{booking.amount}</td>
                  <td data-title="Review">  
                    <ModalComponent
                      onClick={togglePopup}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default ReviewSection;
