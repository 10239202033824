import React, { useState, useEffect } from "react";
import Imglogo from "../../../assets/img/logo.png";
import logoDark from "../../../assets/img/logoDark.png";
import { NavLink, useNavigate } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"; // Import ScrollLink and animateScroll

const userId = localStorage.getItem("user_id");

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [navScroll, setNavScroll] = useState(false);
  const username = localStorage.getItem("loginStatus") === "true";
  console.log(username);
  const isUserLoggedIn = localStorage.getItem("loginStatus") === "true";
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    const shouldLogout = window.confirm("Are you sure you want to logout?");
    if (shouldLogout) {
      localStorage.setItem("loginStatus", false);
      navigate("/");
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setNavScroll(true);
    } else {
      setNavScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  console.log(userId);

  return (
    <nav className={`navbar navbar-expand-lg ${navScroll ? "nav-scroll" : ""}`}>
      <div className="container">
        <div className="logo-wrapper">
          <NavLink to="/" className="logo">
          <img
              src={navScroll ? logoDark : Imglogo}
              className="logo-img"
              alt=""
            />
          </NavLink>
        </div>

        <button
          className={`navbar-toggler ${isMenuOpen ? "active" : ""}`}
          onClick={toggleMenu}
        >
          <span className="navbar-toggler-icon">
            <i className="ti-menu"></i>
          </span>
        </button>

        <div
          className={`navbar-collapse collapse ${isMenuOpen ? "show" : ""}`}
          id="navbar"
        >
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                className="nav-link"
                activeClassName="active"
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/about"
                className="nav-link"
                activeClassName="active"
              >
                About
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                to="/properties"
                className="nav-link"
                activeClassName="active"
              >
                Properties
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                to="/contact"
                className="nav-link"
                activeClassName="active"
              >
                Contact
              </NavLink>
            </li>
            {!username && (
              <li className="nav-item">
                <NavLink
                  to="/registration"
                  className="nav-link"
                  activeClassName="active"
                >
                  Registrations
                </NavLink>
              </li>
            )}

            {!username && (
              <li className="nav-item">
                <NavLink
                  to="/login"
                  className="nav-link"
                  activeClassName="active"
                >
                  Login
                </NavLink>
              </li>
            )}

            {isUserLoggedIn ? (
              <li className="nav-item">
                <button className="nav-link" onClick={handleLogout}>
                  Logout
                </button>
              </li>
            ) : null}

            {isUserLoggedIn ? (
              <li className="nav-item">
                <NavLink
                  to="/review&history"
                  className="nav-link"
                  activeClassName="active"
                >
                  Booking History
                </NavLink>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;