import axios from "axios";

const BASE_API_URL = "https://backend.lluxe.com/api";

async function makePayment(booking_id, amount, id, paymentMethod) {
  try {
    const response = await axios.post(`${BASE_API_URL}/stripe_method`, {
      booking_id,
      amount,
      id,
      payment_method: paymentMethod,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export { makePayment };
