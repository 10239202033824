import React from 'react'
import { useLocation } from 'react-router-dom';
import IMG from "../../../../src/assets/img/thanks.jpg"

const ThanksImg = () => {
	const location = useLocation();
  return (
    <>
<div className="pt-50 pb-80 thank">
		<div className="container my-5 py-5">
			<div className="row align-items-center">
			<div className="col-lg-2 p-0"></div>
				<div className="col-lg-8 p-0">
					<div className="single_contact_rt_thumb">
						<img src={IMG} alt="thank you" />
						<h3 className="text-center">We'll connect you soon...</h3>
						<p className="text-center"> {location.state && location.state.success}</p>
						{/* <div className="button two text-center">
								<a href="https://nvq.sbwares.com">Go to Home</a>
							</div> */}
						
					</div>
				</div>
				
			</div>
		</div>
	</div>
    </>
  )
}

export default ThanksImg