

// import React, { useState } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import ModalCouponCode from "../../booking/sidebookdetail/ModalCouponCode";

// const PropertyAvailability = ({ propertyId }) => {
//   const navigate = useNavigate();
//   const [validationErrors, setValidationErrors] = useState({});
//   const property_id = propertyId;
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     phone: "",
//     check_in: "",
//     check_out: "",
//     guest: "",
//     payment_type: "",
//     coupon_code: "",
//     property_id: property_id,
//   });

//   console.log("propertyid outside the funftion" + property_id);

//   const handleCouponCodeChange = (event) => {
//     const { value } = event.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       coupon_code: value,
//     }));
//   };

//   const handleCouponSelected = (selectedCode) => {
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       coupon_code: selectedCode,
//     }));
//   };

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     if (
//       (name === "total_guests" || name === "children" || name === "adults") &&
//       parseInt(value) < 0
//     ) {
//       return;
//     }

//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));
//   };

//   const isHalfPaymentDisabled = () => {
//     const currentDate = new Date();
//     const checkInDate = formData.check_in;

//     if (!checkInDate) {
//       return true;
//     }

//     const timeDifference = checkInDate.getTime() - currentDate.getTime();
//     const daysDifference = timeDifference / (1000 * 3600 * 24);

//     return daysDifference < 29;
//   };

//   const handleDateChange = (fieldName, date) => {
//     if (
//       fieldName === "check_out" &&
//       formData.check_in &&
//       date >= formData.check_in
//     ) {
//       setFormData((prevFormData) => ({ ...prevFormData, [fieldName]: date }));
//     } else if (fieldName === "check_in" && date >= new Date()) {
//       setFormData((prevFormData) => ({ ...prevFormData, [fieldName]: date }));
//       setFormData((prevFormData) => ({ ...prevFormData, check_out: "" }));
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();

//     // Make a POST request to the booking API
//     axios
//       .post("https://backend.lluxe.com/api/book_property", formData)
//       .then((response) => {
//         console.log("Booking status", response.data.message);
//         console.log("proeprty form the propavaitlitdfdy " + property_id);

//         if (response.data.status === "true") {
//           const { booking_id, price, discounted_price } = response.data.data;

//           navigate("/finalpayment", {
//             state: { booking_id, price, discounted_price },
//           });
//         } else {
//           if (response.data.status === "false") {
//             const errorData = response.data;
//             setValidationErrors(errorData.errors || {});
//             console.log("errordata " + errorData.message);
//             toast.error(errorData.message);
//           } else {
//             setValidationErrors({});
//           }
//         }
//       })
//       .catch((error) => {
//         // Handle errors here
//         console.error("Error booking property:", error);
//       });
//   };

//   return (
//     <>
//       <div className="avail mb-5">
//         <div className="head-box">
//           <h4>Add dates for prices</h4>
//           <h6>
//             <i className="fa fa-star"></i> 4.80 | 56 Reviews
//           </h6>
//         </div>
//         <div className="booking-inner clearfix">
//           <form className="form2" onSubmit={handleSubmit}>
//             <div className="row">
//               <div className="col-md-12">
//                 <div className="input1_wrapper">
//                   <label>Name</label>
//                   <div className="input2_inner">
//                     <input
//                       type="text"
//                       className="form-control input"
//                       placeholder=""
//                       name="name"
//                       value={formData.name}
//                       onChange={handleInputChange}
//                       required
//                     />
//                   </div>
//                 </div>
//               </div>

//               <input
//                 type="hidden"
//                 name="property_id"
//                 value={formData.property_id}
//               />

//               <div className="col-md-12">
//                 <div className="input1_wrapper">
//                   <label>Email</label>
//                   <div className="input2_inner">
//                     <input
//                       type="text"
//                       className="form-control input"
//                       placeholder=""
//                       name="email"
//                       value={formData.email}
//                       onChange={handleInputChange}
//                       required
//                     />
//                   </div>
//                 </div>
//               </div>
//               <div className="col-md-6">
//                 <div className="input1_wrapper">
//                   <label>Check in</label>
//                   <DatePicker
//                     id="checkInDate"
//                     selected={formData.check_in}
//                     onChange={(date) => handleDateChange("check_in", date)}
//                     className={`form-control ${
//                       formData.check_out ? "" : "react-datepicker__day--faded"
//                     }`}
//                     name="checkInDate"
//                     dateFormat="dd/MM/yyyy"
//                     placeholderText=""
//                     required
//                     aria-label="Check-in date"
//                     minDate={new Date()}
//                   />
//                   {/* <div className="input1_inner">
//                       <input
//                         type="text"
//                         className="form-control input datepicker"
//                         placeholder="Check in"
//                         name="check_in"
//                         value={formData.name}
//                         onChange={handleInputChange}
//                         required
//                       />
//                     </div> */}
//                 </div>
//               </div>
//               <div className="col-md-6">
//                 <div className="input1_wrapper">
//                   <label>Check out</label>
//                   <DatePicker
//                     id="checkOutDate"
//                     selected={formData.check_out}
//                     onChange={(date) => handleDateChange("check_out", date)}
//                     className={`form-control ${
//                       formData.check_in ? "" : "react-datepicker__day--faded"
//                     }`}
//                     name="checkOutDate"
//                     dateFormat="dd/MM/yyyy"
//                     placeholderText=""
//                     required
//                     aria-label="Check-out date"
//                     minDate={formData.check_in || new Date()}
//                   />
//                   {/* <div className="input1_inner">
//                       <input
//                         type="text"
//                         className="form-control input datepicker"
//                         placeholder="Check out"
//                       />
//                     </div> */}
//                 </div>
//               </div>

//               <div className="col-md-4">
//   <div className="select1_wrapper">
//     <label className="form-label">Guests</label>
//     <input
//       type="number"
//       className="form-control"
//       name="guest"
//       placeholder=""
//       aria-label=""
//       value={formData.guest}
//       onInput={(e) => {
//         const value = e.target.value.trim(); // Remove leading/trailing whitespace
//         if (value === '' || (parseInt(value) >= 0 && !isNaN(parseInt(value)))) {
//           // Check if the input is empty or a non-negative number
//           setFormData((prevFormData) => ({
//             ...prevFormData,
//             guest: value === '' ? '' : parseInt(value),
//           }));
//         }
//       }}
//       required
//     />
//   </div>
// </div>

//               {/* <div className="col-md-4">
//                 <div className="select1_wrapper">
//                   <label>Guests</label>
//                   <input
//                     type="number"
//                     className="form-control"
//                     name="guests"
//                     placeholder=""
//                     aria-label=""
//                     value={formData.guests}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </div>
//               </div> */}
//               <div className="col-md-8">
//                 <label className="form-label">Phone Number</label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   name="phone"
//                   placeholder=""
//                   aria-label=""
//                   value={formData.phone}
//                   onChange={handleChange}
//                   required
//                 />
//               </div>

//               <div className="col-md-6">
//                 <div className="input1_wrapper cpn-cde">
//                   <ModalCouponCode onSelectCoupon={handleCouponSelected} />
//                 </div>
//               </div>

//               <div className="col-md-6">
//                 <div className="input1_wrapper">
//                   <input
//                     type="text"
//                     className="form-control"
//                     name="coupon_code"
//                     placeholder=""
//                     aria-label=""
//                     value={formData.coupon_code}
//                     onChange={handleCouponCodeChange}
//                   />
//                 </div>
//               </div>

//               <div className="col-md-12">
//                 <div className="input1_wrapper pay-btn">
//                   <label htmlFor="paymentType" className="form-label">
//                     Payment Type
//                   </label>
//                   <select
//                     id="paymentType"
//                     className="form-select"
//                     name="payment_type"
//                     value={formData.payment_type}
//                     onChange={handleChange}
//                     required
//                   >
//                     <option value="">Select Payment Type</option>
//                     <option value="0" disabled={isHalfPaymentDisabled()}>
//                       Half Payment
//                     </option>
//                     <option value="1">Full Payment</option>
//                   </select>
//                 </div>
//               </div>

//               <div className="col-md-12">
//                 <button type="submit" className="btn-form1-submit mt-15">
//                   Reserve Now
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };

// export default PropertyAvailability;



import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalCouponCode from "../../booking/sidebookdetail/ModalCouponCode";

const PropertyAvailability = ({ propertyId, calander }) => {
  const [disabledDates, setDisabledDates] = useState([]);
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const property_id = propertyId;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    check_in: null,
    check_out: null,
    guest: "",
    payment_type: "",
    coupon_code: "",
    property_id: property_id,
  });

  console.log("propertyid outside the funftion" + property_id);

  useEffect(() => {
    if (calander) {
      const disabledDates = calander.map((dateString) => new Date(dateString));
      setDisabledDates(disabledDates);
    }
  }, [calander]);

  const handleCouponCodeChange = (event) => {
    const { value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      coupon_code: value,
    }));
  };

  const handleCouponSelected = (selectedCode) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      coupon_code: selectedCode,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (
      (name === "total_guests" || name === "children" || name === "adults") &&
      parseInt(value) < 0
    ) {
      return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const isHalfPaymentDisabled = () => {
    const currentDate = new Date();
    const checkInDate = formData.check_in;

    if (!checkInDate) {
      return true;
    }

    const timeDifference = checkInDate.getTime() - currentDate.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    return daysDifference < 29;
  };

  const handleDateChange = (fieldName, date) => {
    if (
      fieldName === "check_out" &&
      formData.check_in &&
      date >= formData.check_in
    ) {
      setFormData((prevFormData) => ({ ...prevFormData, [fieldName]: date }));
    } else if (fieldName === "check_in" && date >= new Date()) {
      setFormData((prevFormData) => ({ ...prevFormData, [fieldName]: date }));
      setFormData((prevFormData) => ({ ...prevFormData, check_out: "" }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Make a POST request to the booking API
    axios
      .post("https://backend.lluxe.com/api/book_property", formData)
      .then((response) => {
        console.log("Booking status", response.data.message);
        console.log("proeprty form the propavaitlitdfdy " + property_id);

        if (response.data.status === "true") {
          const { booking_id, price, discounted_price } = response.data.data;

          navigate("/finalpayment", {
            state: { booking_id, price, discounted_price },
          });
        } else {
          if (response.data.status === "false") {
            const errorData = response.data;
            setValidationErrors(errorData.errors || {});
            console.log("errordata " + errorData.message);
            toast.error(errorData.message);
          } else {
            setValidationErrors({});
          }
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error booking property:", error);
      });
  };

  return (
    <>
      <div className="avail mb-5">
        <div className="head-box">
          <h4>Add dates for prices</h4>
          <h6>
            <i className="fa fa-star"></i> 4.80 | 56 Reviews
          </h6>
        </div>
        <div className="booking-inner clearfix">
          <form className="form2" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="input1_wrapper">
                  <label>Name</label>
                  <div className="input2_inner">
                    <input
                      type="text"
                      className="form-control input"
                      placeholder=""
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <input
                type="hidden"
                name="property_id"
                value={formData.property_id}
              />

              <div className="col-md-12">
                <div className="input1_wrapper">
                  <label>Email</label>
                  <div className="input2_inner">
                    <input
                      type="text"
                      className="form-control input"
                      placeholder=""
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input1_wrapper">
                  <label>Check in</label>
                  <DatePicker
                    id="checkInDate"
                    selected={formData.check_in}
                    onChange={(date) => handleDateChange("check_in", date)}
                    className={`form-control ${
                      formData.check_out ? "" : "react-datepicker__day--faded"
                    }`}
                    name="checkInDate"
                    dateFormat="dd/MM/yyyy"
                    placeholderText=""
                    required
                    aria-label="Check-in date"
                    minDate={new Date()}
                    excludeDates={disabledDates} 
                  />
                  {/* <div className="input1_inner">
                      <input
                        type="text"
                        className="form-control input datepicker"
                        placeholder="Check in"
                        name="check_in"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </div> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="input1_wrapper">
                  <label>Check out</label>
                  <DatePicker
                    id="checkOutDate"
                    selected={formData.check_out}
                    onChange={(date) => handleDateChange("check_out", date)}
                    className={`form-control ${
                      formData.check_in ? "" : "react-datepicker__day--faded"
                    }`}
                    name="checkOutDate"
                    dateFormat="dd/MM/yyyy"
                    placeholderText=""
                    required
                    aria-label="Check-out date"
                    minDate={formData.check_in || new Date()}
                    excludeDates={disabledDates}
                  />
                  {/* <div className="input1_inner">
                      <input
                        type="text"
                        className="form-control input datepicker"
                        placeholder="Check out"
                      />
                    </div> */}
                </div>
              </div>

              <div className="col-md-4">
                <div className="select1_wrapper">
                  <label className="form-label">Guests</label>
                  <input
                    type="number"
                    className="form-control"
                    name="guest"
                    placeholder=""
                    aria-label=""
                    value={formData.guest}
                    onInput={(e) => {
                      const value = e.target.value.trim(); // Remove leading/trailing whitespace
                      if (
                        value === "" ||
                        (parseInt(value) >= 0 && !isNaN(parseInt(value)))
                      ) {
                        // Check if the input is empty or a non-negative number
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          guest: value === "" ? "" : parseInt(value),
                        }));
                      }
                    }}
                    required
                  />
                </div>
              </div>

              {/* <div className="col-md-4">
                <div className="select1_wrapper">
                  <label>Guests</label>
                  <input
                    type="number"
                    className="form-control"
                    name="guests"
                    placeholder=""
                    aria-label=""
                    value={formData.guests}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div> */}
              <div className="col-md-8">
                <label className="form-label">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  placeholder=""
                  aria-label=""
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-md-6">
                <div className="input1_wrapper cpn-cde">
                  <ModalCouponCode onSelectCoupon={handleCouponSelected} />
                </div>
              </div>

              <div className="col-md-6">
                <div className="input1_wrapper">
                  <input
                    type="text"
                    className="form-control"
                    name="coupon_code"
                    placeholder=""
                    aria-label=""
                    value={formData.coupon_code}
                    onChange={handleCouponCodeChange}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="input1_wrapper pay-btn">
                  <label htmlFor="paymentType" className="form-label">
                    Payment Type
                  </label>
                  <select
                    id="paymentType"
                    className="form-select"
                    name="payment_type"
                    value={formData.payment_type}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Payment Type</option>
                    <option value="0" disabled={isHalfPaymentDisabled()}>
                      Half Payment
                    </option>
                    <option value="1">Full Payment</option>
                  </select>
                </div>
              </div>

              <div className="col-md-12">
                <button type="submit" className="btn-form1-submit mt-15">
                  Reserve Now
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PropertyAvailability;
