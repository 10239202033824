// import { CLIENT_ID } from "../../../../../src/utils/config";
// import React, { useState, useEffect } from "react";
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

// const Checkout = ({ onSuccess }) => {
//   const [show, setShow] = useState(false);
//   const [success, setSuccess] = useState(false);
//   const [ErrorMessage, setErrorMessage] = useState("");
//   const [orderID, setOrderID] = useState(false);

//   const createOrder = (data, actions) => {
//     return actions.order
//       .create({
//         purchase_units: [
//           {
//             description: "Property",
//             amount: {
//               currency_code: "USD",
//               value: 20,
//             },
//           },
//         ],
//       })
//       .then((orderID) => {
//         setOrderID(orderID);
//         return orderID;
//       });
//   };

//   const onApprove = (data, actions) => {
//     return actions.order.capture().then(function (details) {
//       const { payer } = details;
//       setSuccess(true);
//       onSuccess(orderID);
//     });
//   };

//   const onError = (data, actions) => {
//     setErrorMessage("An Error occured with your payment ");
//   };

//   useEffect(() => {
//     if (success) {
//       alert("Payment successful!!");
//       console.log("Order successful . Your order id is--", orderID);
//     }
//   }, [success]);

//   return (
//     <PayPalScriptProvider
//       options={{ "client-id": CLIENT_ID, components: "buttons" }}
//     >
//       <div>
//         <div className="wrapper">
//           <div className="product-info">
//             <div className="product-price-btn">
//               <button
//                 className="buy-btn p-3 mb-2 bg-primary text-white rounded d-block mx-auto"
//                 type="submit"
//                 onClick={() => setShow(true)}
//               >
//                 Continue payment with PayPal
//               </button>
//             </div>
//           </div>
//         </div>
//         <br></br>
//         {show ? (
//           <PayPalButtons
//             style={{ layout: "vertical" }}
//             createOrder={createOrder}
//             onApprove={onApprove}
//           />
//         ) : null}
//       </div>
//     </PayPalScriptProvider>
//   );
// };

// export default Checkout;


// import { CLIENT_ID } from "../../../../../src/utils/config";
// import React, { useState, useEffect } from "react";
// import { makePayment } from "../../../../utils/apiutils"; 
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

// const Checkout = ({ onSuccess, booking_id, amount, onPaymentSuccess, paymentMethod }) => {
//   const [show, setShow] = useState(false);
//   const [success, setSuccess] = useState(false);
//   const [ErrorMessage, setErrorMessage] = useState("");
//   const [id, setId] = useState(''); // Renamed orderID to id
//   const [paymentId, setPaymentId] = useState(null);

//   const createOrder = (data, actions) => {
//     return actions.order
//       .create({
//         purchase_units: [
//           {
//             description: "Property",
//             amount: {
//               currency_code: "USD",
//               value: amount,
//             },
//           },
//         ],
//       })
//       .then((orderID) => {
//         console.log("orderID before setId:", orderID);
//         setId(orderID);
//         console.log("orderID after setId:", id);
//         return orderID;
//       });
//   };

//   const onApprove = (data, actions) => {
//     return actions.order.capture().then(function (details) {
//       const { payer } = details;
//       setSuccess(true);

//       // Log the API call and handle the response
//       makePayment(booking_id, amount, id, paymentMethod) // Change orderID to id here
//         .then((response) => {
//           if (response.status === "true") {
//             // Handle API success
//             console.log("API Response:", response);
//             setPaymentId(id); // Set the Payment ID in the state
//             onPaymentSuccess(id); // Pass the id to the callback
//           } else {
//             // Handle API failure
//             console.error("API Response Error:", response.error);
//             setErrorMessage("Payment failed. Please try again.");
//           }
//         })
//         .catch((error) => {
//           console.error("Payment error:", error);
//           setErrorMessage("An error occurred. Please try again later.");
//         });
//     });
//   };

//   const onError = (data, actions) => {
//     setErrorMessage("An Error occurred with your payment");
//   };

//   useEffect(() => {
//     if (success) {
//       alert("Payment successful!!");
//       console.log("Order successful. Your order id is--", id);
//     }
//   }, [success]);

//   return (
//     <PayPalScriptProvider
//       options={{ "client-id": CLIENT_ID, components: "buttons" }}
//     >
//       <div>
//         <div className="wrapper">
//           <div className="product-info">
//             <div className="product-price-btn">
//               <button
//                 className="buy-btn p-3 mb-2 bg-primary text-white rounded d-block mx-auto"
//                 type="submit"
//                 onClick={() => setShow(true)}
//               >
//                 Continue payment with PayPal
//               </button>
//             </div>
//           </div>
//         </div>
//         <br></br>
//         {show ? (
//           <PayPalButtons
//             style={{ layout: "vertical" }}
//             createOrder={createOrder}
//             onApprove={onApprove}
//             onError={onError}
//           />
//         ) : null}
//       </div>
//     </PayPalScriptProvider>
//   );
// };

// export default Checkout;






import { CLIENT_ID } from "../../../../../src/utils/config";
import React, { useState, useEffect } from "react";
import { makePayment } from "../../../../utils/apiutils"; 
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";


const Checkout = ({ onSuccess, booking_id, amount, onPaymentSuccess }) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [paymentId, setPaymentId] = useState(null);
  const paymentMethod = 1;

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Property",
            amount: {
              currency_code: "USD",
              value: amount,
            },
          },
        ],
      })
      .then((orderid) => {
        return orderid; // Directly return orderid
      });
  };
  
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setSuccess(true);
  
      // Log the API call and handle the response
      makePayment(booking_id, amount, details.id, paymentMethod) // Use details.id (orderid)
        .then((response) => {
          if (response.status === "true") {
            // Handle API success
          navigate("/thankyou");
            console.log("API Response:", response);
            setPaymentId(details.id); // Set the Payment ID in the state
            onPaymentSuccess(details.id); // Pass the id to the callback
          } else {
            // Handle API failure
            console.error("API Response Error:", response.error);
            setErrorMessage("Payment failed. Please try again.");
          }
        })
        .catch((error) => {
          console.error("Payment error:", error);
          setErrorMessage("An error occurred. Please try again later.");
        });
    });
  };
  
  const onError = (data, actions) => {
    setErrorMessage("An Error occurred with your payment");
  };

  useEffect(() => {
    if (success) {
      alert("Payment successful!!");
      console.log("Order successful. Your order id is--", paymentId);
    }
  }, [success, paymentId]);

  return (
    <PayPalScriptProvider
      options={{ "client-id": CLIENT_ID, components: "buttons" }}
    >
      <div>
        <div className="wrapper">
          <div className="product-info">
            <div className="product-price-btn">
              <button
                className="buy-btn p-3 mb-2 bg-primary text-white rounded d-block mx-auto"
                type="submit"
                onClick={() => setShow(true)}
              >
                Continue payment with PayPal
              </button>
            </div>
          </div>
        </div>
        <br></br>
        {show ? (
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        ) : null}
      </div>
    </PayPalScriptProvider>
  );
};

export default Checkout;






















