import React from 'react'
import Header from '../../../common/header/Header'
import Footer from '../../../common/footer/Footer'
import BannerHeader from '../BannerHeader/BannerHeader'
import ImgSlider1 from '../../../../assets/img/slider/1.jpg';
import HomeAbout from '../../home/HomeAboutPage/HomeAbout';
import PricingSection from '../../home/HomePricingPage/PricingSection';
import HotelFacilitiesSection from '../../home/HomeFacilitiesPage/HotelFacilitiesSection';
import TeamSection from '../TeamSection/TeamSection';
import TestimonialSlider from '../../home/HomeTestionmialsPage/TestimonialSlider';



const backgroundImg = `url(${ImgSlider1})`;



const About = () => {
    const bannerStyle = {
        backgroundImage: backgroundImg,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      };
  return (
    <>
        <Header/>
        <BannerHeader/>
        <HomeAbout/>
        {/* <PricingSection/> */}
        <HotelFacilitiesSection/>
        <TeamSection/>
        <TestimonialSlider/>
        <Footer/>
    </>
  )
}

export default About