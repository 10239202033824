import './App.css';
import 'font-awesome/css/font-awesome.min.css';
import ProgressCircle from './components/common/progressicon/ProgressCircle';

import Approuter from './router/Approuter';

function App() {
  return (
   <>
   {/* <ProgressCircle/> */}
    <Approuter/>
   </>
  );
}

export default App;
