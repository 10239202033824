import React from "react";
import { Routes, Route } from "react-router-dom";
import RoomsSuites from "../components/pages/rooms&suites/RSpage/RoomsSuites";
import RoomMainPage from "../components/pages/roomdetails/RoomMainPage/RoomMainPage";

const RoomSuitesRouter = () => {
  return (
    <>
      <Routes>
        {/* <Route path="/" element={<RoomsSuites />} />
        <Route path="roomdetails" element={<RoomMainPage />} /> */}
        <Route path="/" element={<RoomsSuites />} />
        <Route path="roomdetails/:roomId" element={<RoomMainPage />} />
      </Routes>
    </>
  );
};

export default RoomSuitesRouter;
