import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import IMG2 from "../../../../assets/img/slider/2.jpg";
import axios from "axios";
import { API_CHECKNOW } from "../../../../utils/config";

const Reservations = () => {
  const [check_in, setCheckIn] = useState(null); 
  const [check_out, setCheckOut] = useState(null); 
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [room, setRoom] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [bookingData, setBookingData] = useState(null);

  const navigate = useNavigate();
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    console.log("Form submitted!");
    console.log("Check-in Date:", check_in);
    console.log("Check-out Date:", check_out);
    console.log("Adults:", adults);
    console.log("Children:", children);
    console.log("Rooms:", room);
  
    try {
      const response = await axios.post(
        API_CHECKNOW,
        {
          check_in: formatDateForAPI(check_in),
          check_out: formatDateForAPI(check_out),
          adults,
          children,
          room,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setBookingData(response.data);
      navigate("/searchroomdetails", { state: { bookingData: response.data } });
    } catch (error) {
      setError("An error occurred while fetching data from the server.");
    } finally {
      setLoading(false);
    }
  };



  const formatDateForAPI = (date) => {
    if (!date) return null;
    return date.toISOString().slice(0, 10);
  };

  const backgroundStyle = {
    backgroundImage: `url(${IMG2})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };

  return (
    <section className="testimonials">
      <div
        className="background bg-img bg-fixed section-padding pb-0"
        style={backgroundStyle}
        data-overlay-dark="2"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-5 mb-30 mt-30">
              <p>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
                <i className="star-rating"></i>
              </p>
              <h5>
                Each of our guest rooms feature a private bath, wi-fi, cable
                television and include full breakfast.
              </h5>
              <div className="reservations mb-30">
                <div className="icon color-1">
                  <span className="flaticon-call"></span>
                </div>
                <div className="text">
                  <p className="color-1">Reservation</p>{" "}
                  <a className="color-1" href="tel:+44 7877 766886">
                  +44 7877 766886
                  </a>
                </div>
              </div>
              <p>
                <i className="ti-check"></i>
                <small>Call us, it's toll-free.</small>
              </p>
            </div>

            {/* <div className="col-md-5 offset-md-2">
              <div className="booking-box">
                <div className="head-box">
                  <h6>Rooms & Suites</h6>
                  <h4>Hotel Booking Form</h4>
                </div>
                <div className="booking-inner clearfix">
                  <form
                    action="rooms2.html"
                    className="form1 clearfix"
                    onSubmit={handleFormSubmit}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input1_wrapper">
                          <label>Check in</label>
                          <div className="input1_inner">
                            <DatePicker
                              selected={check_in}
                              onChange={(date) => setCheckIn(date)}
                              placeholderText="Check in"
                              className="form-control input"
                              dateFormat="MM/dd/yyyy"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input1_wrapper">
                          <label>Check out</label>
                          <div className="input1_inner">
                            <DatePicker
                              selected={check_out}
                              onChange={(date) => setCheckOut(date)}
                              placeholderText="Check out"
                              className="form-control input"
                              dateFormat="MM/dd/yyyy"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="select1_wrapper">
                          <label>Adults</label>
                          <div className="select1_inner">
                            <select
                              className="select2 select"
                              style={{ width: "100%" }}
                              value={adults}
                              onChange={(e) =>
                                setAdults(parseInt(e.target.value))
                              }
                            >
                              <option value="1">1 Adult</option>
                              <option value="2">2 Adults</option>
                              <option value="3">3 Adults</option>
                              <option value="4">4 Adults</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="select1_wrapper">
                          <label>Children</label>
                          <div className="select1_inner">
                            <select
                              className="select2 select"
                              style={{ width: "100%" }}
                              value={children}
                              onChange={(e) =>
                                setChildren(parseInt(e.target.value))
                              }
                            >
                              <option value="0">None</option>
                              <option value="1">1 Child</option>
                              <option value="2">2 Children</option>
                              <option value="3">3 Children</option>
                              <option value="4">4 Children</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="select1_wrapper">
                          <label>Rooms</label>
                          <div className="select1_inner">
                            <select
                              className="select2 select"
                              style={{ width: "100%" }}
                              value={room}
                              onChange={(e) =>
                                setRoom(parseInt(e.target.value))
                              }
                            >
                              <option value="1">1 Room</option>
                              <option value="2">2 Rooms</option>
                              <option value="3">3 Rooms</option>
                              <option value="4">4 Rooms</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                     

                        <button type="submit" className="btn-form1-submit mt-15">
                {loading ? "Loading..." : "Check Availability"}
              </button>

                      </div>
                      {error && <p className="error-message">{error}</p>}

                    </div>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reservations;
