import { useState, useEffect } from 'react';
import axios from 'axios';
import {API_URL} from "../../../../utils/config"

const useFacilitiesData = () => {
  const [facilitiesData, setFacilitiesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFacilitiesData = async () => {
      try {
        const response = await axios.get(API_URL);
        setFacilitiesData(response.data.hotel_facilities);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching facilities data:', error);
        setIsLoading(false);
      }
    };

    fetchFacilitiesData();
  }, []);

  return { facilitiesData, isLoading };
};

export default useFacilitiesData;
