import React from "react";
import { useLocation } from "react-router-dom";
import ModalCouponCode from "./ModalCouponCode";

const HotelCard = () => {
  const location = useLocation();
  const { roomId, state: { bookNowData } } = location;
  const { name, primary_image, price, price_type, description } = bookNowData;

  if (!bookNowData) {
    return <p>Loading...</p>;
  }

  return (
      <div className="shadow-soft bg-white rounded-sm book-now">
        <div className="mt-5">
          <a href="#" className="d-block mb-3">
            <img
              className="img-fluid rounded-2"
              src={primary_image}
              alt="Image-Description"
            />
          </a>
          <div className="my-1 flex-horizontal-center text-gray-1">
            <i className="icon flaticon-pin-1 mr-2 font-size-15"></i>
          </div>
        </div>
        <p>{description}</p>
        <div id="basicsAccordion">
          {["Booking Detail"].map((heading, index) => (
            <div
              className="card"
              key={index}
            >
              <div
                className="card-header card-collapse bg-transparent border-0"
                id={`basicsHeading${index + 1}`}
              >
                <h5 className="mb-0">
                  <button
                    type="button"
                    className="btn btn-link border-0 btn-block d-flex justify-content-between card-btn py-3 px-4 font-size-17 font-weight-bold text-dark"
                    data-toggle="collapse"
                    data-target={`#basicsCollapse${index + 1}`}
                    aria-expanded={index === 0}
                    aria-controls={`basicsCollapse${index + 1}`}
                  >
                    {heading}
                    <span className="card-btn-arrow font-size-14 text-dark">
                      <i className="fa fa-chevron-down"></i>
                    </span>
                  </button>
                </h5>
              </div>
              <div
                id={`basicsCollapse${index + 1}`}
                className={`collapse ${index === 0 ? "show" : ""}`}
                aria-labelledby={`basicsHeading${index + 1}`}
                data-parent="#basicsAccordion"
              >
                <div className="card-body pt-0">
                  <ul className="list-unstyled font-size-1 mb-0 font-size-16">
                    <li className="d-flex justify-content-between">
                      <span className="font-weight-medium">
                     
                        <span className="text-gray-1 font-weight-normal pp">
                          {name} &nbsp;&nbsp;&nbsp; {price_type} {price}
                        </span>
                        <span className="text-gray-1 font-weight-normal code-cpn">
                          <ModalCouponCode/>
                        </span>
                      </span>
                      <span className="text-secondary">
                        <a href="#" className="text-underline">
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

  );
};

export default HotelCard;
