// import React, { useEffect, useState } from "react";
// import { API_URL } from "../../../../utils/config";
// import Modal from "react-modal";
// import IMG2 from "../../../../assets/img/slider/2.jpg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faYoutube } from "@fortawesome/free-brands-svg-icons";
// import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"; // Import close icon


// const PromotionalVideo = () => {
//   const [youtubeData, setYoutubeData] = useState(null);
//   const [modalIsOpen, setModalIsOpen] = useState(false);
  

//   useEffect(() => {
//     // Fetch data from the API
//     fetch(API_URL)
//       .then((response) => response.json())
//       .then((data) => {
//         // Assuming the YouTube data is available in the 'youtube' property
//         setYoutubeData(data.youtube);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   const openModal = () => {
//     setModalIsOpen(true);
//   };

//   const closeModal = () => {
//     setModalIsOpen(false);
//   };
// console.log(youtubeData?.url)
//   return (
//     <section
//       className="video-wrapper video section-padding bg-img bg-fixed"
//       data-overlay-dark="3"
//       style={{ backgroundImage: `url(${IMG2})` }}
//     >
//       <div className="container">
//         <div className="row">
//           <div className="col-md-8 offset-md-2 text-center">
//             <span>
//               <i className="star-rating"></i>
//               <i className="star-rating"></i>
//               <i className="star-rating"></i>
//               <i className="star-rating"></i>
//               <i className="star-rating"></i>
//             </span>
//             <div className="section-subtitle">
//               <span>{youtubeData?.title}</span>
//             </div>
//             <div className="section-title">
//               <span>{youtubeData?.subtitle}</span>
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="text-center col-md-12 vid">
//             {youtubeData && (
//               <>
//                 <div className="vid-butn">
//                   <span className="icon" onClick={openModal}>
//                     <FontAwesomeIcon icon={faYoutube} size="2x" />
//                   </span>
//                 </div>
//                 <Modal
//                   isOpen={modalIsOpen}
//                   onRequestClose={closeModal}
//                   className="modal"
//                   overlayClassName="overlay"
//                 >
//                   <div className="modal-header">
//                     <span className="close-icon" onClick={closeModal}>
//                       <FontAwesomeIcon icon={faTimesCircle} size="lg" />
//                     </span>
//                   </div>
                  
//                 </Modal>
//               </>
//             )}
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };
//  export default PromotionalVideo;


// import React, { useEffect, useState } from "react";
// import { API_URL } from "../../../../utils/config";
// import Modal from "react-modal";
// import IMG2 from "../../../../assets/img/slider/2.jpg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faYoutube } from "@fortawesome/free-brands-svg-icons";
// import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"; // Import close icon

// const PromotionalVideo = () => {
//   const [youtubeData, setYoutubeData] = useState(null);
//   const [modalIsOpen, setModalIsOpen] = useState(false);

//   useEffect(() => {
//     // Fetch data from the API
//     fetch(API_URL)
//       .then((response) => response.json())
//       .then((data) => {
//         // Assuming the YouTube data is available in the 'youtube' property
//         setYoutubeData(data.youtube);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   const openModal = () => {
//     setModalIsOpen(true);
//   };

//   const closeModal = () => {
//     setModalIsOpen(false);
//   };

//   return (
//     <section
//       className="video-wrapper video section-padding bg-img bg-fixed"
//       data-overlay-dark="3"
//       style={{ backgroundImage: `url(${IMG2})` }}
//     >
//       <div className="container">
//         <div className="row">
//           <div className="col-md-8 offset-md-2 text-center">
//             <span>
//               <i className="star-rating"></i>
//               <i className="star-rating"></i>
//               <i className="star-rating"></i>
//               <i className="star-rating"></i>
//               <i className="star-rating"></i>
//             </span>
//             <div className="section-subtitle">
//               <span>{youtubeData?.title}</span>
//             </div>
//             <div className="section-title">
//               <span>{youtubeData?.subtitle}</span>
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="text-center col-md-12 vid">
//             {youtubeData && (
//               <>
//                 <div className="vid-butn">
//                   <span className="icon" onClick={openModal}>
//                     <FontAwesomeIcon icon={faYoutube} size="2x" />
//                   </span>
//                 </div>
//                 <Modal
//                   isOpen={modalIsOpen}
//                   onRequestClose={closeModal}
//                   className="modal"
//                   overlayClassName="overlay"
//                 >
//                   <div className="modal-header">
//                     <span className="close-icon" onClick={closeModal}>
//                       <FontAwesomeIcon icon={faTimesCircle} size="lg" />
//                     </span>
//                   </div>
//                   {/* Insert your YouTube video iframe here */}
//                   <iframe
//                     className="embed-responsive-item"
//                     src="https://www.youtube.com/embed/bB8KO6N0Jx0?autoplay=1"
//                     style={{ width: '100%', height: '80vh' }}
//                     allowFullScreen
//                   ></iframe>
//                 </Modal>
//               </>
//             )}
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default PromotionalVideo;


// import React, { useEffect, useState } from "react";
// import { API_URL } from "../../../../utils/config";
// import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
// import IMG2 from "../../../../assets/img/slider/2.jpg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faYoutube } from "@fortawesome/free-brands-svg-icons";

// import Slider from "react-slick";

// const PromotionalVideo = () => {
//   const [youtubeData, setYoutubeData] = useState(null);
//   const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
//   const [modalShow, setModalShow] = useState(false);

//   useEffect(() => {
//     fetch(API_URL)
//       .then((response) => response.json())
//       .then((data) => {
//         setYoutubeData(data.link); 
//         console.log("YouTube Data:", data.link);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   const openModal = (index) => {
//     setSelectedVideoIndex(index);
//     setModalShow(true);
//     console.log("Modal opened for video index:", index);
//   };

//   const closeModal = () => {
//     setSelectedVideoIndex(0);
//     setModalShow(false);
//     console.log("Modal closed");
//   };

//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//   };

//   const youtubeLinks = youtubeData || {};
//   const videoUrls = Object.values(youtubeLinks);

//   return (
//     <section
//       className="video-wrapper video section-padding bg-img bg-fixed"
//       data-overlay-dark="3"
//       style={{ backgroundImage: `url(${IMG2})` }}
//     >
//       <div className="container">
      
//         <div className="row">
//           {videoUrls && videoUrls.length > 0 ? (
//             videoUrls.map((videoUrl, index) => (
//               <div key={index} className="text-center col-md-12 vid">
//                 <div className="vid-butn">
//                   <span className="icon" onClick={() => openModal(index)}>
//                     <FontAwesomeIcon icon={faYoutube} size="2x" />
//                   </span>
//                 </div>
//               </div>
//             ))
//           ) : (
//             <p>No YouTube videos available</p>
//           )}
//         </div>
//       </div>

    
//       <Modal
//         show={modalShow}
//         onHide={closeModal}
//         size="lg"
//         centered
//         className="modal"
//         dialogClassName="modal-dialog"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Video Modal</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <h4>Centered Modal</h4>
         
//           {videoUrls.length > 0 ? (
//             <div className="video-container">
//               <iframe
//                 width="100%"
//                 height="400"
//                 src={`https://www.youtube.com/embed/${videoUrls[selectedVideoIndex].split('v=')[1]}`}
//                 title={`Video ${selectedVideoIndex}`}
//                 allow="autoplay; encrypted-media"
//                 allowFullScreen
//               ></iframe>
//             </div>
//           ) : null}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button onClick={closeModal}>Close</Button>
//         </Modal.Footer>
//       </Modal>
//     </section>
//   );
// };

// export default PromotionalVideo;



import React, { useEffect, useState } from "react";
import { API_URL } from "../../../../utils/config";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import IMG2 from "../../../../assets/img/slider/2.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import Slider from "react-slick"; // Import Slider from react-slick

const PromotionalVideo = () => {
  const [youtubeData, setYoutubeData] = useState(null);
  const [youtubeDetail, setYoutubeDetail] = useState(null);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    fetch(API_URL)
      .then((response) => response.json())
      .then((data) => {
        setYoutubeData(data.link); // Use the "link" object for video URLs
        setYoutubeDetail(data.youtube); // Use the "link" object for video URLs
        console.log("YouTube Data:", data.link);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const openModal = (index) => {
    setSelectedVideoIndex(index);
    setModalShow(true);
    console.log("Modal opened for video index:", index);
  };

  const closeModal = () => {
    setSelectedVideoIndex(0);
    setModalShow(false);
    console.log("Modal closed");
  };

  const sliderSettings = {
    dots: true, // Enable dots navigation
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3 } },
  
  
      {
        breakpoint: 767,
        settings: {
        slidesToShow: 1 } }]
  };


  

  const youtubeLinks = youtubeData || {};
  const videoUrls = Object.values(youtubeLinks);

  // Fetch video thumbnails
  const videoThumbnails = videoUrls.map((videoUrl) => {
    const videoId = videoUrl.split("v=")[1];
    return `https://img.youtube.com/vi/${videoId}/0.jpg`; // This URL fetches the default thumbnail
  });
 
  return (
    <section
      className="video-wrapper video section-padding bg-img bg-fixed"
      data-overlay-dark="3"
      // style={{ backgroundImage: `url(${IMG2})` }}
    >
      <div className="container">
      <div className="row">
           <div className="col-md-8 offset-md-2 text-center">
             <span>
               <i className="star-rating"></i>
              <i className="star-rating"></i>
               <i className="star-rating"></i>
               <i className="star-rating"></i>
               <i className="star-rating"></i>
             </span>
             {/* <div className="section-subtitle">
               <span>{youtubeDetail?.title}</span>
             </div> */}
             <div className="section-title">
               <span>{youtubeDetail?.subtitle}</span>
           </div>
           </div>
         </div>
        <Slider {...sliderSettings}>
          {videoUrls.map((videoUrl, index) => (
            <div key={index} className="text-center col-md-12 vid">
              <div
                className="vid-butn"
                style={{
                  backgroundImage: `url(${videoThumbnails[index]})`, // Set thumbnail as background image
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%", // Set your desired width
                  height: "280px", // Set your desired height
                  borderRadius: "0px"  
                }}
                onClick={() => openModal(index)}
              >
                <span className="icon">
                  <FontAwesomeIcon icon={faYoutube} size="2x" />
                </span>
              </div>
            </div>
          ))}
        </Slider>
      </div>

    
      <Modal
        show={modalShow}
        onHide={closeModal}
        size="lg"
        centered
        className="modal video-popup"
        dialogClassName="modal-dialog"
      >
        <Modal.Header closeButton>
       
        </Modal.Header>
        <Modal.Body>
         
          
          {videoUrls.length > 0 ? (
            <div className="video-container">
              <iframe
                width="100%"
                height="400"
                src={`https://www.youtube.com/embed/${videoUrls[selectedVideoIndex].split('v=')[1]}`}
                title={`Video ${selectedVideoIndex}`}
                allow="autoplay; encrypted-media"
                allowFullScreen
              ></iframe>
            </div>
          ) : null}
        </Modal.Body>
       
      </Modal>
    </section>
  );
};

export default PromotionalVideo;



// import React, { useEffect, useState } from "react";
// import { API_URL } from "../../../../utils/config";
// import Modal from "react-modal";
// import IMG2 from "../../../../assets/img/slider/2.jpg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faYoutube } from "@fortawesome/free-brands-svg-icons";
// import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
// import VideoOperation from "./VideoOperation";

// const PromotionalVideo = () => {
//   const [youtubeData, setYoutubeData] = useState(null);
//   const [modalIsOpen, setModalIsOpen] = useState(false);

//   useEffect(() => {
//     // Fetch data from the API
//     fetch(API_URL)
//       .then((response) => response.json())
//       .then((data) => {
//         // Assuming the YouTube data is available in the 'youtube' property
//         setYoutubeData(data.youtube);
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   const openModal = () => {
//     setModalIsOpen(true);
//   };

//   const closeModal = () => {
//     setModalIsOpen(false);
//   };

//   return (
//     <section
//       className="video-wrapper video section-padding bg-img bg-fixed"
//       data-overlay-dark="3"
//       style={{ backgroundImage: `url(${IMG2})` }}
//     >
//       <div className="container">
//         <div className="row">
//           <div className="col-md-8 offset-md-2 text-center">
//             <span>
//               <i className="star-rating"></i>
//               <i className="star-rating"></i>
//               <i className="star-rating"></i>
//               <i className="star-rating"></i>
//               <i className="star-rating"></i>
//             </span>
//             <div className="section-subtitle">
//               <span>{youtubeData?.title}</span>
//             </div>
//             <div className="section-title">
//               <span>{youtubeData?.subtitle}</span>
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           <div className="text-center col-md-12 vid">
//             {youtubeData && (
//               <>
//                 <div className="vid-butn">
//                   <span className="icon" onClick={openModal}>
//                     <FontAwesomeIcon icon={faYoutube} size="2x" />
//                   </span>
//                 </div>
//                 <Modal
//                   isOpen={modalIsOpen}
//                   onRequestClose={closeModal}
//                   className="modal"
//                   overlayClassName="overlay"
//                 >
//                   <div className="modal-header">
//                     <span className="close-icon" onClick={closeModal}>
//                       <FontAwesomeIcon icon={faTimesCircle} size="lg" />
//                     </span>
//                   </div>
//                   {youtubeData.url.split(",").map((url, index) => (
//                     <VideoOperation key={index} youtubeUrl={url.trim()} />
//                   ))}
//                 </Modal>
//               </>
//             )}
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default PromotionalVideo;
