// import React from "react";
// import IMG8 from '../../../../assets/img/rooms/8.jpg';
// import IMG2 from '../../../../assets/img/rooms/2.jpg';
// import API_URL from "../../../../utils/config"
// const HomeAbout = () => {
//   return (
//     <>
//       <section className="about section-padding">
//         <div className="container">
//           <div className="row">
//             <div
//               className="col-md-6 mb-30 animate-box"
//               data-animate-effect="fadeInUp"
//             >
//               <span>
//                 <i className="star-rating"></i>
//                 <i className="star-rating"></i>
//                 <i className="star-rating"></i>
//                 <i className="star-rating"></i>
//                 <i className="star-rating"></i>
//               </span>
//               <div className="section-subtitle">The Lluex Luxury Hotel</div>
//               <div className="section-title">Enjoy a Luxury Experience</div>
//               <p>
//                 Welcome to the best five-star deluxe hotel in New York. Hotel
//                 elementum sesue the aucan vestibulum aliquam justo in sapien
//                 rutrum volutpat. Donec in quis the pellentesque velit. Donec id
//                 velit ac arcu posuere blane.
//               </p>
//               <p>
//                 Hotel ut nisl quam nestibulum ac quam nec odio elementum sceisue
//                 the aucan ligula. Orci varius natoque penatibus et magnis dis
//                 parturient monte nascete ridiculus mus nellentesque habitant
//                 morbine.
//               </p>
//               <div className="reservations">
//                 <div className="icon">
//                   <span className="flaticon-call"></span>
//                 </div>
//                 <div className="text">
//                   <p>Reservation</p>
//                   <a href="tel:855-100-4444">855 100 4444</a>
//                 </div>
//               </div>
//             </div>
//             <div
//               className="col col-md-3 animate-box"
//               data-animate-effect="fadeInUp"
//             >
//               <img src={IMG8} alt="" className="mt-90 mb-30" />
//             </div>
//             <div
//               className="col col-md-3 animate-box"
//               data-animate-effect="fadeInUp"
//             >
//               <img src={IMG2} alt="" />
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default HomeAbout;

import React, { useState, useEffect } from "react";
import IMG8 from "../../../../assets/img/rooms/8.jpg";
import IMG2 from "../../../../assets/img/rooms/2.jpg";
import { API_URL } from "../../../../utils/config";
import LottieAnimation from "../../../common/lottieSpinner/LottieSpinner"

const HomeAbout = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(API_URL);
        const jsonData = await response.json();
        setData(jsonData); 
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  if (loading) {
    return <LottieAnimation/>
  }

  if (!data) {
    return <div>Error</div>;
  }

  const { first_banner } = data;
  const { title, images, description } = first_banner;

  // Render your component using the extracted datazz
  return (
    <section className="about section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mb-30 animate-box" data-animate-effect="fadeInUp">
            <span>
              <i className="star-rating"></i>
              <i className="star-rating"></i>
              <i className="star-rating"></i>
              <i className="star-rating"></i>
              <i className="star-rating"></i>
            </span>
            <div className="section-subtitle">The Lluex Luxury Hotel</div>
            <div className="section-title">{title}</div>
            <p>{description}</p>
            <div className="reservations">
              <div className="icon"><span className="fa fa-phone"></span></div>
              <div className="text">
                <p>Reservation</p>
                <a href="tel:+44 7877 766886">+44 7877 766886</a>
              </div>
            </div>
          </div>
          <div className="col col-md-3 animate-box" data-animate-effect="fadeInUp">
            <img src={images[0]} alt="" className="mt-90 mb-30" />
          </div>
          <div className="col col-md-3 animate-box" data-animate-effect="fadeInUp">
            <img src={images[1]} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeAbout;
