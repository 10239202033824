// import React from "react";
// import Modal from "react-modal";

// const customModalStyles = {
//   content: {
//     width: "70%", // Set the desired width of the modal content
//     margin: "auto", // Center the modal horizontally
//     zIndex: 7,
//     display:"flex",
//     justifyContent:'center',
//   },
// };

// const CustomModal = ({ isOpen, onRequestClose, images }) => {
//   return (
//     <Modal
//       isOpen={isOpen}
//       onRequestClose={onRequestClose}
//       style={customModalStyles} // Apply custom styles to the modal
//     >
//       <div className="modal-content">
//         {images.map((image, index) => (
//           <img key={index} src={image} alt={`Image ${index}`} />
//         ))}
//       </div>
//     </Modal>
//   );
// };

// export default CustomModal;


// import React from "react";
// import Modal from "react-modal";

// const customModalStyles = {
//   content: {
//     width: "70%", // Set the desired width of the modal content
//     margin: "auto", // Center the modal horizontally
//     zIndex: 7,
//     display: "flex",
//     flexDirection: "column", // Align items in a column layout
//     alignItems: "center", // Center items horizontally
//   },
// };

// const CustomModal = ({ isOpen, onRequestClose, images }) => {
//   return (
//     <Modal
//       isOpen={isOpen}
//       onRequestClose={onRequestClose}
//       style={customModalStyles} // Apply custom styles to the modal
//     >
//       <span // Add a close icon element
//         className="close-icon"
//         onClick={onRequestClose}
//         style={{
//           position: "absolute",
//           top: "10px",
//           right: "10px",
//           cursor: "pointer",
//           fontSize: "24px",
//         }}
//       >
//         &times; {/* Display the '×' character for a close icon */}
//       </span>
//       <div className="modal-content">
//         {images.map((image, index) => (
//           <img key={index} src={image} alt={`Image ${index}`} />
//         ))}
//       </div>
//     </Modal>
//   );
// };

// export default CustomModal;


import React from "react";
import Modal from "react-modal";

const customModalStyles = {
  content: {
    width: "70%", // Set the desired width of the modal content
    margin: "auto", // Center the modal horizontally
    zIndex: 7,
    display: "flex",
    flexDirection: "column", // Align items in a column layout
    alignItems: "center", // Center items horizontally
  },
};

const closeIconStyles = {
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
  fontSize: "24px",
  color: "red", // Customize the color of the close icon
};

const CustomModal = ({ isOpen, onRequestClose, images }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customModalStyles} // Apply custom styles to the modal
    >
      <span // Add a close icon element
        className="close-icon"
        onClick={onRequestClose}
        style={closeIconStyles}
      >
        &times; {/* Display the '×' character for a close icon */}
      </span>
      <div className="modal-content">
        {images.map((image, index) => (
          <img key={index} src={image} alt={`Image ${index}`} />
        ))}
      </div>
    </Modal>
  );
};

export default CustomModal;
