// import React, { useState, useEffect } from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import RoomCard from "../../roomdetails/RoomContent/RoomCard";
// import { useLocation } from "react-router-dom";
// import axios from 'axios';

// const RoomSection = () => {
//   const location = useLocation();
//   const { hotel_id } = location.state;
//   const [roomData, setRoomData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchRoomDetails = async () => {
//       try {
//         console.log("hotel id " + hotel_id )
//         const response = await axios.post(
//           "http://lluxe.sbwares.com/api/hotel_rooms",
//           { hotel_id: hotel_id }
//         );
//         console.log(response.data)

//         const data = response.data;
//         if (data.status === "true") {
//           setRoomData(data.data);
//         } else {
//           setError("Error fetching room details from the API.");
//         }
//         setLoading(false);
//       } catch (error) {
//         setError("Error fetching room details from the API.");
//         setLoading(false);
//       }
//     };

//     fetchRoomDetails();
//   }, [hotel_id]);

//   const handleRoomCardClick = (hotel_id) => {
//     console.log("Room Card Clicked for Hotel ID:", hotel_id);
//     // Handle the click event here
//   };

//   if (loading) {
//     return <p>Loading...</p>;
//   }

//   if (error) {
//     return <p>{error}</p>;
//   }

//   return (
//     <section className="section-padding">
//       <div className="container">
//           {roomData.map((item, index) => (
//             <div key={index}>
//               <RoomCard
//                 imgSrc={item.primary_image}
//                 name={item.name}
//                 price={item.price}
//                 roomType={item.content}
//                 facilities={item.facilities}
//                 hotel_id={item.id}
//                 onRoomCardClick={handleRoomCardClick}
//               />
//             </div>
//           ))}
//         </div>
//     </section>
//   );
// };

// export default RoomSection;






























import React, { useState, useEffect } from 'react';
import LottieAnimation from "../../../common/lottieSpinner/LottieSpinner"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RoomCard from "../../roomdetails/RoomContent/RoomCard";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import RoomContentPage from '../../roomdetails/RoomContent/RoomContentPage';
import { API_HOTELROOM } from '../../../../utils/config';

const RoomSection = () => {
  const location = useLocation();
  const { hotel_id } = location.state;
  const [roomData, setRoomData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRoomId, setSelectedRoomId] = useState(null);


  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        console.log("hotel id " + hotel_id )
        const response = await axios.post(
          API_HOTELROOM,
          { hotel_id: hotel_id }
        );
        console.log(response.data)

        const data = response.data;
        if (data.status === "true") {
          setRoomData(data.data);
        } else {
          setError("Error fetching room details from the API.");
        }
        setLoading(false);
      } catch (error) {
        setError("Error fetching room details from the API.");
        setLoading(false);
      }
    };

    fetchRoomDetails();
  }, [hotel_id]);

  // const handleRoomCardClick = (hotel_id) => {
    const handleRoomCardClick = (roomId) => {
    console.log(" Hotel ID:", hotel_id);
    console.log("Room ID:", roomId);
    setSelectedRoomId(roomId);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (selectedRoomId) {
        console.log("Selected Room ID:", selectedRoomId);
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (loading) {
    return <p>Loading... <LottieAnimation/> </p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className="section-padding card-pro">
      <div className="container">
      <form onSubmit={handleSubmit}>
          {roomData.map((item, index) => (
            <div key={index}>
              <RoomCard
                imgSrc={item.primary_image}
                name={item.name}
                price={item.price}
                roomType={item.content}
                facilities={item.facilities}
                hotel_id={item.id}
                roomId={item.id}
                onRoomCardClick={handleRoomCardClick}
              />
            </div>
          ))}

{console.log(selectedRoomId)}

{selectedRoomId && <RoomContentPage roomId={selectedRoomId} />}
        </form>
        </div>
    </section>
  );
};

export default RoomSection;






