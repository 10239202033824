import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PricingCard from './PricingCard'; 
import IMG1 from "../../../../assets/img/pricing/1.jpg"
import IMG2 from "../../../../assets/img/pricing/2.jpg"
import IMG3 from "../../../../assets/img/pricing/3.jpg"
import IMG4 from "../../../../assets/img/pricing/4.jpg"


const PricingSection = () => {
  const pricingData = [
    {
      imgSrc: IMG1,
      name: 'Room cleaning',
      amount: '£50 / month',
      features: [
        { text: 'Hotel ut nisan the duru', available: false },
        { text: 'Orci miss natoque vasa ince', available: true },
        { text: 'Clean sorem ipsum morbin', available: false },
      ],
    },
    {
      imgSrc: IMG2,
      name: 'Drinks included',
      amount: '£30 / daily',
      features: [
        { text: 'Hotel ut nisan the duru', available: true },
        { text: 'Orci miss natoque vasa ince', available: false },
        { text: 'Clean sorem ipsum morbin', available: false },
      ],
    },
    {
      imgSrc: IMG3,
      name: 'Room Breakfast',
      amount: '£30 / daily',
      features: [
        { text: 'Hotel ut nisan the duru', available: false },
        { text: 'Orci miss natoque vasa ince', available: true },
        { text: 'Clean sorem ipsum morbin', available: false },
      ],
    },
    {
      imgSrc: IMG4,
      name: 'Safe & Secure',
      amount: '£15 / daily',
      features: [
        { text: 'Hotel ut nisan the duru', available: true },
        { text: 'Orci miss natoque vasa ince', available: false },
        { text: 'Clean sorem ipsum morbin', available: false },
      ],
    },
  ];

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="pricing section-padding bg-blck">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="section-subtitle">
              <span>Best Prices</span>
            </div>
            <div className="section-title">
              <span>Extra Services</span>
            </div>
            <p className="color-2">
              The best prices for your relaxing vacation. The utanislen quam nestibulum ac quame odion elementum sceisue the aucan.
            </p>
            <p className="color-2">
              Orci varius natoque penatibus et magnis disney parturient monte nascete ridiculus mus nellen etesque habitant morbine.
            </p>
            <div className="reservations mb-30">
              <div className="icon">
                <span className="fa fa-phone"></span>
              </div>
              <div className="text">
                <p className="color-2">For information</p>
                <a href="tel:+44 7877 766886">+44 7877 766886</a>
              </div>
            </div>
          </div>
          <div className="col-md-8">
          <Slider {...slickSettings}>
              {pricingData.map((item, index) => (
                <PricingCard
                  key={index}
                  imgSrc={item.imgSrc}
                  name={item.name}
                  amount={item.amount}
                  features={item.features}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
