// ParentComponent.js
import React, { useState, useEffect } from "react";
import SearchRoomSection from "./SearchRoomSection";
import axios from "axios";

const ParentComponent = () => {
  const [bookingData, setBookingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await axios.post("https://backend.lluxe.com/api/check_now", {
              // Add your request data here if required
            });
            setBookingData(response.data);
            setLoading(false);
          } catch (error) {
            setError("An error occurred while fetching data from the server.");
            setLoading(false);
          }
        };
    fetchData();
  }, []);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <SearchRoomSection bookingData={bookingData} />
        
      )}
      {console.log(bookingData)}
    </div>
  );
};

export default ParentComponent;
