import React from "react";
import HotelCard from "./sidebookdetail/HotelCard";

const RightPart = () => {
  return (
    <div className="col-lg-4 col-xl-3">
      <HotelCard />
    </div>
  );
};

export default RightPart;
