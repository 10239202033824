import React from 'react'
import Header from '../../common/header/Header'
import BannerHeader from './bannerHeader/BannerHeader'
import ThanksImg from './ThanksImg'
import Footer from '../../common/footer/Footer'

const ThanksMainPage = () => {
  return (
    <>
        <Header/>
        <BannerHeader/>
        <ThanksImg/>
        <Footer/>
    </>
  )
}

export default ThanksMainPage