import React from 'react'
import Footer from '../../common/footer/Footer'
import Header from '../../common/header/Header'
import BookingHistoryBannerHeader from "./BookingHistoryBannerHeader/BookingHistoryBannerHeader"
import ReviewSection from './ReviewSection'

const BookingHistoryMainPage = () => {
  return (
    <>
        <Header/>
        <BookingHistoryBannerHeader/>
        <ReviewSection/>
        <Footer/>

    </>
  )
}

export default BookingHistoryMainPage