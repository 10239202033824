import React from 'react'
import Header from '../../common/header/Header'
import VerifyBannerHeader from './VerifyBannerHeader/VerifyBannerHeader'
import VerifyAccountPage from './VerifyAccountPage'
import Footer from '../../common/footer/Footer'

const VerifyMainPage = () => {
  return (
    <>
        <Header/>
        <VerifyBannerHeader/>
        <VerifyAccountPage/>
        <Footer/>
    </>
  )
}

export default VerifyMainPage