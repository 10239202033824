import React from 'react'
import Header from '../../../common/header/Header'
import ForgetBannerHeader from './forgetbannerHeader/ForgetBannerHeader'
import Footer from '../../../common/footer/Footer'
import ForgotPasswordPage from './ForgotPasswordPage'

const ForgetPassword = () => {
  return (
    <div>
        <Header/>
        <ForgetBannerHeader/>
        <ForgotPasswordPage/>
        <Footer/>
    </div>
  )
}

export default ForgetPassword