import React, { useState, useEffect } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ModalCouponCode = ({ onSelectCoupon }) => {
  const [show, setShow] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleHover = (coupon) => {
    setSelectedCoupon(coupon);
  };

  const handleCopyCouponCode = (coupon) => {
    if (coupon) {
      const dummyInput = document.createElement("input");
      document.body.appendChild(dummyInput);
      dummyInput.value = coupon.coupon_code;
      dummyInput.select();
      document.execCommand("copy");
      document.body.removeChild(dummyInput);
      onSelectCoupon(coupon.coupon_code);
      
      console.log("Coupon Code Copied:", coupon.coupon_code);

      setCoupons(prevCoupons => 
        prevCoupons.map(c => 
          c.id === coupon.id ? { ...c, copied: true } : c
        )
      );

      setTimeout(() => {
        setCoupons(prevCoupons => 
          prevCoupons.map(c => 
            c.id === coupon.id ? { ...c, copied: false } : c
          )
        );
      }, 2000); 
    }
  };

  useEffect(() => {
    fetch('https://backend.lluxe.com/api/get_coupon')
      .then(response => response.json())
      .then(data => {
        if (data.status === "true") {
          const couponsWithCopied = data.data.map(coupon => ({
            ...coupon,
            copied: false,
          }));
          setCoupons(couponsWithCopied);
        } else {
          console.error('API Error:', data.message);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div>
      <Button onClick={handleShow}>Coupon Code</Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Coupon Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h4>Coupons:</h4>
            <ul>
              {coupons.map(coupon => (
                <li
                  key={coupon.id}
                  onMouseEnter={() => handleHover(coupon)}
                  onMouseLeave={() => setSelectedCoupon(null)}
                  style={{
                    backgroundColor: selectedCoupon === coupon ? '#e0e0e0' : 'transparent',
                    outline: selectedCoupon === coupon ? '1px solid #6495ed' : 'none',
                    transition: 'outline 0.3s ease-in-out'
                  }}
                >
                  <strong>{coupon.coupon_name}</strong> - {coupon.coupon_description}
                  <br />
                  Coupon Code: {coupon.coupon_code}, Discount: {coupon.discount}%
                  <br />

                  <Button
                    variant="primary"
                    onClick={() => handleCopyCouponCode(coupon)}
                    disabled={coupon.copied || (selectedCoupon !== coupon)}
                  >
                    {coupon.copied ? "Copied" : "Copy Coupon Code"}
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalCouponCode;
