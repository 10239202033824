// import React, { useState } from "react";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// import axios from "axios";
// import Checkout from "./Checkout";
// import { API_STRIPE } from "../../../../utils/config";

// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import CheckoutForm from "./CheckoutForm";
// const stripePromise = loadStripe(
//   // "sk_test_51Nhbd3J4E3TyUWskXALXxVI7VFVrSoeCYf0oNHw123UANfAMNJSKKlyYblccUVRPBz4ADPa3tsZz0RB1w65Ed05y00ZNgQtaQx"
//   "pk_test_51Nhbd3J4E3TyUWskDKFKqrdtilxaHf8Aa5qKrs0jwzuIkraT1mp74DzBGdq8uGJL2HmSWXbEmyeSkfMiMea29JiS00bVw40cbd"    
// );

// const PaymentProcess = () => {
//   // const PaymentProcess = ({ booking_id, amount }) => {

//   const navigate = useNavigate();
//   const location = useLocation();
//   const [validationErrors, setValidationErrors] = useState({});
//   const { booking_id, price, discounted_price } = location.state;
//   const calculatedAmount = discounted_price !== "" ? discounted_price : price;

//   const [currentTab, setCurrentTab] = useState("credit-card");
//   const [formData, setFormData] = useState({
//     card_no: "",
//     ccExpiryMonth: "",
//     ccExpiryYear: "",
//     cvvNumber: "",
//     booking_id: "",
//     amount:"",
//   });

//   const handlePaymentSuccess = (message) => {
//     toast.success(message);
//   };

//   const handlePayPalCheckout = () => {
//     // Trigger the PayPal checkout when the "Buy Now" button is clicked
//     setCurrentTab("paypal");
//   };

//   const handleTabChange = (tabName) => {
//     setCurrentTab(tabName);
//   };





//   console.log("bookingid,  " + booking_id );
// console.log("price " + price)
// console.log("discounted price " + discounted_price)
//   return (
//     <div className="booking-confrmation">
//       <h3
//         id="scroll-description"
//         className="font-size-21 font-weight-bold text-dark mb-4"
//       >
//         Your Card Information
//       </h3>

//       <div className="pay-cpn">
//         {discounted_price == "" ? (
//           <div className="">
//             <p className="">payable price{`${price}`}</p>
//           </div>
//         ) : (
//           <div>
//             <p className="discounted-price">orignal price{`${price}`}</p>

//             <p className="original-price">payable price {`${discounted_price}`}</p>
//           </div>
//         )}
//       </div>

//       <ul className="nav nav-tabs nav-justified" role="tablist">
//         <li className="nav-item">
//           <a
//             className={`nav-link ${
//               currentTab === "credit-card" ? "active" : ""
//             }`}
//             onClick={() => handleTabChange("credit-card")}
//           >
//             Payment with  card
//           </a>
//         </li>
//         <li className="nav-item">
//           <a
//             className={`nav-link ${currentTab === "paypal" ? "active" : ""} paypal-btn`}
//             onClick={() => handleTabChange("paypal")}
//           >
//             Payment with PayPal
//           </a>
//         </li>
//       </ul>
//       <div className="tab-content">
//         <div
//           className={`tab-pane fade ${
//             currentTab === "credit-card" ? "show active" : ""
//           }`}
//           id="credit-card"
//           role="tabpanel"
//         >
     

// <Elements stripe={stripePromise}  >
//         <CheckoutForm booking_id={booking_id}  amount={calculatedAmount} />
//       </Elements>

//         </div>

//         <div
//           className={`tab-pane fade ${
//             currentTab === "paypal" ? "show active" : ""
//           }`}
//           id="paypal"
//           role="tabpanel"
//         >
//           {currentTab === "paypal" && (
//             <Checkout
//               onSuccess={(orderId) => {
//                 // Handle success callback from Checkout component
//               }}
//             />
//           )}
//         </div>
//       </div>

//       <ToastContainer />
//     </div>
//   );
// };

// export default PaymentProcess;



import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Checkout from "./Checkout";
import { API_STRIPE } from "../../../../utils/config";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
const stripePromise = loadStripe(
  // "sk_test_51Nhbd3J4E3TyUWskXALXxVI7VFVrSoeCYf0oNHw123UANfAMNJSKKlyYblccUVRPBz4ADPa3tsZz0RB1w65Ed05y00ZNgQtaQx"
  "pk_live_51Nhbd3J4E3TyUWskJyfGuswalIrvzlo7rlgKCDuASgDF4aPZuBgWicfLubZDrWRqF4SYseuzK7LAKX6QjPI7SxKd00lDzTOjcv"    
);

const PaymentProcess = () => {
  // const PaymentProcess = ({ booking_id, amount }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const [validationErrors, setValidationErrors] = useState({});
  const { booking_id, price, discounted_price } = location.state;
  const calculatedAmount = discounted_price !== "" ? discounted_price : price;

  const [currentTab, setCurrentTab] = useState("credit-card");
  const [formData, setFormData] = useState({
    card_no: "",
    ccExpiryMonth: "",
    ccExpiryYear: "",
    cvvNumber: "",
    booking_id: "",
    amount:"",
  });

  const handlePaymentSuccess = (message) => {
    toast.success(message);
  };

  const handlePayPalCheckout = () => {
    // Trigger the PayPal checkout when the "Buy Now" button is clicked
    setCurrentTab("paypal");
  };

  const handleTabChange = (tabName) => {
    setCurrentTab(tabName);
  };





  console.log("bookingid,  " + booking_id );
console.log("price " + price)
console.log("discounted price " + discounted_price)
  return (
    <div className="booking-confrmation">
      <h3
        id="scroll-description"
        className="font-size-21 font-weight-bold text-dark mb-4"
      >
        Your Card Information
      </h3>

      <div className="pay-cpn">
        {discounted_price == "" ? (
          <div className="">
            <p className="">payable price{`${price}`}</p>
          </div>
        ) : (
          <div>
            <p className="discounted-price">orignal price{`${price}`}</p>

            <p className="original-price">payable price {`${discounted_price}`}</p>
          </div>
        )}
      </div>

      <ul className="nav nav-tabs nav-justified" role="tablist">
        <li className="nav-item">
          <a
            className={`nav-link ${
              currentTab === "credit-card" ? "active" : ""
            }`}
            onClick={() => handleTabChange("credit-card")}
          >
            Payment with  card
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${currentTab === "paypal" ? "active" : ""} paypal-btn`}
            onClick={() => handleTabChange("paypal")}
          >
            Payment with PayPal
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div
          className={`tab-pane fade ${
            currentTab === "credit-card" ? "show active" : ""
          }`}
          id="credit-card"
          role="tabpanel"
        >
     

    <Elements stripe={stripePromise}>
        <CheckoutForm booking_id={booking_id}  amount={calculatedAmount}     paymentMethod={currentTab === "credit-card" ? 0 : 1}
/>
      </Elements>

        </div>

        <div
  className={`tab-pane fade ${
    currentTab === "paypal" ? "show active" : ""
  }`}
  id="paypal"
  role="tabpanel"
>
  {currentTab === "paypal" && (
  <Checkout
  booking_id={booking_id}
  amount={calculatedAmount}
  onPaymentSuccess={(orderId) => {
    console.log("Payment successful! Order ID:", orderId);
    // Do any additional actions you need on payment success
    // You can also use the onPaymentSuccess callback from the parent
    // onPaymentSuccess("Payment successful!");
  }}
  paymentMethod={currentTab === "credit-card" ? "credit-card" : 1}
  
/>

  )}
</div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default PaymentProcess;
