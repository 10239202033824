// import React from 'react'
// import ImgSlider1 from '../../../../assets/img/slider/1.jpg';
// import ImgSlider2 from '../../../../assets/img/slider/2.jpg';
// import ImgSlider3 from '../../../../assets/img/slider/3.jpg';

// const HeaderSlider = () => {
//   return (
//     <>
//  <header className="header slider-fade">
//       <div className="owl-carousel owl-theme">
//         <div className="text-center item bg-img" data-overlay-dark="2" data-background="img/slider/1.jpg">
//           <div className="v-middle caption">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-10 offset-md-1">
//                   <span>
//                     <i className="star-rating"></i>
//                     <i className="star-rating"></i>
//                     <i className="star-rating"></i>
//                     <i className="star-rating"></i>
//                     <i className="star-rating"></i>
//                   </span>
//                   <h4>Luxury Hotel & Best Resort</h4>
//                   <h1>Enjoy a Luxury Experience</h1>
//                   <div className="butn-light mt-30 mb-30">
//                     <a href="#" data-scroll-nav="1"><span>Rooms & Suites</span></a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="text-center item bg-img" data-overlay-dark="2" data-background="img/slider/2.jpg">
//           <div className="v-middle caption">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-10 offset-md-1">
//                   <span>
//                     <i className="star-rating"></i>
//                     <i className="star-rating"></i>
//                     <i className="star-rating"></i>
//                     <i className="star-rating"></i>
//                     <i className="star-rating"></i>
//                   </span>
//                   <h4>Unique Place to Relax & Enjoy</h4>
//                   <h1>The Perfect Base For You</h1>
//                   <div className="butn-light mt-30 mb-30">
//                     <a href="#" data-scroll-nav="1"><span>Rooms & Suites</span></a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="text-center item bg-img" data-overlay-dark="3" data-background="img/slider/1.jpg">
//           <div className="v-middle caption">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-10 offset-md-1">
//                   <span>
//                     <i className="star-rating"></i>
//                     <i className="star-rating"></i>
//                     <i className="star-rating"></i>
//                     <i className="star-rating"></i>
//                     <i className="star-rating"></i>
//                   </span>
//                   <h4>The Ultimate Luxury Experience</h4>
//                   <h1>Enjoy The Best Moments of Life</h1>
//                   <div className="butn-light mt-30 mb-30">
//                     <a href="#" data-scroll-nav="1"><span>Rooms & Suites</span></a>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="reservation">
//         <a href="tel:8551004444">
//           <div className="icon d-flex justify-content-center align-items-center">
//             <i className="flaticon-call"></i>
//           </div>
//           <div className="call">
//             <span>855 100 4444</span> <br />Reservation
//           </div>
//         </a>
//       </div>
//     </header>

//     </>
//   )
// }

// export default HeaderSlider


// import React from 'react';
// import ImgSlider1 from '../../../../assets/img/slider/1.jpg';
// import ImgSlider2 from '../../../../assets/img/slider/2.jpg';
// import ImgSlider3 from '../../../../assets/img/slider/3.jpg';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import Slider from 'react-slick';

// const HeaderSlider = () => {
//   const settings = {
//     dots: false,
//     infinite: true,
//     autoplay: true,
//     speed: 2000, // Set the duration for each slide in milliseconds
//     autoplaySpeed: 2000, // Set the interval between slides in milliseconds
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     fade: true,
//     cssEase: 'linear',
//   };

//   return (
//     <Slider {...settings}>
//       <div>
//         <img src={ImgSlider1} alt="Slider 1" />
//         <div className="v-middle caption">
//           <div className="container">
//             <div className="row">
//             <div className="col-md-10 offset-md-1">
//                 <span>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                 </span>
//                 <h4>Luxury Hotel & Best Resort</h4>
//                 <h1>Enjoy a Luxury Experience</h1>
//                 <div className="butn-light mt-30 mb-30">
//                   <a href="#" data-scroll-nav="1"><span>Rooms & Suites</span></a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div>
//         <img src={ImgSlider2} alt="Slider 2" />
//         <div className="v-middle caption">
//           <div className="container">
//             <div className="row">
//             <div className="col-md-10 offset-md-1">
//                 <span>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                 </span>
//                 <h4>Unique Place to Relax & Enjoy</h4>
//                 <h1>The Perfect Base For You</h1>
//                 <div className="butn-light mt-30 mb-30">
//                   <a href="#" data-scroll-nav="1"><span>Rooms & Suites</span></a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div>
//         <img src={ImgSlider3} alt="Slider 3" />
//         <div className="v-middle caption">
//           <div className="container">
//             <div className="row">
//             <div className="col-md-10 offset-md-1">
//                 <span>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                 </span>
//                 <h4>The Ultimate Luxury Experience</h4>
//                 <h1>Enjoy The Best Moments of Life</h1>
//                 <div className="butn-light mt-30 mb-30">
//                   <a href="#" data-scroll-nav="1"><span>Rooms & Suites</span></a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </Slider>
//   );
// };

// export default HeaderSlider;



// import React from 'react';
// import ImgSlider1 from '../../../../assets/img/slider/1.jpg';
// import ImgSlider2 from '../../../../assets/img/slider/2.jpg';
// import ImgSlider3 from '../../../../assets/img/slider/3.jpg';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import Slider from 'react-slick';

// const HeaderSlider = () => {
//   const settings = {
//     dots: false,
//     infinite: true,
//     autoplay: true,
//     speed: 5000, // Set the duration for each slide in milliseconds
//     autoplaySpeed: 2000, // Set the interval between slides in milliseconds (change this value)
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     fade: true,
//     cssEase: 'linear',
//   };

//   return (
//     <Slider {...settings}>
//       <div>
//         <img src={ImgSlider1} alt="Slider 1" />
//         <div className="v-middle caption">
//           <div className="container">
//             <div className="row">
//               <div className="col-md-10 offset-md-1">
//                 <span>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                 </span>
//                 <h4>Luxury Hotel & Best Resort</h4>
//                 <h1>Enjoy a Luxury Experience</h1>
//                 <div className="butn-light mt-30 mb-30">
//                   <a href="#" data-scroll-nav="1"><span>Rooms & Suites</span></a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div>
//         <img src={ImgSlider2} alt="Slider 2" />
//         <div className="v-middle caption">
//           <div className="container">
//             <div className="row">
//               <div className="col-md-10 offset-md-1">
//                 <span>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                 </span>
//                 <h4>Unique Place to Relax & Enjoy</h4>
//                 <h1>The Perfect Base For You</h1>
//                 <div className="butn-light mt-30 mb-30">
//                   <a href="#" data-scroll-nav="1"><span>Rooms & Suites</span></a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div>
//         <img src={ImgSlider3} alt="Slider 3" />
//         <div className="v-middle caption">
//           <div className="container">
//             <div className="row">
//               <div className="col-md-10 offset-md-1">
//                 <span>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                   <i className="star-rating"></i>
//                 </span>
//                 <h4>The Ultimate Luxury Experience</h4>
//                 <h1>Enjoy The Best Moments of Life</h1>
//                 <div className="butn-light mt-30 mb-30">
//                   <a href="#" data-scroll-nav="1"><span>Rooms & Suites</span></a>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </Slider>
//   );
// };

// export default HeaderSlider;


import React from 'react';
import Slider from 'react-slick';
import ImgSlider1 from '../../../../assets/img/slider/1.jpg';
import ImgSlider2 from '../../../../assets/img/slider/2.jpg';
import ImgSlider3 from '../../../../assets/img/slider/3.jpg';
import { NavLink } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HeaderSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 5000, // Set the duration for each slide in milliseconds
    autoplaySpeed: 1000, // Set the interval between slides in milliseconds (change this value)
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: 'linear',
  };

  return (
    <Slider {...settings}>
      <div className="text-center item bg-img fhtd fg" data-overlay-dark="4">
        <img src={ImgSlider1} alt="Slider 1" />
        <div className="v-middle caption">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <span>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                </span>
                <h4>Luxury Hotel & Best Resort</h4>
                <h1>Enjoy a Luxury Experience</h1>
                {/* <div className="butn-light mt-30 mb-30">
                  <NavLink to = "/"><span>Properties</span></NavLink>
                 
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center item bg-img fg" data-overlay-dark="4">
        <img src={ImgSlider2} alt="Slider 2" />
        <div className="v-middle caption">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <span>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                </span>
                <h4>Unique Place to Relax & Enjoy</h4>
                <h1>The Perfect Base For You</h1>
                <div className="butn-light mt-30 mb-30">
                {/* <NavLink to = "/properties"><span>Properties</span></NavLink>
                <NavLink to = "/"><span>Properties</span></NavLink> */}
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center item bg-img fg" data-overlay-dark="4">
        <img src={ImgSlider3} alt="Slider 3" />
        <div className="v-middle caption">
          <div className="container">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <span>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                  <i className="star-rating"></i>
                </span>
                <h4>The Ultimate Luxury Experience</h4>
                <h1>Enjoy The Best Moments of Life</h1>
                <div className="butn-light mt-30 mb-30">
                {/* <NavLink to = "/properties"><span>Properties</span></NavLink>
                <NavLink to = "/"><span>Properties</span></NavLink> */}
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default HeaderSlider;

// import React, { useState, useEffect } from 'react';
// import {API_URL} from "../../../../utils/config"
// import Slider from 'react-slick';
// import { NavLink } from 'react-router-dom';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// const HeaderSlider = () => {
//   const [headerData, setHeaderData] = useState([]);

//   useEffect(() => {
//     const fetchHeaderData = async () => {
//       try {
//         const response = await fetch(API_URL);
//         const data = await response.json();
//         setHeaderData(data.header);
//       } catch (error) {
//         console.error('Error fetching header data:', error);
//       }
//     };

//     fetchHeaderData();
//   }, []);

//   const settings = {
//     dots: false,
//     infinite: true,
//     autoplay: true,
//     speed: 5000,
//     autoplaySpeed: 2000,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     fade: true,
//     cssEase: 'linear',
//   };

//   return (
//     <Slider {...settings}>
//       {headerData.map((item, index) => (
//         <div key={index} className="text-center item bg-img fhtd" data-overlay-dark="2">
//           <img src={item.images} alt={`Slider ${index + 1}`} />
//           <div className="v-middle caption">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-10 offset-md-1">
//                   <span>
//                     <i className="star-rating"></i>
//                     <i className="star-rating"></i>
//                     <i className="star-rating"></i>
//                     <i className="star-rating"></i>
//                     <i className="star-rating"></i>
//                   </span>
//                   <h4>{item.title}</h4>
//                   <h1>{item.sub_title}</h1>
//                   <div className="butn-light mt-30 mb-30">
//                     <NavLink to="/rooms&suites"><span>Rooms & Suites</span></NavLink>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ))}
//     </Slider>
//   );
// };
//  export default HeaderSlider;

// import React, { useState, useEffect } from 'react';
// import { API_URL } from '../../../../utils/config';
// import Slider from 'react-slick';
// import { NavLink } from 'react-router-dom';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';


// const HeaderSlider = () => {
//   const [headerData, setHeaderData] = useState([]);

//   useEffect(() => {
//     const fetchHeaderData = async () => {
//       try {
//         const response = await fetch(API_URL);
//         const data = await response.json();
//         setHeaderData(data.header);
//       } catch (error) {
//         console.error('Error fetching header data:', error);
//       }
//     };

//     fetchHeaderData();
//   }, []);

//   const settings = {
//     dots: false,
//     infinite: true,
//     autoplay: true,
//     speed: 5000,
//     autoplaySpeed: 2000,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     fade: true,
//     cssEase: 'linear',
//   };
//   const headerSliderStyles = {
//     marginTop: '100px', // Adjust this value to create enough space between the slider and the header
//     // Add any other styles you need
//   };

//   return (
//     <div style={headerSliderStyles}>
//     <Slider {...settings}>
//       {headerData.map((item, index) => (
//         <div key={index} className="text-center item bg-img fhtd" data-overlay-dark="2">
//           <img src={item.images} alt={`Slider ${index + 1}`} />
//           <div className="v-middle caption">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-10 offset-md-1">
//                   <span className="star-rating">
//                     {[1, 2, 3, 4, 5].map((starIndex) => (
//                       <i key={starIndex} className="star-icon"></i>
//                     ))}
//                   </span>
//                   <h4>{item.title}</h4>
//                   <h1 className="header-title">{item.sub_title}</h1>
//                   <div className="butn-light mt-3 mb-3">
//                     <NavLink to="/rooms&suites">
//                       <span>Rooms & Suites</span>
//                     </NavLink>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ))}
//     </Slider>
//     </div>
//   );
// };

// export default HeaderSlider;

