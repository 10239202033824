// import React from 'react';
// import Slider from 'react-slick';
// import { NavLink } from 'react-router-dom';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import ImgClient1 from "../../../../assets/img/clients/1.png"
// import ImgClient2 from "../../../../assets/img/clients/2.png"
// import ImgClient3 from "../../../../assets/img/clients/3.png"
// import ImgClient4 from "../../../../assets/img/clients/4.png"
// import ImgClient5 from "../../../../assets/img/clients/5.png"
// import ImgClient6 from "../../../../assets/img/clients/6.png"

// const Clients = () => {
//   const clientLogos = [ ImgClient1, ImgClient2, ImgClient3, ImgClient4, ImgClient5, ImgClient6 ];

//   const slickSettings = {
//     dots: false,
//     infinite: true,
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <section className="clients">
//       <div className="container">
//         <div className="row">
//           <div className="col-md-7">
//             <Slider {...slickSettings}>
//               {clientLogos.map((logo, index) => (
//                 <div className="clients-logo" key={index}>
//                   <NavLink>
//                     <img src={logo} alt={`Client ${index + 1}`} />
//                   </NavLink>
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Clients;

import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { API_HOME } from "../../../../utils/config";

const Clients = () => {
  const [clientLogos, setClientLogos] = useState([]);

  const slickSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    // Fetch data from the backend API
    axios
      .get(API_HOME)
      .then((response) => {
        // Assuming your JSON data has the "client_logo" array
        const clientLogoData = response.data.client_logo;
        setClientLogos(clientLogoData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <section className="clients">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Slider {...slickSettings}>
              {clientLogos.map((client, index) => (
                <div className="clients-logo" key={index}>
                  <NavLink>
                    <img src={client.image} alt={`Client ${index + 1}`} />
                  </NavLink>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;
