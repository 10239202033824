

import React, { useState } from "react";
import CustomModal from "./CustomModal"; // Import your modal component

const PropertyRoomImages = ({ secondary_images, primary_images }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [showAllPhotos, setShowAllPhotos] = useState(false);

  const handleImageClick = () => {
    const allImages = [
      ...secondary_images,
      ...(Array.isArray(primary_images) ? primary_images : []),
    ];
    setSelectedImages(allImages);
    setModalIsOpen(true);
  };

  const handleShowAllPhotosClick = () => {
    setShowAllPhotos(true);
    handleImageClick(); // Display all images when "Show all Photos" is clicked
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setShowAllPhotos(false); // Reset showAllPhotos when the modal is closed
  };

  const visibleSecondaryImages = showAllPhotos
    ? secondary_images
    : (secondary_images || []).slice(0, 4);

  return (
    <section className="room-book my-5 pt-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 big p-1">
            {primary_images && (
              <img
                src={primary_images}
                alt="Primary Image"
                onClick={handleImageClick}
                style={{ cursor: "pointer" }}
              />
            )}
          </div>
          <div className="col-md-6">
            <div className="row right-img-box">
              {visibleSecondaryImages.map((image, index) => (
                <div
                  className={`col-md-6 ${showAllPhotos ? "" : "p-1"}`}
                  key={index}
                >
                  <img
                    src={image}
                    alt={`Image ${index}`}
                    onClick={handleImageClick}
                    style={{ cursor: "pointer" }}
                    className="img-fluid"
                  />
                </div>
              ))}
            </div>
          </div>
          {!showAllPhotos && (
            <button className="btn-show" onClick={handleShowAllPhotosClick}>
              Show all Photos
            </button>
          )}
        </div>

        <CustomModal
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          images={selectedImages}
          modalWidth="50%"
        />
      </div>
    </section>
  );
};

export default PropertyRoomImages;


