import React from 'react'
import Header from "../../../../components/common/header/Header"
import HeaderSlider from '../HomeSliderPage/HeaderSlider'
import BookingSearch from '../HomeBookingSearchPage/BookingSearch'
import HomeAbout from '../HomeAboutPage/HomeAbout'
// import Rooms from '../HomeRoomPage/Rooms'
import PricingSection from '../HomePricingPage/PricingSection'
import PromotionalVideo from '../HomePromoVideoPage/PromotionalVideo'
import HotelFacilitiesSection from '../HomeFacilitiesPage/HotelFacilitiesSection'
import TestimonialSlider from '../HomeTestionmialsPage/TestimonialSlider'
import ServicesSection from '../HomeServicesPage.js/ServicesSection'
import Reservations from '../HomeReservationsBookingPage/Reservations'
import Clients from '../HomeClient/Clients'
import Footer from '../../../common/footer/Footer'
import PhoneCallButton from '../PhoneCallButton/PhoneCallButton'
import WebsiteLayout from '../WebsiteLayout'
import Property from '../HomePropertyRoomPage/Property'





const Home = () => {
  return (
    <div>
    <WebsiteLayout>
        <Header/>
        <HeaderSlider/>
        {/* <BookingSearch/> */}
        <HomeAbout/>
        <Property/>
        {/* <PricingSection/> */}
        <PromotionalVideo/>
        <HotelFacilitiesSection/>
        {/* <PhoneCallButton /> */}
        <Reservations/>
        <Clients/>
        <Footer/>
        </WebsiteLayout>
    </div>
  )
}

export default Home