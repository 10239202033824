import React from 'react';

const VerifyBannerHeader = () => {
  return (
    <div className="banner-header section-padding valign bg-img bg-fixed" data-overlay-dark="3" data-background="img/slider/1.jpg">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-left caption mt-90">
            <h1>Verify your account</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyBannerHeader;