import React from "react";

import LoginBannerHeader from "./LoginBannerHeader/LoginBannerHeader";
import Footer from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import LoginForm from "./LoginForm";

const LoginPage = () => {
  return (
    <>
      <Header />
      <LoginBannerHeader />
      <LoginForm />
      <Footer />
    </>
  );
};

export default LoginPage;
