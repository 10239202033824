// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { API_CSRF } from "../../../utils/config";

// const RegistrationForm = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     last_name: "",
//     email: "",
//     password: "",
//     c_password: "",
//   });

//   useEffect(() => {
//     async function fetchCSRFToken() {
//       try {
//         const response = await axios.get(
//           API_CSRF
//         );
//         const csrfToken = response.data.csrf_token;
//         axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
//         setCSRFToken(csrfToken);
//       } catch (error) {
//         console.error("Error fetching CSRF token:", error);
//       }
//     }
//     fetchCSRFToken();
//   }, []);

//   const [csrfToken, setCSRFToken] = useState("");
//   const navigate = useNavigate(); // Import and initialize useHistory

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     try {
//       // Perform validation on the form data
//       const errors = {};
//       if (!formData.name) {
//         errors.name = "First Name is required";
//       }
//       if (!formData.last_name) {
//         errors.last_name = "Last Name is required";
//       }
//       if (!formData.email) {
//         errors.email = "Email is required";
//       }
//       if (!formData.password) {
//         errors.password = "Password is required";
//       }
//       if (formData.password !== formData.c_password) {
//         errors.c_password = "Passwords do not match";
//       }

//       // If there are validation errors, set them in the state and stop form submission
//       if (Object.keys(errors).length > 0) {
//         setFormErrors(errors);
//         return;
//       }

//       // If there are no validation errors, make the API call
//       const requestOptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "X-CSRF-Token": csrfToken,
//         },
//         body: userId
//           ? JSON.stringify({ ...formData, user_id: userId })
//           : JSON.stringify(formData),
//         // body: JSON.stringify(formData),
//         //   body: JSON.stringify({
//         //   ...formData,
//         //   user_id: userId,
//         // }),
//       };

//       const response = await fetch(
//         "https://lluxe.sbwares.com/api/register",
//         requestOptions
//       );
//       const data = await response.json();

//       // Handle the response (e.g., show a success message, redirect user, etc.)
//       console.log("API Response:", data);

//       // Redirect to the verify page after successful form submission
//       if (response.ok) {
//         // Save the user ID from the response in the state
//         setUserId(data.data.id);

//         window.alert("Registration successful! Please verify your email.");
        
//         // navigate('/verify');
//         navigate(`/verify?userId=${data.data.id}`);
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   // State variable to hold form validation errors
//   const [formErrors, setFormErrors] = useState({});
//   // State variable to hold the user ID
//   const [userId, setUserId] = useState(null);

//   return (
//     <div>
//       <section className="contact section-padding">
//         <div className="container">
//           <div className="row ">
//             <div className="col-md-4 offset-md-4">
//               <h3 className="text-center mb-30">Registration form</h3>
//               <form onSubmit={handleSubmit} className="Register__form">
//                 <div className="row">
//                   <div className="col-12">
//                     <div
//                       className="alert alert-success contact__msg"
//                       style={{ display: "none" }}
//                       role="alert"
//                     >
//                       Your message was sent successfully.
//                     </div>
//                   </div>
//                 </div>
//                 <div className="row">
//                   <div className="col-sm-6">
//                     <label>First Name</label>
//                     <input
//                       type="text"
//                       name="name"
//                       value={formData.name}
//                       onChange={handleInputChange}
//                       className="input-field"
//                     />
                   
//                     {formErrors.name && (
//                       <div className="error-message">{formErrors.name}</div>
//                     )}
//                   </div>
//                   <div className="col-sm-6">
//                     <label>Last Name</label>
//                     <input
//                       type="text"
//                       name="last_name"
//                       value={formData.last_name}
//                       onChange={handleInputChange}
//                       className="input-field"
//                     />
//                     {formErrors.last_name && (
//                       <div className="error-message">
//                         {formErrors.last_name}
//                       </div>
//                     )}
//                   </div>
//                 </div>
//                 <div>
//                   <label>Your Email</label>
//                   <input
//                     type="email"
//                     name="email"
//                     value={formData.email}
//                     onChange={handleInputChange}
//                     required
//                     className="input-field"
//                   />
//                   {formErrors.email && (
//                     <div className="error-message">{formErrors.email}</div>
//                   )}
//                 </div>
//                 <div className="row mb-25">
//                   <div className="col-sm-6">
//                     <label>Password</label>
//                     <input
//                       type="password"
//                       name="password"
//                       value={formData.password}
//                       onChange={handleInputChange}
//                       className="input-field"
//                     />
//                     {formErrors.password && (
//                       <div className="error-message">{formErrors.password}</div>
//                     )}
//                   </div>
//                   <div className="col-sm-6">
//                     <label>Confirm Password</label>
//                     <input
//                       type="password"
//                       name="c_password"
//                       value={formData.c_password}
//                       onChange={handleInputChange}
//                       className="input-field"
//                     />
//                     {formErrors.c_password && (
//                       <div className="error-message">
//                         {formErrors.c_password}
//                       </div>
//                     )}
//                   </div>
//                 </div>
//                 <div className="col-md-12">
//                   <div className="text-center">
//                     <button type="submit" className="butn-dark2">
//                       <span>Sign up</span>
//                     </button>
//                   </div>
//                 </div>
               
//                 <input type="hidden" name="user_id" value={userId} />
//               </form>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default RegistrationForm;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_CSRF , API_REGISTER } from "../../../utils/config";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    email: "",
    password: "",
    c_password: "",
  });

  useEffect(() => {
    async function fetchCSRFToken() {
      try {
        const response = await axios.get(
          API_CSRF
        );
        const csrfToken = response.data.csrf_token;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
        setCSRFToken(csrfToken);
      } catch (error) {
        console.error("Error fetching CSRF token:", error);
      }
    }
    fetchCSRFToken();
  }, []);

  const [csrfToken, setCSRFToken] = useState("");
  const navigate = useNavigate(); // Import and initialize useHistory

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Perform validation on the form data
      const errors = {};
      if (!formData.name) {
        errors.name = "First Name is required";
      }
      if (!formData.last_name) {
        errors.last_name = "Last Name is required";
      }
      if (!formData.email) {
        errors.email = "Email is required";
      }
      if (!formData.password) {
        errors.password = "Password is required";
      }
      if (formData.password !== formData.c_password) {
        errors.c_password = "Passwords do not match";
      }

      // If there are validation errors, set them in the state and stop form submission
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }

      // If there are no validation errors, make the API call
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: userId
          ? JSON.stringify({ ...formData, user_id: userId })
          : JSON.stringify(formData),
        // body: JSON.stringify(formData),
        //   body: JSON.stringify({
        //   ...formData,
        //   user_id: userId,
        // }),
      };

      const response = await fetch(
        API_REGISTER,
        requestOptions
      );
      const data = await response.json();

      // Handle the response (e.g., show a success message, redirect user, etc.)
      console.log("API Response:", data);

      // Redirect to the verify page after successful form submission
      if (data.success === true) {
        // Save the user ID from the response in the state
        setUserId(data.data.id);

        // window.alert("Registration successful! Please verify your email.");
        toast.success("Registration successful! Please verify your email.");
        
        // navigate('/verify');
        navigate(`/verify?userId=${data.data.id}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // State variable to hold form validation errors
  const [formErrors, setFormErrors] = useState({});
  // State variable to hold the user ID
  const [userId, setUserId] = useState(null);

  return (
    <div>
      <section className="contact section-padding">
        <div className="container">
          <div className="row ">
            <div className="col-md-6 offset-md-3">
              <div className="reg">
              <h3 className="text-center mb-30">Registration form</h3>
              <form onSubmit={handleSubmit} className="Register__form">
                <div className="row">
                  <div className="col-12">
                    <div
                      className="alert alert-success contact__msg"
                      style={{ display: "none" }}
                      role="alert"
                    >
                      Your message was sent successfully.
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <label>First Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      className="input-field"
                    />
                   
                    {formErrors.name && (
                      <div className="error-message">{formErrors.name}</div>
                    )}
                  </div>
                  <div className="col-sm-6">
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      className="input-field"
                    />
                    {formErrors.last_name && (
                      <div className="error-message">
                        {formErrors.last_name}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label>Your Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    className="input-field"
                  />
                  {formErrors.email && (
                    <div className="error-message">{formErrors.email}</div>
                  )}
                </div>
                <div className="row mb-25">
                  <div className="col-sm-6">
                    <label>Password</label>
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      className="input-field"
                    />
                    {formErrors.password && (
                      <div className="error-message">{formErrors.password}</div>
                    )}
                  </div>
                  <div className="col-sm-6">
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      name="c_password"
                      value={formData.c_password}
                      onChange={handleInputChange}
                      className="input-field"
                    />
                    {formErrors.c_password && (
                      <div className="error-message">
                        {formErrors.c_password}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="text-center">
                    <button type="submit" className="butn-dark2">
                      <span>Sign up</span>
                    </button>
                  </div>
                </div>
               
                <input type="hidden" name="user_id" value={userId} />
              </form>
            </div></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RegistrationForm;