// import React, { useState } from 'react';

// const ContactForm = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     subject: '',
//     message: '',
//   });

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     // You can implement the form submission logic here, e.g., send data to a server or display a success message.
//     console.log(formData);
//   };

//   return (
//     <section className="contact section-padding">
//       <div className="container">
//         <div className="row mb-90">
//         <div className="col-md-6 mb-60">
//                     <h3>The Cappa Luxury Hotel</h3>
//                     <p>Hotel ut nisl quam nestibulum ac quam nec odio elementum sceisue the aucan ligula. Orci varius natoque penatibus et magnis dis parturient monte nascete ridiculus mus nellentesque habitant morbine.</p>
//                     <div className="reservations mb-30">
//                         <div className="icon"><span className="flaticon-call"></span></div>
//                         <div className="text">
//                             <p>Reservation</p> <a href="tel:855-100-4444">855 100 4444</a>
//                         </div>
//                     </div>
//                     <div className="reservations mb-30">
//                         <div className="icon"><span className="flaticon-envelope"></span></div>
//                         <div className="text">
//                             <p>Email Info</p> <a href="mailto:info@luxuryhotel.com">info@luxuryhotel.com</a>
//                         </div>
//                     </div>
//                     <div className="reservations">
//                         <div className="icon"><span className="flaticon-location-pin"></span></div>
//                         <div className="text">
//                             <p>Address</p> 1616 Broadway NY, New York 10001
//                             <br/>United States of America
//                         </div>
//                     </div>
//                 </div>
//           <div className="col-md-5 mb-30 offset-md-1">
//             <h3>Get in touch</h3>
//             <form onSubmit={handleSubmit} className="contact__form">
//               {/* form message */}
//               <div className="row">
//                 <div className="col-12">
//                   <div className="alert alert-success contact__msg" style={{ display: 'none' }} role="alert">
//                     Your message was sent successfully.
//                   </div>
//                 </div>
//               </div>
//               {/* form elements */}
//               <div className="row">
//                 <div className="col-md-6 form-group">
//                   <input
//                     name="name"
//                     type="text"
//                     placeholder="Your Name *"
//                     value={formData.name}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </div>
//                 <div className="col-md-6 form-group">
//                   <input
//                     name="email"
//                     type="email"
//                     placeholder="Your Email *"
//                     value={formData.email}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </div>
//                 <div className="col-md-6 form-group">
//                   <input
//                     name="phone"
//                     type="text"
//                     placeholder="Your Number *"
//                     value={formData.phone}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </div>
//                 <div className="col-md-6 form-group">
//                   <input
//                     name="subject"
//                     type="text"
//                     placeholder="Subject *"
//                     value={formData.subject}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </div>
//                 <div className="col-md-12 form-group">
//                   <textarea
//                     name="message"
//                     id="message"
//                     cols="30"
//                     rows="4"
//                     placeholder="Message *"
//                     value={formData.message}
//                     onChange={handleInputChange}
//                     required
//                   ></textarea>
//                 </div>
//                 <div className="col-md-12">
//                   <button type="submit" className="butn-dark2">
//                     <span>Send Message</span>
//                   </button>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//         {/* Map Section */}
//         <div className="row">
//           <div className="col-md-12 map animate-box" data-animate-effect="fadeInUp">
//           <iframe
//               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1573147.7480448114!2d-74.84628175962355!3d41.04009641088412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25856139b3d33%3A0xb2739f33610a08ee!2s1616%20Broadway%2C%20New%20York%2C%20NY%2010019%2C%20Amerika%20Birle%C5%9Fik%20Devletleri!5e0!3m2!1str!2str!4v1646760525018!5m2!1str!2str"
//               width="100%"
//               height="600"
//               style={{ border: 0 }}
//               allowFullScreen=""
//               loading="lazy"
//             ></iframe>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ContactForm;

// import React, { useState } from 'react';
// import axios from 'axios';
// import {API_CONTACT} from "../../../utils/config"

// const ContactForm = () => {
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     phone: '',
//     subject: '',
//     message: '',
//   });
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [submitError, setSubmitError] = useState(null);
//   const [submitSuccess, setSubmitSuccess] = useState(false);

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     setSubmitError(null);
//     setIsSubmitting(true);

//     try {
//       const response = await axios.post(API_CONTACT, formData);
//       console.log('Form submission response:', response.data);
//       setSubmitSuccess(true);
//       // Optionally, you can show a success message to the user here or reset the form.
//     } catch (error) {
//       console.error('Error submitting the form:', error);
//       setSubmitError('An error occurred while submitting the form. Please try again later.');
//       // Optionally, you can show an error message to the user here.
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

//   return (
//     <section className="contact section-padding">
//       <div className="container">
//       <div className="row mb-90">
//         <div className="col-md-6 mb-60">
//                     <h3>The Cappa Luxury Hotel</h3>
//                     <p>Hotel ut nisl quam nestibulum ac quam nec odio elementum sceisue the aucan ligula. Orci varius natoque penatibus et magnis dis parturient monte nascete ridiculus mus nellentesque habitant morbine.</p>
//                     <div className="reservations mb-30">
//                         <div className="icon"><span className="flaticon-call"></span></div>
//                         <div className="text">
//                             <p>Reservation</p> <a href="tel:855-100-4444">855 100 4444</a>
//                         </div>
//                     </div>
//                     <div className="reservations mb-30">
//                         <div className="icon"><span className="flaticon-envelope"></span></div>
//                         <div className="text">
//                             <p>Email Info</p> <a href="mailto:info@luxuryhotel.com">info@luxuryhotel.com</a>
//                         </div>
//                     </div>
//                     <div className="reservations">
//                         <div className="icon"><span className="flaticon-location-pin"></span></div>
//                         <div className="text">
//                             <p>Address</p> 1616 Broadway NY, New York 10001
//                             <br/>United States of America
//                         </div>
//                     </div>
//                 </div>
//           <div className="col-md-5 mb-30 offset-md-1">
//             <h3>Get in touch</h3>
//             <form onSubmit={handleSubmit} className="contact__form">
//               {/* form message */}
//               <div className="row">
//                 <div className="col-12">
//                   <div className="alert alert-success contact__msg" style={{ display: 'none' }} role="alert">
//                     Your message was sent successfully.
//                   </div>
//                 </div>
//               </div>
//               {/* form elements */}
//               <div className="row">
//                 <div className="col-md-6 form-group">
//                   <input
//                     name="name"
//                     type="text"
//                     placeholder="Your Name *"
//                     value={formData.name}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </div>
//                 <div className="col-md-6 form-group">
//                   <input
//                     name="email"
//                     type="email"
//                     placeholder="Your Email *"
//                     value={formData.email}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </div>
//                 <div className="col-md-6 form-group">
//                   <input
//                     name="phone"
//                     type="text"
//                     placeholder="Your Number *"
//                     value={formData.phone}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </div>
//                 <div className="col-md-6 form-group">
//                   <input
//                     name="subject"
//                     type="text"
//                     placeholder="Subject *"
//                     value={formData.subject}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </div>
//                 <div className="col-md-12 form-group">
//                   <textarea
//                     name="message"
//                     id="message"
//                     cols="30"
//                     rows="4"
//                     placeholder="Message *"
//                     value={formData.message}
//                     onChange={handleInputChange}
//                     required
//                   ></textarea>
//                 </div>
//                 <div className="col-md-12">
//                   <button type="submit" className="butn-dark2">
//                     <span>Send Message</span>
//                   </button>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>

//         <div className="col-md-5 mb-30 offset-md-1">
//           <h3>Get in touch</h3>
//           {submitSuccess ? (
//             <div className="alert alert-success contact__msg" role="alert">
//               Your message was sent successfully.
//             </div>
//           ) : (
//             <form onSubmit={handleSubmit} className="contact__form">
//               {submitError && (
//                 <div className="alert alert-danger contact__msg" role="alert">
//                   {submitError}
//                 </div>
//               )}
//             </form>
//           )}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default ContactForm;

// import React, { useState } from "react";
// import { API_CONTACT } from "../../../utils/config";
// import axios from "axios";

// const ContactForm = () => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     number: "",
//     subject: "",
//     message: "",
//   });

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       const response = await axios.post(API_CONTACT, formData);
//       console.log("Form submission successful!", response.data);
//       // You can show a success message or perform any other actions here.
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       // You can show an error message or handle the error in any other way.
//     }
//   };

//   return (
//     <section className="contact section-padding">
//       <div className="container">
//         <div className="row mb-90">
//           <div className="col-md-6 mb-60">
//             <h3>The Cappa Luxury Hotel</h3>
//             <p>
//               Hotel ut nisl quam nestibulum ac quam nec odio elementum sceisue
//               the aucan ligula. Orci varius natoque penatibus et magnis dis
//               parturient monte nascete ridiculus mus nellentesque habitant
//               morbine.
//             </p>
//             <div className="reservations mb-30">
//               <div className="icon">
//                 <span className="flaticon-call"></span>
//               </div>
//               <div className="text">
//                 <p>Reservation</p> <a href="tel:855-100-4444">855 100 4444</a>
//               </div>
//             </div>
//             <div className="reservations mb-30">
//               <div className="icon">
//                 <span className="flaticon-envelope"></span>
//               </div>
//               <div className="text">
//                 <p>Email Info</p>{" "}
//                 <a href="mailto:info@luxuryhotel.com">info@luxuryhotel.com</a>
//               </div>
//             </div>
//             <div className="reservations">
//               <div className="icon">
//                 <span className="flaticon-location-pin"></span>
//               </div>
//               <div className="text">
//                 <p>Address</p> 1616 Broadway NY, New York 10001
//                 <br />
//                 United States of America
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6 mb-30">
//             <form onSubmit={handleSubmit} className="contact__form">
//               <div className="row">
//                 <div className="col-md-6 form-group">
//                   <input
//                     name="name"
//                     type="text"
//                     placeholder="Your Name *"
//                     value={formData.name}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </div>
//                 <div className="col-md-6 form-group">
//                   <input
//                     name="email"
//                     type="email"
//                     placeholder="Your Email *"
//                     value={formData.email}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </div>
//                 <div className="col-md-6 form-group">
//                   <input
//                     name="number"
//                     type="text"
//                     placeholder="Your Number *"
//                     value={formData.number}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </div>
//                 <div className="col-md-6 form-group">
//                   <input
//                     name="subject"
//                     type="text"
//                     placeholder="Subject *"
//                     value={formData.subject}
//                     onChange={handleInputChange}
//                     required
//                   />
//                 </div>
//                 <div className="col-md-12 form-group">
//                   <textarea
//                     name="message"
//                     id="message"
//                     cols="30"
//                     rows="4"
//                     placeholder="Message *"
//                     value={formData.message}
//                     onChange={handleInputChange}
//                     required
//                   ></textarea>
//                 </div>
//                 <div className="col-md-12">
//                   <button type="submit" className="butn-dark2">
//                     <span>Send Message</span>
//                   </button>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//         {/* <div className="row">
//           <div className="col-md-12 map animate-box" data-animate-effect="fadeInUp">
//           <iframe
//               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1573147.7480448114!2d-74.84628175962355!3d41.04009641088412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25856139b3d33%3A0xb2739f33610a08ee!2s1616%20Broadway%2C%20New%20York%2C%20NY%2010019%2C%20Amerika%20Birle%C5%9Fik%20Devletleri!5e0!3m2!1str!2str!4v1646760525018!5m2!1str!2str"
//               width="100%"
//               height="600"
//               style={{ border: 0 }}
//               allowFullScreen=""
//               loading="lazy"
//             ></iframe>
//           </div>
//         </div> */}
//       </div>
//     </section>
//   );
// };

// export default ContactForm;

import React, { useState } from "react";
import { API_CONTACT } from "../../../utils/config";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(API_CONTACT, formData);
      console.log("Form submission successful!", response.data);
  
      if (response.status === 200 && response.data.status === 'true') {
        toast.success("We will connect you soon");
      }
  
      // You can show a success message or perform any other actions here.
    } catch (error) {
      console.error("Error submitting form:", error);
      // You can show an error message or handle the error in any other way.
    }
  };

  return (
    <section className="contact section-padding">
      <div className="container">
        <div className="row mb-90">
          <div className="col-md-6 mb-60">
            <h3>Lluxe Properties Ltd</h3>
            <p>
              Hotel ut nisl quam nestibulum ac quam nec odio elementum sceisue
              the aucan ligula. Orci varius natoque penatibus et magnis dis
              parturient monte nascete ridiculus mus nellentesque habitant
              morbine.
            </p>
            <div className="reservations mb-30">
              <div className="icon">
                <span className="fa fa-phone"></span>
              </div>
              <div className="text">
                <p>Reservation</p> <a href="tel:+44 7877 766886">+44 7877 766886</a>
              </div>
            </div>
            <div className="reservations mb-30">
              <div className="icon">
                <span className="fa fa-envelope"></span>
              </div>
              <div className="text">
                <p>Email Info</p>{" "}
                <a href="mailto:info@luxuryhotel.com">info@luxuryhotel.com</a>
              </div>
            </div>
            <div className="reservations">
              <div className="icon">
                <span className="fa fa-map-marker"></span>
              </div>
              <div className="text">
                <p>Address</p> 1616 Broadway NY, New York 10001
                <br />
                United States of America
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-30">
            <div className="cnt">
          <h3>Get in touch</h3>
            <form onSubmit={handleSubmit} className="contact__form">
              <div className="row">
                <div className="col-md-6 form-group">
                  <input
                    name="name"
                    type="text"
                    placeholder="Your Name *"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-6 form-group">
                  <input
                    name="email"
                    type="email"
                    placeholder="Your Email *"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-6 form-group">
                  <input
                    name="number"
                    type="text"
                    placeholder="Your Number *"
                    value={formData.number}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-6 form-group">
                  <input
                    name="subject"
                    type="text"
                    placeholder="Subject *"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-12 form-group">
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="4"
                    placeholder="Message *"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
                <div className="col-md-12">
                  <button type="submit" className="butn-dark2">
                    <span>Send Message</span>
                  </button>
                </div>
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;