// import React from "react";

// const PropertyAmennties = () => {
//   return (
//     <>
//       <section className="palce-offer pb-5 mb-5">
//         <h3>What this place offers</h3>
//         <div className="row">
//           <div className="col-sm-6">
//             <ul className="amenties">
//               <li>
//               <i className="fa fa-home"></i> Kitchen
//               </li>
//               <li>
//                 <i className="fa fa-home"></i> Dedicated workspace
//               </li>
//               <li>
//                 <i className="fa fa-tv"></i> TV
//               </li>
//               <li>
//                 <i className="fa fa-home"></i> Dryer
//               </li>
//               <li>
//                 <i className="fa fa-tree"></i> Garden
//               </li>
//             </ul>
//           </div>
//           <div className="col-sm-6">
//             <ul className="amenties">
//               <li>
//                 <i className="fa fa-wifi"></i> Wifi
//               </li>
//               <li>
//                 <i className="fa fa-car"></i> Free parking on premises
//               </li>
//               <li>
//                 <i className="fa fa-home"></i> Washing machine
//               </li>
//               <li>
//                 <i className="fa fa-bath"></i> Bath
//               </li>
//               <li>
//                 <i className="fa fa-home"></i> Hair dryer
//               </li>
//             </ul>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default PropertyAmennties;


// FeaturesScroll.js

import React from 'react';

const FeaturesScroll = ({ amenities }) => {
  return (
    <div>
      <h2>Property Amenities</h2>
      <ul className="amenties mb-5">
        {amenities &&
          amenities.map((amenity, index) => (
            <li key={index}>
              <img src={amenity.image} alt={amenity.name} className="col-sm-6"/>
              {amenity.name}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default FeaturesScroll;
