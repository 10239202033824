import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment'; 
import { faCircleChevronRight, faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";

const PropertyCalender = ({ calander }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [month, setMonth] = useState(new Date());
  const [disabledDates, setDisabledDates] = useState([]);


  useEffect(() => {
    if (calander) {
      setDisabledDates(calander); 
      console.log("calender in useEffect", calander);
    }
  }, [calander]);




  const generateDaysInMonth = (date) => {
    const daysInMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();
    return Array.from({ length: daysInMonth }, (_, index) => index + 1);
  };

  const isDateDisabled = (date) => {
    const dateString = moment(date).format('YYYY-MM-DD');
    return disabledDates.includes(dateString);
  };

  const handleDateClick = (day) => {
    setSelectedDate(day);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(month);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setMonth(nextMonth);
  };

  const handlePrevMonth = () => {
    const prevMonth = new Date(month);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    setMonth(prevMonth);
  };

  return (
    <div className="cle">
   
      <div className="datepicker">
        <div className="datepicker-top">
          <div className="btn-group">
            <button className="tag">Today</button>
            <button className="tag">Tomorrow</button>
            <button className="tag">In 2 days</button>
          </div>
          <div className="month-selector">
            <button className="arrow" onClick={handlePrevMonth}>
              <FontAwesomeIcon icon={faCircleChevronLeft} />
            </button>
            <span className="month-name">
              {month.toLocaleString("default", { month: "long" })}{" "}
              {month.getFullYear()}
            </span>
            <button className="arrow" onClick={handleNextMonth}>
              <FontAwesomeIcon icon={faCircleChevronRight} />
            </button>
          </div>
        </div>
        <div className="datepicker-calendar">
          <span className="day">Mo</span>
          <span className="day">Tu</span>
          <span className="day">We</span>
          <span className="day">Th</span>
          <span className="day">Fr</span>
          <span className="day">Sa</span>
          <span className="day">Su</span>

          {generateDaysInMonth(month).map((day) => {
            const currentDate = new Date(month);
            currentDate.setDate(day);
            const isDisabled = isDateDisabled(currentDate);

            return (
              <button
                key={day}
                className={`date ${selectedDate === day ? "current-day" : ""} ${
                  isDisabled ? "disabled-day" : ""
                }`}
                onClick={() => handleDateClick(day)}
                disabled={isDisabled}
              >
                {day}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default PropertyCalender;


// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import moment from "moment";
// import {
//   faCircleChevronRight,
//   faCircleChevronLeft
// } from "@fortawesome/free-solid-svg-icons";

// const PropertyCalender = ({ calander }) => {
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [month, setMonth] = useState(new Date());
//   const [disabledDates, setDisabledDates] = useState([]);

//   useEffect(() => {
//     if (calander) {
//       setDisabledDates(calander);
//     }
//   }, [calander]);

//   const generateDaysInMonth = (date) => {
//     const daysInMonth = new Date(
//       date.getFullYear(),
//       date.getMonth() + 1,
//       0
//     ).getDate();
//     return Array.from({ length: daysInMonth }, (_, index) => index + 1);
//   };

//   const isDateAvailable = (date) => {
//     const dateString = moment(date).format("YYYYMMDD");
//     return !disabledDates.includes(dateString);
//   };

//   const handleDateClick = (day) => {
//     setSelectedDate(day);
//   };

//   const handleNextMonth = () => {
//     const nextMonth = new Date(month);
//     nextMonth.setMonth(nextMonth.getMonth() + 1);
//     setMonth(nextMonth);
//   };

//   const handlePrevMonth = () => {
//     const prevMonth = new Date(month);
//     prevMonth.setMonth(prevMonth.getMonth() - 1);
//     setMonth(prevMonth);
//   };

//   return (
//     <div className="cle">
//       <div className="datepicker">
//         <div className="datepicker-top">
//           <div className="btn-group">
//             <button className="tag">Today</button>
//             <button className="tag">Tomorrow</button>
//             <button className="tag">In 2 days</button>
//           </div>
//           <div className="month-selector">
//             <button className="arrow" onClick={handlePrevMonth}>
//               <FontAwesomeIcon icon={faCircleChevronLeft} />
//             </button>
//             <span className="month-name">
//               {month.toLocaleString("default", { month: "long" })}{" "}
//               {month.getFullYear()}
//             </span>
//             <button className="arrow" onClick={handleNextMonth}>
//               <FontAwesomeIcon icon={faCircleChevronRight} />
//             </button>
//           </div>
//         </div>
//         <div className="datepicker-calendar">
//           <span className="day">Mo</span>
//           <span className="day">Tu</span>
//           <span className="day">We</span>
//           <span className="day">Th</span>
//           <span className="day">Fr</span>
//           <span className="day">Sa</span>
//           <span className="day">Su</span>

//           {generateDaysInMonth(month).map((day) => {
//             const currentDate = new Date(month);
//             currentDate.setDate(day);
//             const isAvailable = isDateAvailable(currentDate);

//             return (
//               <button
//                 key={day}
//                 className={`date ${
//                   selectedDate === day ? "current-day" : ""
//                 } ${isAvailable ? "available-day" : "disabled-day"}`}
//                 onClick={() => handleDateClick(day)}
//                 disabled={!isAvailable}
//               >
//                 {day}
//               </button>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// };
// export default PropertyCalender;
